fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageSeasons = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-seasons",
	template: fantasy.templates.page_seasons,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		var self = this;

		this.setContent({});
		this.allSeasonsCollection = new fantasy.packs.CoreCollections.AllSeasons();

		this.seasonsListView = new fantasy.packs.Comps.SeasonsListView({collection:this.allSeasonsCollection });

		self.addView(this.seasonsListView,{selector: ".fd-dz-season-list"});
		this.allSeasonsCollection.fetch();
		
		this.render();
	}
});