fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


fantasy.packs.CompBeans.SeasonComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "season_id",
        mdl_name: "SeasonComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      this.addModel("season_core",new fantasy.packs.CompBeans.Season({season_id: this.getId()}));
      console.log("season composite mid init, this.getId()", this.getId(),this);
      this.addCollection("rounds",new fantasy.packs.CoreCollections.RoundsForSeason(null,{season_id: this.getId()}), {depth:1});
    },
    levelPrep: function() { 
		this.getModel("season_core").setId(this.getId());
		this.getCollection("rounds").season_id = this.getId();
	}

  });
