fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


fantasy.packs.CompBeans.FantasyTeamComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "f_team_id",
   initialize: function(attributes, options) {
    console.log("pre super init fantasy.packs.CompBeans.FantasyTeamComposite", attributes,options);

      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(attributes && attributes.f_team_id)
        this.setId(attributes.f_team_id);
      if(options && options.f_team_id)
        this.setId(options.f_team_id);


      this.addModel("f_team_core",new fantasy.packs.CompBeans.FantasyTeam( {f_team_id: this.getId()}));
      this.addCollection("athletes",new fantasy.packs.CoreCollections.AthletesOnAFTeam(null,{f_team_id: this.getId()}));
      console.log("fantasy.packs.CompBeans.FantasyTeamComposite init post super", this);
    }

  });



/**

So this is a type of collection that says, hey, I'm first going to get a shallow list of cores, then I'm going to convert that to a richer
composite model.

expects subclass to extend:
model: class of the composite  (the composite must accept an option of "model" to be used as its core.)
idAttribute: "core_object_pkey_field_name like sport_id",
coreCollectionClass: class of the core collection. It gets the same options as this CCCC collection passed to it when we construct it.
**/
fantasy.packs.CompBeans.AllFantasyTeamCompositesForLeagueCollection = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
	model: fantasy.packs.CompBeans.FantasyTeamComposite,
	coreCollectionClass: fantasy.packs.CoreCollections.FantasyTeamsInALeague,
	idAttribute: "league_id",
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("AllFantasyTeamCompositesForLeagueCollection post super init",this);
    },
  getCompositeByUserId: function(user_id) { 
    var foundModel = null;
    _.each(this.models,function(model) { 
      if(model.getModel("f_team_core").get("user_id") === user_id)
        foundModel = model;
    });
    return foundModel;
  }
});

fantasy.packs.CompBeans.SingleUserFantasyTeamCompositeCollection = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.FantasyTeamComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.FantasyTeamsForUserAndLeague,
  mdl_name: "SingleUserFantasyTeamCompositeCollection",
  use_cache: true,
  idAttribute: "league_id",
  id_fields: ["league_id","user_id"],
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("SingleUserFantasyTeamCompositeCollection post super init",this);
    },
});

