
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageViewReceivedInvite = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_view_received_invite,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		
	  if(options)
	    {
	    	this.league_invite_id = options.league_invite_id;
	    	this.invite_auth_code = options.invite_auth_code;
	    	this.sh = options.sh;
	    }
	    else
	    {
	    	throw "Required params missing.";
	    }
	    var self = this;

	    try {
	    	var view = new fantasy.packs.Comps.InviteToYouView({league_invite_id: this.league_invite_id, invite_auth_code: this.invite_auth_code, sh:this.sh });
	    	this.listenToOnce(view,"accepted_invite", function() { 
	    		console.log("PageViewReceivedInvite received accepted_invite event. Bubbling up");
	    		self.trigger("accepted_invite");
	    	});
	    	//accepted_invite
			this.addView(view,{selector:".fd-dz-invite"});
		}
		catch(err)
		{
			console.error("PageViewReceivedInvite Error ",err);
			this.setContent({error:true});
		}
		this.render();
	}
});