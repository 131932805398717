fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};

fantasy.packs.CompBeans.TeamStarterRoundRel = fantasy.beans.Classes.Base.extend({
          mdl_name: "TeamStarterRoundRel",
    use_cache: true,      
    idAttribute: "team_starter_round_rel_id",
    urlRoot: "/api/team_starter_round_rel/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.TeamStarterRoundRelComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "team_starter_round_rel_id",
     mdl_name: "TeamStarterRoundRelComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
    console.log("TeamStarterRoundRelComposite", attributes,options);

      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.model)
        this.addModel("team_starter_round_rel_core",options.model);
      else 
        this.addModel("team_starter_round_rel_core",new fantasy.packs.CompBeans.TeamStarterRoundRel({team_starter_round_rel_id: this.getId()}));

      this.addModel("position_core", new fantasy.packs.CompBeans.Position({position_id: this.get("position_id")}),{depth:1});
      this.addModel("athlete_core", new fantasy.packs.CompBeans.Athlete({athlete_id: this.get("athlete_id")}),{depth:1});

      //add points for this round and this athlete
      this.addCollection("athlete_rounds", new fantasy.packs.CoreCollections.PointRoundsForAthleteRound([],{athlete_id: this.getModel("team_starter_round_rel_core").get("athlete_id"),
        round_id: this.getModel("team_starter_round_rel_core").get("round_id")}),{depth:1});

      

    },
        levelPrep: function() { 
      var teamStarterRoundRelCore = this.getModel("team_starter_round_rel_core");
      if(teamStarterRoundRelCore.loaded)
      {
          this.getModel("position_core").set("position_id",teamStarterRoundRelCore.get("position_id"));
          this.getModel("athlete_core").set("athlete_id",teamStarterRoundRelCore.get("athlete_id"));
          this.getCollection("athlete_rounds").set("athlete_id", teamStarterRoundRelCore.get("athlete_id")).
             set("round_id",teamStarterRoundRelCore.get("round_id"));

      }

    },
    destroy: function(options) {
    	console.log("TeamStarterRoundRelComposite destroy called, passing the buck to core model", this,this.getModel("team_starter_round_rel_core"));
    	return this.getModel("team_starter_round_rel_core").destroy();
    },


  });

fantasy.packs.CoreCollections.TeamStarterRoundRelCompositesForTeamRound  = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.TeamStarterRoundRelComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.TeamStarterRoundRelsForTeamRound,
	id_fields: ["f_team_id","round_id"],
    mdl_name: "PositionsForSport",  
    use_cache: true,       
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("TeamStarterRoundRelCompositesForTeamRound post super init",this);
    },
    containsAthleteId: function(athlete_id) { 
    	var found = false;
    	_.each(this.models,function(model) { 
    		if(model.getModel("team_starter_round_rel_core").get("athlete_id") === athlete_id)
    			found = true;
    	});
    	return found;
    }
});

