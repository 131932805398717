fantasy.util = fantasy.util || {};

	fantasy.util.checkEmailAddress = function(email) {
		// Source: http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    	return re.test(email);
	};

	fantasy.util.conformHandle = function(str) { 

		var outString = str.replace(/[`~!@#$%^&*()\s|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
		outString = outString.substr(0,16);
		return outString;

	};

	fantasy.util.arrayUnique = function(array) {
		// Source: http://stackoverflow.com/questions/19962759/jquery-unique-function-not-working-properly
    	return $.grep(array, function(el, index) {
        	return index == $.inArray(el, array);
   		});
	};

	fantasy.util.roundToTwoDecimals = function(arg)
	{
		return Math.round(arg * 100) / 100;
	};

	fantasy.util.scrollToID = function(id,duration, offset) {
		offset = offset || 0;
		
		$('html, body').animate({
	        scrollTop: $("#"+id).offset().top + offset
	    }, duration);
	};

	fantasy.util.findPos = function(obj) {
	    var curtop = 0;
	    if (obj.offsetParent) {
	        do {
	            curtop += obj.offsetTop;
	            obj = obj.offsetParent;
	        } while (obj);
	    }
	    return [curtop];
	};


fantasy.util.modelsArrayUnique = function(array, indexUniqueFieldName) {
	var indexMap = {};

	_.each(array, function(model) { 
		var val = model.get(indexUniqueFieldName);
		indexMap[val] = model;
	});

	return _.values(indexMap);
};

/** UUID GENERATOR..... **/
var lut = []; for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }
function e7()
{
  var d0 = Math.random()*0xffffffff|0;
  var d1 = Math.random()*0xffffffff|0;
  var d2 = Math.random()*0xffffffff|0;
  var d3 = Math.random()*0xffffffff|0;
  return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff]+'-'+
    lut[d1&0xff]+lut[d1>>8&0xff]+'-'+lut[d1>>16&0x0f|0x40]+lut[d1>>24&0xff]+'-'+
    lut[d2&0x3f|0x80]+lut[d2>>8&0xff]+'-'+lut[d2>>16&0xff]+lut[d2>>24&0xff]+
    lut[d3&0xff]+lut[d3>>8&0xff]+lut[d3>>16&0xff]+lut[d3>>24&0xff];
}


