fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


fantasy.packs.CompBeans.SportComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "sport_id",
        mdl_name: "SportComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      console.log("SportComposite, post super init", this, options);
      this.addModel("sport_core",new fantasy.packs.CompBeans.Sport({sport_id: this.getId()}));
      this.addCollection("primary_position_composites",new  fantasy.packs.CompBeans.PrimaryPositionCompositesForSportCollection(null,{sport_id: this.getId()}));
      this.addCollection("real_team_composites",new  fantasy.packs.CompBeans.RealTeamCompositesForSportCollection(null,{sport_id: this.getId()}));

    }

  });

fantasy.packs.CompBeans.PrimaryPositionComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "position_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options.model)
      	this.addModel("position_core",options.model);
      else 
      	this.addModel("position_core",new fantasy.packs.CompBeans.Sport({position_id: this.getId()}));
      // this.position_id = this.getModel("position_core").getId();
      // if(!this.position_id)
      // {
      // 	if(options && options[this.idAttribute])
      // 	{
      // 		this.position_id = options[this.idAttribute];
      // 	}
      // }
      this.addCollection("athletes",new fantasy.packs.CoreCollections.AthleteCompositesForPrimaryPosition(null,{position_id: this.getId()}));
    }

  });


/**
Using its super class, it gets a simple core collection of positions for the sport.
Then it converts those to composites and fetches those.
**/
fantasy.packs.CompBeans.PrimaryPositionCompositesForSportCollection = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
	idAttribute: "sport_id",
	model: fantasy.packs.CompBeans.PrimaryPositionComposite,
	coreCollectionClass: fantasy.packs.CoreCollections.PositionsForSport,
        mdl_name: "PrimaryPositionCompositesForSportCollection",
    use_cache: true,    
 initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("PrimaryPositionCompositesForSportCollection end init", this);
    }
});


fantasy.packs.CompBeans.RealTeamComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "real_team_id",
        mdl_name: "RealTeamComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options.model)
        this.addModel("real_team_core",options.model);
      else 
        this.addModel("real_team_core",new fantasy.packs.CompBeans.RealTeam({real_team_id: this.getId()}));

      this.addCollection("athletes",new fantasy.packs.CoreCollections.AthleteCompositesOnRealTeam(null,{real_team_id: this.getId()}));
    }

  });

fantasy.packs.CompBeans.RealTeamCompositesForSportCollection = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  idAttribute: "sport_id",
        mdl_name: "RealTeamCompositesForSportCollection",
    use_cache: true,      
  model: fantasy.packs.CompBeans.RealTeamComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.RealTeamsForASport,
 initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("RealTeamCompositesForSportCollection end init", this);
    }
});

