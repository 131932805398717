fantasy.packs.Auth = fantasy.packs.Auth || {};

fantasy.packs.Auth.FacebookSocial = {};


fantasy.packs.Auth.FacebookSocial.OUR_APP_ID = "1598948693454320";


fantasy.packs.Auth.FacebookLoginView = fantasy.present.Classes.Base.extend({ 
  template: fantasy.templates.facebook_login_view,
    initialize: function(options)
  {
    console.log("FacebookLoginView INIT Start");
    fantasy.present.Classes.Base.prototype.initialize.apply(this, arguments);
    if(options && options.invited)
    {
      var local_storage_item =     JSON.parse( localStorage.getItem( 'invite_to_accept' ) ) ;
      this.setContent({invited:true, invite_info: local_storage_item});
    }
    var self = this;
    this.boundStatusChangeCallback = _.bind(self.statusChangeCallback,this);

    // This function is called when someone finishes with the Login
    // Button.  See the onlogin handler attached to it in the sample
    // code below.
    window.facebookCheckLoginState = function() {
      FB.getLoginStatus(function(response) {
        self.boundStatusChangeCallback(response);
      });
    };

    //if facebook is already loaded, set new init method and call it
    if(window.FB)
    {
      this.setupFacebookAsyncInit();
        window.fbAsyncInit();
    }
    else
    {
      //else setup new init method and then load FB system
      this.setupFacebookAsyncInit();
      this.loadFacebookSystem();

    }
    this.render();
  },
  showError: function(error_message) { 
    this.$el.find(".error-message-area").html("<h3>Error</h3><p class=\"fd-error-msg\">" + error_message + "</p>");
  },
  setupFacebookAsyncInit: function() { 
    var self = this;

    window.fbAsyncInit = function() {
      console.log("FacebookLoginView fbAsyncInit Start");

      try { 
        FB.init({
          appId      : fantasy.packs.Auth.FacebookSocial.OUR_APP_ID,
          cookie     : true,  // enable cookies to allow the server to access 
                              // the session
          xfbml      : true,  // parse social plugins on this page
          version    : 'v2.8' // use graph api version 2.8
        });
      }
      catch(err)
      {
        console.error("Trouble initializing facebook for facebook login", err);
        self.showError("Can't load facebook login system -- are you on FireFox? Try disabling Tracking Protection in about:config > privacy.trackingprotection.pbmode.enabled  setting it to false");
        return;
      }
        // Now that we've initialized the JavaScript SDK, we call 
        // FB.getLoginStatus().  This function gets the state of the
        // person visiting this page and can return one of three states to
        // the callback you provide.  They can be:
        //
        // 1. Logged into your app ('connected')
        // 2. Logged into Facebook, but not your app ('not_authorized')
        // 3. Not logged into Facebook and can't tell if they are logged into
        //    your app or not.
        //
        // These three cases are handled in the callback function.

        FB.getLoginStatus(function(response) {
          self.boundStatusChangeCallback(response);
        });

      };

  },
  loadFacebookSystem: function() { 
    var self = this;
    window.fbsdk_load_error_handler = function(err) { 
      console.error("Trouble loading FB SDK",err);
    };
    try { 

      // Load the SDK asynchronously
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        js.onerror = function(err) { 
              console.error("Trouble loading FB SDK via js.onerror",err);
            self.showError("Can't load facebook login system -- are you on FireFox? Try disabling Tracking Protection in about:config > privacy.trackingprotection.pbmode.enabled  setting it to false");

        };
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
    catch(err)
    {
      console.error("Trouble initializing facebook for facebook login at script inclusion phase", err);
      self.showError("Can't load facebook login system -- are you on FireFox? Try disabling Tracking Protection in about:config > privacy.trackingprotection.pbmode.enabled  setting it to false");
      return;
    }
  },
  // This is called with the results from from FB.getLoginStatus().

  statusChangeCallback: function(response){ 
    var self = this;
      console.log("FacebookLoginView statusChangeCallback Start", response);

      // The response object is returned with a status field that lets the
      // app know the current login status of the person.
      // Full docs on the response object can be found in the documentation
      // for FB.getLoginStatus().
      if (response.status === 'connected') {
        fantasy.router._showLoadingPage();
        $(".fd-loading-msg").html("Logging in...");        
        // Logged into your app and Facebook.
        self.testAPI();
        self.sendTokenToServer();
      } else if (response.status === 'not_authorized') {
        // The person is logged into Facebook, but not your app.
        document.getElementById('status').innerHTML = 'Please log ' +
          'into this app.';
      } else {
        // The person is not logged into Facebook, so we're not sure if
        // they are logged into this app or not.
        document.getElementById('status').innerHTML = 'Please log ' +
          'into Facebook.';
      }
  },
  sendTokenToServer: function() { 
    var self = this;
   FB.getLoginStatus(function(response) {
      if (response.status === 'connected') {
        var accessToken = response.authResponse.accessToken;
        console.log("got accessToken, sending to server", accessToken);
         fantasy.packs.Auth.handleFacebookAuth(accessToken).done(function(text) { 
            console.log("FacebookLoginView handleSuccess callback successful facebook login, triggering logged_in on self.",self);
            self.trigger("logged_in");
          }).fail(function(err) { 
              $(".fd-loading-outer").removeClass("active");    
                $(".fd-loading-msg").html("Loading...");

            //TODO LET SERVER KNOW ABOUT ERROR
            self.showError("There has been an error with our system, we apologize.  Please contact us to let us know about the problem and or try again soon.");
            console.error("FacebookLoginView handleSuccess callback failure There has been an error!!!",err);
          });
      } 
    } );
  },
  // Here we run a very simple test of the Graph API after login is
  // successful.  See statusChangeCallback() for when this call is made.
  testAPI: function() {
    var self = this;
    console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', function(response) {
      console.log('Successful login for: ' + response.name);
      document.getElementById('status').innerHTML =
        'Thanks for logging in, ' + response.name + '!';
        //self.getAccessToken();
    });
  },
  logoutOfFacebook: function(callback) { 
      console.log("FacebookLoginView logoutOfFacebook Start");

    FB.logout(function(response) {
      console.log("FacebookLoginView logoutOfFacebook called back");

       // Person is now logged out
       callback();
    });

  },
  getAccessToken: function(callback) { 
    FB.getLoginStatus(function(response) {
      if (response.status === 'connected') {
        var accessToken = response.authResponse.accessToken;
        console.log("accessToken", accessToken);
        if(callback)
          callback(accessToken);
      } 
    } );

  }
});



  
fantasy.packs.Auth.FacebookSocial.loadFBIfNeeded = function(callback) { 
if(typeof window.FB !== "undefined" && FB)
{
  console.log("FB already loaded");
  callback();
}
else
{
    console.log("FB NOT already loaded");

  window.fbAsyncInit = function() {
    console.log("FB fbAsyncInit via fantasy.packs.Auth.FacebookSocial.loadFBIfNeeded");

   try { 
        FB.init({
          appId      : fantasy.packs.Auth.FacebookSocial.OUR_APP_ID ,
          cookie     : true,  // enable cookies to allow the server to access 
                              // the session
          xfbml      : true,  // parse social plugins on this page
          version    : 'v2.8' // use graph api version 2.8
        });
        callback();
      }
      catch(err)
      {
        console.error("Trouble initializing facebook for facebook login", err);
        self.showError("Can't load facebook login system -- are you on FireFox? Try disabling Tracking Protection in about:config > privacy.trackingprotection.pbmode.enabled  setting it to false");
        return;
      }
  };

 (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "//connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));




}


};



fantasy.packs.Auth.FacebookSocial._launchShareModal = function(url) { 

  var deferred = new $.Deferred();
  try {
    FB.ui({
    method: 'share',
    href: url,
    }, function(response){
      if (response && !response.error_message) {
            deferred.resolve({posted_knowable:true, posted:true});
      } else if(response && response.error_message) {
            deferred.resolve({posted_knowable:true, posted:false, error_message: response.error_message});
      } else
      {
        deferred.resolve({posted_knowable: false});
      }

    });
  }catch(err)
  {
    deferred.reject(err);
  }

  return deferred.promise();

};
 


fantasy.packs.Auth.FacebookSocial.launchShareModal = function(url) { 
  var deferred = new $.Deferred();

  fantasy.packs.Auth.FacebookSocial.loadFBIfNeeded(function() { 
    console.log("loaded fb if needed.");
    fantasy.packs.Auth.FacebookSocial._launchShareModal(url).done(function(arg) { 
      console.log("got success from share", arg);
      deferred.resolve(arg);
    }).fail(function(err) { 
      console.error("trouble with facebook share");
      deferred.reject(err);
     });
  });
  return deferred.promise();

};

