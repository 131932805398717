

fantasy.packs.Comps = fantasy.packs.Comps || {};
/**

expects a model option that is a leagueComposite, already loaded

**/
fantasy.packs.Comps.PageLeagueWeeksTab = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_league_week_tabs,
	className: "fd-page-league-week-tabs",
	initialize: function(options) {
		console.log("PageLeagueYouAreOnView INIT START",options);
		var self = this;
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.addView(new fantasy.packs.Comps.LeagueWeekTabs({model: this.model, round_id: options.round_id}),{selector: ".fd-dz-tabs-system"});
		this.render();
	},
		getPageTitle: function() { 
		return this.model.getModel("league_core").get("name"); 
	},
});

