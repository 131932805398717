
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageLandingView = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_landing,
	bodyClasses: "fdbc-landing fdbc-full-body-width",
	events: {"click .action-start-league" : "startLeagueModalLaunch"},
	initialize: function(options) {
		var self = this;
		if(options)
		{
			this.sport_id = options.sport_id;
			this.season_id = options.season_id;			
		}
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.addView(new fantasy.packs.UXWidgets.ImpactStatement({model: {statement: "Fantasy Dictator<br/>Where America's Fantasy Comes to Life"}}),{selector: ".fd-dz-home-mast"});
		this.addView(new fantasy.packs.UXWidgets.BandBeefyParagraph({model: {title: "About Fantasy Dictator",paragraph: fantasy.templates.about_us_blurb() }}),{selector: ".fd-dz-intro"});

		this.render();
	}
});
