
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageSportWithAthletesView = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_sport_with_athletes_view,
	bodyClasses: "fdbc-sport-athletes fdbc-sub-page fdbc-full-body-width",
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.sportComposite = options.sportComposite;
		var self = this;
		this.sport_id = options.sport_id;
		this.addView(new fantasy.packs.UXWidgets.ImpactStatement({model: {statement: "Players"}}),{selector: ".fd-dz-mast"});

		// self.addView(new fantasy.packs.Comps.SportCoreView({model:this.sportComposite}),{selector: ".fd-dz-sport"});
		//console.log("PageSportWithAthletesView about to build out views and append for each position: ", this.sportComposite.getCollection("primary_position_composites"));

		// _.each(this.sportComposite.getCollection("primary_position_composites").models, function(compositeModel) { 
		// 	//console.log("PageSportWithAthletesView position compositeModel in loop", compositeModel);
		// 	self.addView(new fantasy.packs.Comps.PrimaryPositionWithAthletesView({model: compositeModel}), {fineWithAppend: true, selector: ".fd-dz-positions"});
		// });
		_.each(this.sportComposite.getCollection("real_team_composites").models, function(compositeModel) { 
			//console.log("PageSportWithAthletesView real_team_composite compositeModel in loop", compositeModel);
			self.addView(new fantasy.packs.Comps.RealTeamWithAthletesView({model: compositeModel}), {fineWithAppend: true, selector: ".fd-dz-real_teams"});
		});

		this.render();
	},
	postRender: function() { 
		//console.log("PageSportWithAthletesView, ", this);
	}
});