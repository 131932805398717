
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageRoundForLeague = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_round_for_league,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.leagueComposite = options.leagueComposite;
		
		this.round_id = options.round_id;
		this.league_id = options.league_id;

		this.roundCompositeForLeague = new fantasy.packs.CompBeans.RoundForLeagueComposite({round_id: this.round_id, league_id: this.league_id},{leagueComposite: this.leagueComposite});

		this.addView(new fantasy.packs.Comps.LeagueMastView({model: this.leagueComposite}),{selector:".fd-dz-league"});

		this.addView(new fantasy.packs.Comps.RoundMastView({model: this.roundCompositeForLeague.getModel("round_core")}),{selector: ".fd-dz-round"});

		this.compositeGames = new fantasy.packs.CoreCollections.GameCompositesForLeagueAndRound(null,{leagueComposite: this.leagueComposite, round_id: this.round_id, league_id: this.league_id});
		window.tempcomp = this.compositeGames;
		
		this.addView(new fantasy.packs.Comps.GamesInRoundListView({collection: this.compositeGames }),{selector: ".fd-dz-games"});
		this.compositeGames.fetch();

		//this.addView(new fantasy.packs.Comps.GamesInRoundListView({collection: this.roundCompositeForLeague.getCollection("games") }),{selector: ".fd-dz-games"});

		this.roundCompositeForLeague.fetch();


		this.render();
	}
});