fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


fantasy.packs.CompBeans.UserComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "user_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      this.addModel("user_core",new fantasy.packs.CompBeans.User({user_id: this.getId()}));
      this.addCollection("leagues",new fantasy.packs.CoreCollections.LeaguesForUser(null,{user_id: this.getId()}));
      this.addCollection("inbound_invites", new fantasy.packs.CoreCollections.InvitesToMe());
    }

  });
