fantasy.packs.Comps = fantasy.packs.Comps || {};


fantasy.packs.Comps.PositionCoreView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.sub_position_core_view
});

fantasy.packs.Comps.RealTeamCoreView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.sub_real_team_core_view
});

fantasy.packs.Comps.SportCoreView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.sub_sport_core_view
});


fantasy.packs.Comps.AthleteResearchItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item athlete fd-athlete-item",
	template: fantasy.templates.sub_athlete_research_view
});

fantasy.packs.Comps.AthletesResearchListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-athlete-list",

	subViewClass: fantasy.packs.Comps.AthleteResearchItemView
});



fantasy.packs.Comps.HeaderWithCollapsibleListView =  fantasy.present.Classes.AssignableContainerView.extend({
	className: "fd-header-with-collapsible-list",
	events: { 
		"click .toggle": "handleToggle"
	},
	template: fantasy.templates.sub_primary_position_with_athletes,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.primaryPositionComposite = options.primaryPositionComposite || options.model;

		this.addView(new fantasy.packs.Comps.PositionCoreView({model: this.primaryPositionComposite.getModel("position_core")}),{selector:".fd-dz-position"});

		this.addView(new fantasy.packs.Comps.AthletesResearchListView ({collection: this.primaryPositionComposite.getCollection("athletes")}),{selector: ".fd-dz-athletes"});
		this.render();
	},
	handleToggle: function(e) { 
		this.$(".fd-collapsible").toggle();
	}
});


fantasy.packs.Comps.PrimaryPositionWithAthletesView =  fantasy.present.Classes.AssignableContainerView.extend({
	className: "fd-position-grouped-athletes",

	template: fantasy.templates.sub_primary_position_with_athletes,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.primaryPositionComposite = options.primaryPositionComposite || options.model;

		//this.addView(new fantasy.packs.Comps.PositionCoreView({model: this.primaryPositionComposite.getModel("position_core")}),{selector:".fd-dz-position"});

		this.addView(new fantasy.packs.Comps.AthletesResearchListView ({collection: this.primaryPositionComposite.getCollection("athletes")}),{selector: ".fd-dz-athletes"});
		this.render();
	}
});

fantasy.packs.Comps.RealTeamWithAthletesView =  fantasy.present.Classes.AssignableContainerView.extend({
	className: "fd-real-team-grouped-athletes",

	//TODO FIXZ THIS TEMPLATE

	//ENTIRE CLASS WORK IN PROGRESS
	template: fantasy.templates.sub_real_team_with_athletes,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.realTeamComposite = options.realTeamComposite || options.model;

		this.addView(new fantasy.packs.Comps.RealTeamCoreView({model: this.realTeamComposite.getModel("real_team_core")}),{selector:".fd-dz-real_team"});

		this.addView(new fantasy.packs.Comps.AthletesResearchListView ({collection: this.realTeamComposite.getCollection("athletes")}),{selector: ".fd-dz-athletes"});
		this.render();
	}
});
