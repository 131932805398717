fantasy.sw = fantasy.sw || {};


fantasy.sw.SWManager = function() { 
	var self = this;

	self.loadWorker = function(path_to_worker) { 

		try {
			if ('serviceWorker' in navigator) {
			  window.addEventListener('load', function() {
			    navigator.serviceWorker.register(path_to_worker).then(function(registration) {
			      // Registration was successful
			      console.log('ServiceWorker registration successful with scope: ', registration.scope, path_to_worker);
			    }).catch(function(err) {
			      // registration failed :(
			      console.log('ServiceWorker registration failed: ', err,path_to_worker);
			    });
			  });
			}
		}
		catch(err)
		{
			console.error("trouble registering service worker", path_to_worker);
		}


	};



	return self;


};
fantasy.sw.swManager = new fantasy.sw.SWManager();
fantasy.sw.swManager.loadWorker("/sw_one.js");
