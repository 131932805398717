fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


/**

**/
fantasy.packs.CompBeans.YourGameComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "f_game_id",
     mdl_name: "GameComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
    console.log("GameComposite", attributes,options);

      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(!this.getId())
      {
        console.error("YourGameComposite initialized without an id");
      }
 
      if(options && options.model)
        this.addModel("f_game_core",options.model);
      else 
        this.addModel("f_game_core",new fantasy.packs.CompBeans.FantasyGame({f_game_id: this.getId()}));

      var fGameCore = this.getModel("f_game_core");
      if(options && options.your_f_team_id)
      {
      	this.your_f_team_id = options.your_f_team_id;
      }
      else
      {
      	this.your_f_team_id = null;
      }

      if(options && options.round_id)
        this.round_id = options.round_id;

      this.addModel("league_core",new fantasy.packs.CompBeans.League({league_id: fGameCore.get("league_id")}), {depth:1});
      this.addModel("round_core",new fantasy.packs.CompBeans.Round( {round_id: fGameCore.get("round_id")}), {depth:1});
      this.addModel("sport_core",new fantasy.packs.CompBeans.Sport({sport_id: null}), {depth:2});

      this.addModel("sport_core",new fantasy.packs.CompBeans.Sport({sport_id: null}), {depth:2});
      this.addModel("your_team",new fantasy.packs.CompBeans.FantasyTeam( ), {depth:1});
      this.addModel("their_team",new fantasy.packs.CompBeans.FantasyTeam( ), {depth:1});
      this.addModel("your_opponent", new fantasy.packs.CompBeans.User(),{depth:2});

      this.their_f_team_id = null;

      this.addCollection("your_team_lineup",new fantasy.packs.CoreCollections.TeamStarterRoundRelCompositesForTeamRound(null,{f_team_id: fGameCore.get("a_team_id"), round_id: fGameCore.get("round_id")}), {depth:1});
      this.addCollection("their_team_lineup",new fantasy.packs.CoreCollections.TeamStarterRoundRelCompositesForTeamRound(null,{f_team_id: fGameCore.get("b_team_id"), round_id: fGameCore.get("round_id")}), {depth:1});
      
     // this.addCollection("your_roster", new fantasy.packs.CoreCollections.AthletesOnAFTeam(null,{f_team_id: this.your_f_team_id}),{depth:1});
      this.addCollection("your_roster", new fantasy.packs.CoreCollections.AthletesOnFTeamWithRoundStats(null,{f_team_id: this.your_f_team_id, round_id: this.round_id}),{depth:1});



      this._yourLeftOverRosterCollection = new fantasy.Collections.Classes.Base([],{});
      this.listenTo(this.getCollection("your_team_lineup"),"sync",this.calculateAvailableRoster);
      this.listenTo(this.getCollection("your_roster"),"sync",this.calculateAvailableRoster);
      this.calculateAvailableRoster();

    },
    shouldFetch: function() { 
       if(this.getId() === null || ! this.getId()) {
        return false;
      }

      return true; 
    },
    fetch: function(options) { 
      if(this.getId() === null || ! this.getId())
      {
        console.error("fantasy.packs.CompBeans.YourGameComposite fetching but doesn't have an id....", this);
      }
      return fantasy.beans.Classes.CompositeModel.prototype.fetch.apply(this,arguments);

    },
    calculateAvailableRoster: function() { 
    	var rosterCollection = this.getCollection("your_roster");
    	var yourLineupCollection = this.getCollection("your_team_lineup");
    	console.log("calculateAvailableRoster START roster, lineup",rosterCollection,yourLineupCollection);

    	this._yourLeftOverRosterCollection.reset();
    	var leftArray = [];

    	_.each(rosterCollection.models,function(model) { 
    		if(yourLineupCollection.containsAthleteId(model.get("athlete_id")))
    		{

    		}
    		else
    		{
    			leftArray.push(model);
    		}
    	});

    	this._yourLeftOverRosterCollection.reset(leftArray);
      this._yourLeftOverRosterCollection.loaded = true;
    	this._yourLeftOverRosterCollection.trigger("sync");
    	console.log("calculateAvailableRoster END roster, lineup, leftover",rosterCollection,yourLineupCollection,this._yourLeftOverRosterCollection);

    	return this;
    },
    getYourLeftOverRoster: function() { 
    	return this._yourLeftOverRosterCollection;
    },
        levelPrep: function() { 
      var fGameCore = this.getModel("f_game_core");
      fGameCore.setId(this.getId());

      if(fGameCore.loaded)
      {

      	this.round_id = fGameCore.get("round_id");
      	var a_team_id = fGameCore.get("a_team_id");
      	var b_team_id = fGameCore.get("b_team_id");


      	if(this.your_f_team_id === null)
      	{
      		this.your_f_team_id = a_team_id;
      	}

      	if(a_team_id === this.your_f_team_id)
      		this.their_f_team_id = b_team_id;
      	else
      		this.their_f_team_id = a_team_id;

        this.getModel("your_team").setId(this.your_f_team_id);
        this.getModel("their_team").setId(this.their_f_team_id);

      	this.getCollection("your_team_lineup").f_team_id = this.your_f_team_id;
      	this.getCollection("your_team_lineup").round_id = this.round_id;

      	this.getCollection("your_roster").f_team_id = this.your_f_team_id;
        this.getCollection("your_roster").round_id = this.round_id;

      	this.getCollection("their_team_lineup").f_team_id = this.their_f_team_id;
      	this.getCollection("their_team_lineup").round_id = this.round_id;	

      	this.getModel("league_core").setId(fGameCore.get("league_id"));
      	this.getModel("round_core").setId(this.round_id);

        this.getModel("your_opponent").setId(this.getModel("their_team").get("user_id"));

      	console.log("YourGameComposite levelPrep, set ids", this);
      }
      var leagueCore = this.getModel("league_core");
      if(leagueCore.loaded)
      {
      	this.getModel("sport_core").setId(leagueCore.get("sport_id"));
      }

    }

  });



/**
Composite model that is passed a leagueComposite and a fantasyGameCore
and then loads
team core models for both teams.

**/
fantasy.packs.CompBeans.FantasyGameComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "f_game_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.leagueComposite && options.fantasyGameCore)
      {
      	this.leagueComposite = options.leagueComposite;
      	this.addModel("league", this.leagueComposite);
      	this.set("league_id", this.leagueComposite.getId());
      	this.fantasyGameCore = options.fantasyGameCore;
      	this.addModel("f_game_core", options.fantasyGameCore);

       this.addModel("round_core",new fantasy.packs.CompBeans.Round( {round_id: this.fantasyGameCore.get("round_id")}), {depth:1});


      }
      else
      {
      	throw "leagueComposite required for RoundForLeagueComposite";
      }
      this.set("your_f_team_id",this.leagueComposite.get("your_f_team_id"));
      this.addModel("a_team",new fantasy.packs.CompBeans.FantasyTeam( {f_team_id: this.fantasyGameCore.get("a_team_id")}));
      this.addModel("b_team",new fantasy.packs.CompBeans.FantasyTeam( {f_team_id: this.fantasyGameCore.get("b_team_id")}));
    },
    _normalizeWinnerInfo: function() { 
    	var coreModel = this.getModel("f_game_core");
    	var winningTeamId = coreModel.get("winning_team_id");
    	if(winningTeamId)
    	{
    		var aTeam = this.getModel("a_team");
    		var bTeam = this.getModel("b_team");
    		var winningTeam = null;
    		var losingTeam = null;

    		if(winningTeamId === coreModel.get("a_team_id")) {
    			winningTeam = aTeam;
    			losingTeam = bTeam;
    		}
    		if(winningTeamId === coreModel.get("b_team_id")){
    			winningTeam = bTeam;
    			losingTeam = aTeam;
    		}
    		if(winningTeam)
    		{
    			winningTeam.setMeta("winner", true);
    			winningTeam.setMeta("loser", false);

    			this.addModel("winning_team",winningTeam);
    		}
    		if(losingTeam)
    		{
    			losingTeam.setMeta("loser", true);
    			losingTeam.setMeta("winner", false);
    			
    			this.addModel("losing_team",losingTeam);
    		}    		
    		aTeam.trigger("normalize_sync");
    		bTeam.trigger("normalize_sync");
    		this.trigger("normalize_sync");
    	}
    },
    fetch: function(options) { 
    	var self = this;

    	return this.fetchList([this.getModel("a_team"),this.getModel("b_team"), this.getModel("round_core")]).done(function() { 
    		self._normalizeWinnerInfo();
    	});
    }
  });


/**
	GameCompositesForLeagueAndRound
	internally contains a fantasy.packs.CoreCollections.GamesForLeagueAndRound
	Requires: 
	league_id
	round_id
	leagueComposite

**/

fantasy.packs.CoreCollections.GameCompositesForLeagueAndRound = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.FantasyGameComposite,
	idAttribute: "league_id",
   initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      if(options && options.round_id)
      	this.round_id = options.round_id;
      if(options && options.leagueComposite)
      	this.leagueComposite = options.leagueComposite;

      this.coreCollection = new fantasy.packs.CoreCollections.GamesForLeagueAndRound(null,{round_id:this.round_id,league_id: this.league_id});
    },
    fetch:function(options) {
    	var composites = [];
    	var self = this;
    	var deferred = new $.Deferred();
      var fetchPromise = fantasy.cstore.cstore.getOrFetchItem(  this.coreCollection,400);
      fetchPromise.done(function(arg) { 
        self.coreCollection = arg;
    		var promises = [];

    		_.each(self.coreCollection.models,function(coreModel) { 
    			var composite = new fantasy.packs.CompBeans.FantasyGameComposite({f_game_id: coreModel.getId()},{leagueComposite: self.leagueComposite, fantasyGameCore:coreModel});
    			composites.push(composite);
    			promises.push(composite.fetch());
    		});

    		$.when.apply($, promises).done(function() { 
    			self.reset(composites);
    			self.trigger("sync");
    			deferred.resolve(self);
    		}).fail(function(err) { 
    			console.error("trouble with individual fetches of FantasyGameComposite models",err,self);
    			deferred.reject(err);
    		});

    	}).fail(function(err) { 
    		console.error("trouble loading core collection GamesForLeagueAndRound ", err,self);
   			deferred.reject(err);  		
    	});
      var promise = deferred.promise();
      promise.done(function() { 
        self.loaded = true;
      });
      return promise;


    }
});
