fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


/**
Requires leagueComposite option
**/
fantasy.packs.CompBeans.RoundForLeagueComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "round_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.leagueComposite)
      {
      	this.leagueComposite = options.leagueComposite;
      	this.addModel("league", this.leagueComposite);
      	this.set("league_id", this.leagueComposite.getId());
      }
      else
      {
      	throw "leagueComposite required for RoundForLeagueComposite";
      }

      this.addModel("round_core",new fantasy.packs.CompBeans.Round( {round_id: this.getId()}));
      console.log("RoundForLeagueComposite", this);
	  this.games = new fantasy.packs.CoreCollections.GamesForLeagueAndRound(null,{league_id: this.get("league_id"), round_id: this.getId()});
      this.addCollection("games",this.games);

    }

  });


/**
Requires leagueComposite option and your user_id and round_id

fantasy.packs.CompBeans.RichRoundComposite({round_id: round_id},{leagueComposite: leagueComposite, user_id: user_id}));

**/
fantasy.packs.CompBeans.RichRoundComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "round_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
        this.leagueComposite = options.leagueComposite;
        this.addModel("league_composite", this.leagueComposite);
        this.set("league_id", this.leagueComposite.getId());
        this.user_id = options.user_id;

        console.log("RichRoundComposite initialize with user id", this.user_id, " league id", this.leagueComposite.get("league_id") );

        this.addModel("round_core",new fantasy.packs.CompBeans.Round( {round_id: this.getId()}));
        console.log("RoundForLeagueComposite", this);
        var games = new fantasy.packs.CoreCollections.GamesForLeagueAndRound(null,{league_id: this.get("league_id"), round_id: this.getId()});
        this.addCollection("games_simple", games, {depth:0});
    
        var gameComposites = new fantasy.packs.CoreCollections.GameCompositesForLeagueAndRound (null,{leagueComposite: this.leagueComposite, round_id: this.getId(), league_id: this.get("league_id")});
        this.addCollection("game_composites", gameComposites, {depth:0});

        var yourFantasyTeamCompositeCollection = new fantasy.packs.CompBeans.SingleUserFantasyTeamCompositeCollection([],{user_id: this.user_id, league_id: this.leagueComposite.get("league_id")});
        this.addCollection("your_fantasy_team_composite_col", yourFantasyTeamCompositeCollection,{depth:0});

        var yourGameComposite = new fantasy.packs.CompBeans.YourGameComposite({f_game_id: null, round_id: this.getId()},{f_game_id: null,your_f_team_id:null, round_id: this.getId() });
        this.addModel("your_game_composite", yourGameComposite, {depth: 1, load_fail_okay: true});


    }, 
    levelPrep: function() { 
      if(this.getCollection("your_fantasy_team_composite_col").length > 0 && this.getCollection("game_composites")) {
        var your_f_game_id = null;
        var your_fantasy_team_composite_col = this.getCollection("your_fantasy_team_composite_col");
        var your_fantasy_team_composite = your_fantasy_team_composite_col.models[0];

        
        this.getModel("your_game_composite").set("your_f_team_id", your_fantasy_team_composite.getModel("f_team_core").getId());
        this.getModel("your_game_composite").your_f_team_id =  your_fantasy_team_composite.getModel("f_team_core").getId();


        _.each(this.getCollection("game_composites").models, function(gameComposite) { 
          if(gameComposite.getModel("f_game_core").get("a_team_id") === your_fantasy_team_composite.getModel("f_team_core").getId() ||
            gameComposite.getModel("f_game_core").get("b_team_id") === your_fantasy_team_composite.getModel("f_team_core").getId())
          {
            your_f_game_id = gameComposite.getId();
            self.your_f_game_id = gameComposite.getId();
          }
        });
        if(your_f_game_id !== null)
        {
          this.getModel("your_game_composite").set("f_game_id", your_f_game_id);
          this.getModel("your_game_composite").f_game_id = your_f_game_id;
          this.getModel("your_game_composite").setId(your_f_game_id);
          console.log("fantasy.packs.CompBeans.RichRoundComposite set f_game_id to ", your_f_game_id);
        }

      }
    }

  });

