/**

actual routes for client side javascript backbone routes go here

**/

fantasy.utilroute = fantasy.utilroute || {};

/**
@see fantasy.utilroute.RtopClass in modules/base/abstract_router --- it has lots of useful functions in it that we use here.

**/
fantasy.utilroute.FantasyRouter = fantasy.utilroute.RtopClass.extend({

	 routes: fantasy.pure_routes,
//{}
 //     "/"                                                   : "landingPage",
	//    ""                                                    : "landingPage",
 //     "home/"                                                   : "homePage",
 //     "home"                                                   : "homePage",
 //     "/home/"                                                   : "homePage",
 //     "login/?invited=true&lid=:league_invite_id"           : "loginPage",
 //     "login/"                                              : "loginPage",
 //     "league/?league_id=:league_id"                     : "myLeague",
 //     "league/fantasy_team/?league_id=:league_id&f_team_id=:f_team_id": "fantasyTeam",
	//    "league/round/?league_id=:league_id&round_id=:round_id"      : "leagueRound",
 //     "league/"                                            : "homePage",      
 //     "league/your/game/?f_game_id=:f_game_id&your_f_team_id=:your_f_team_id"             : "yourGame",  
 //     "league/drafting/?league_id=:league_id"              : "drafting",  
 //     "dicmove/:point_journal_id/:url_name/"                         : "dicMovePage",
 //     "league/weeks/?league_id=:league_id&round_id=:round_id"  : "leagueTabs",

 //     "sport/?sport_id=:sport_id"                           : "sportHome",
 //     "athletes/?athlete_id=:athlete_id"                     : "athlete",
 //     "athletes/"                                           : "sportHome",
 //     "about/"                                               : "about",
 //     "invite/info/?lid=:league_invite_id&ac=:invite_auth_code&sh=:sh" : "inviteLookup",
 //     "/invite/info/?lid=:league_invite_id&ac=:invite_auth_code&sh=:sh" : "inviteLookup",
 //     "accepted_invite/"                                     : "acceptedInvite",
 //     "/accepted_invite/"                                     : "acceptedInvite",
 //     "season/"                                            : "seasons",          
 //     "standing/"                                            : "standings",
 //     "admin/point_journal/"       : "handlePointJournalAddEdit",
 //     "admin/season/"       : "handleAdminSeason",


 //     "logout/?tk=:tk"                    : "handleLogout"

	//  },

  initialize: function(options) {
    fantasy.utilroute.RtopClass.prototype.initialize.apply(this,arguments);
    var self = this;

    this.base_site_title = "Fantasy Dictator";
    
    this.modalManager = new fantasy.packs.Modal.ModalManager();

    this.listenTo(this.userState, "logged_out", function() {
      self.navigateWrapper("login/");
      console.log("ROUTER CAUGHT logged_out EVENT, REDIRECTED TO HOMEPAGE");
    });

   
  },
  changePageView: function(pageView) {
    if(pageView && pageView.render_immediately)
      pageView.render();

    fantasy.utilroute.RtopClass.prototype.changePageView.apply(this,arguments);
   // $(".fd-loading-outer").hide().removeClass("active");    
   $(".fd-loading-outer").removeClass("active");
    _.delay(function() { 
      $(".fd-loading-outer").removeClass("loading_on");
    },200);

    if(!this.has_done_one_route)
      fantasy.packs.Tracking.trackTimingSinceBodScript("FD Loading Timings","first_page_appended","First Page Appended");
    else
      this.has_done_one_route = true;


    if(typeof window.ga !=="undefined" && ga && !isBot)
    {
        ga('send', 'pageview');
    }

    this.modalManager.closeAll();
  },
  _showLoadingPage: function() { 
    // var loadingPage = new fantasy.packs.Comps.PageLoading();
    // this._untrackedPageShow(loadingPage);   

    $(".fd-loading-msg").html("Loading...");

    if($(".fd-loading-outer").hasClass("active"))
      return;

      $(".fd-loading-outer").addClass("active loading_on");

    // $(".fd-loading-outer").show(10,function() { 
    //   $(".fd-loading-outer").addClass("active");
    // });
  },
  /**
  if authenticated, call success callback function

  if not, send to signin page.

  @param success callback function
  @returns nada
  **/
  requireAuthed: function(success) {
    var self = this;
    self.userState.getPromise("user_is_authenticated").done(success).fail(function(err) {
    console.log("NOT LOGGED IN, about to navigate to login route.");
      //not authenticated
      self.navigateWrapper("login/");
    });
  },
  requireUserLoadedAndCurrentRoundIDed: function(success) {
    var self = this;    
    // if(!self.userState.passiveIsLoggedIn())
    // {
    //   self.navigateWrapper("login/");
    //   return;
    // }

    self.requireAuthed(function() { 
      self.userState.getPromise("user_loaded").done(function() { 
          self.userState.getSiteState().getPromise("current_round_identified").done(success).fail(function(err) {
            console.log("USER FAILED LOADED, about to navigate to login route.");
            //not authenticated
              self.navigateWrapper("login/");
          });
      }).fail(function(err) {
      console.log("USER FAILED LOADED, about to navigate to login route.");

        //not authenticated
        self.navigateWrapper("login/");
      });
    });

  },
 requireUserLoaded: function(success) {
    var self = this;    
    self.requireAuthed(function() { 
      self.userState.getPromise("user_loaded").done(success).fail(function(err) {
      console.log("USER FAILED LOADED, about to navigate to login route.");
        //not authenticated
        self.navigateWrapper("login/");
      });
    });
  },
   requireGroup: function(group_name,success) {
      var self = this;
      self.requireUserLoaded(function() { 
          if(self.userState.isInGroup(group_name)) {
            success();
          }
          else
          {
            console.log("USER not in proper group.  Navigating home.");
            self.navigateWrapper("/");
          }
      });
  },
  editConfirmUserHandle: function() { 
    var self = this;
    self.requireAuthed(function () {
      self.changePageView(new fantasy.packs.Comps.PageEditConfirmUserHandle({model: self.userState.userComposite.getModel("user_core")}));
    });
  },
  goToUserDashboard: function() {
    var self = this;
    self.requireAuthed(function () {
      self.changePageView(new fantasy.packs.Pages.UserDashboardPage({model: self.userState}));
    });
  },
  handleLogout: function(tk) { 
    var self = this;
    if(tk === self.userState.getTK()) {
      var logoutView = new fantasy.packs.Auth.LogoutView();
      self.listenToOnce(logoutView,"logged_out", function() { 
        console.log("router received logged_out view");
        self._showLoadingPage();            
        fantasy.cstore.cstore.reset();
        console.log("cleared cache");
        self.userState.reinit();
        self.userState.setNotLoggedIn();

        self.userState.trigger("sync");
        console.log("user really logged out, state reinited.");
        logoutView.remove();
        self.navigateWrapper("/");
      });
      self.changePageView(logoutView);
    } else
    {
      console.error("can't find proper TK", tk, self.userState.getTK());
    }
  },
  genericPage: function() { 
    this._showLoadingPage();    
    var self = this;
    var href = Backbone.history.getFragment();
    var parts = href.split("/");
    var part = null;
    if(parts.length > 0)
    {
      part = parts[0];
    }
    if(!part)
    {
      console.error("boooo bad generic page", href, part);
        self.navigateWrapper("/");
        return;
    }
    var data = fantasy.packs.Comps.content[part];
    var use_template = false;
    if(!data)
    {
      if(fantasy.templates["page_" + part])
      {
        use_template = true;
         data = fantasy.templates["page_" + part];
       }
       else {
        console.error("boooo bad generic page, no data or template", href, part);
        self.navigateWrapper("/");
        return;
      }
    }
    if(use_template)
    self.changePageView(new fantasy.packs.Comps.PageGeneric({template: data, model: {}}));
      else
    self.changePageView(new fantasy.packs.Comps.PageGeneric({model: data}));
     console.log("showed generic page", part);
  },
  loginPage: function(league_invite_id) { 
    var self = this;
    console.log("loginPage route handler");
    this._showLoadingPage();    
    var invited = false;
    if(league_invite_id && typeof league_invite_id === "string")
      invited = true;    
    var view = new fantasy.packs.Auth.GenericLoginView({invited: invited});
     this.changePageView(view);

  },
  googleLoginPage: function(league_invite_id) { 
    this._showLoadingPage();    

    var invited = false;
    if(league_invite_id && typeof league_invite_id === "string")
      invited = true;

    console.log("loginPage route. Invited?", invited);
    var self = this;
    var view = new fantasy.packs.Auth.GoogleLoginButtonView({invited: invited});
    self.listenToOnce(view,"logged_in", function() { 
      console.log("router listener to logged_in in loginPage -- Got Logged In event, going to check if fully logged in.");
        
        self.userState.askServerForAuthState().done(function() { 
          console.log("router PASS userState askServerForAuthState About to navigate to homepage.");
          $(".fd-loading-msg").html("Loading...");      

          if(!invited)
            self.navigateWrapper("/");
          else
            self.navigateWrapper("/accepted_invite/");

        }).fail(function(err) { 
          console.error("Trouble checking if user is logged in.",err);
          self.navigateWrapper("/login_trouble/");
        });
    });
    this.changePageView(view);
  },
  homePage: function(){
    this._showLoadingPage();    
    var self = this;
    self.requireUserLoadedAndCurrentRoundIDed(function() { 

      var handleEdits = fantasy.userState.userComposite.getModel("user_core").get("handle_edits_count");
      if(handleEdits===0)
      {
        self.navigateWrapper("/mysettings/handle/");
        return;
      }


      self.changePageView(new fantasy.packs.Comps.PageHomeView({
        userComposite: fantasy.userState.userComposite, 
        season_id: fantasy.userState.getSiteState().getCurrentSeasonId(), 
        sport_id: fantasy.userState.getSiteState().getCurrentSportId()
      }));
    });
  },
  landingPage: function(){
    console.log("landingPage top");
    if(this.userState.passiveIsLoggedIn())
    {
      console.log("landing page redirecting to home");
      this.navigateWrapper("/home/");
      return;
    }

    if(!isBot)
      this._showLoadingPage();   

    var self = this;
    self.userState.getPromise("user_is_authenticated").done(function() { 
      self.navigateWrapper("/home/");
      return;
    }).fail(function(err) {
      self.changePageView(new fantasy.packs.Comps.PageLandingView({
        season_id: fantasy.userState.getSiteState().getCurrentSeasonId(), 
        sport_id: fantasy.userState.getSiteState().getCurrentSportId()
      }));
    });


  },


  inviteLookup: function(league_invite_id,invite_auth_code,sh) { 
    var self = this;
    console.log("inviteLookup route handler");
    this._showLoadingPage();    

    var view = new fantasy.packs.Comps.PageViewReceivedInvite({league_invite_id: league_invite_id, invite_auth_code: invite_auth_code, sh:sh });
    self.listenToOnce(view,"accepted_invite", function() { 

      self.userState.getPromise("user_is_authenticated").done(function() { 
         self.userState.getPromise("user_loaded").done(function() { 
            
            //Already logged in, so, let's just go to accept invite page.
            self.navigateWrapper("/accepted_invite/");


         }).fail(function(err) {
          console.log("USER FAILED LOADED, about to navigate to login route.");
          self.navigateWrapper("login/?invited=true&lid=" + league_invite_id);

          });


      }).fail(function(err) {
        console.log("NOT LOGGED IN, about to navigate to login route.");
        //not authenticated
        self.navigateWrapper("login/?invited=true&lid=" + league_invite_id);
      });
 

    });
    self.changePageView( view);

  },
  acceptedInvite: function() {
    this._showLoadingPage();    

    console.log("GOT TO acceptedInvite");
    var self = this;
    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      self.changePageView(new fantasy.packs.Comps.PageJoiningLeague());
    });
  },
  drafting: function(league_id) { 


 this._showLoadingPage();    
    var self = this;
    if(!league_id)
    {
      console.error("myLeague league_id is required!!!");
      return;
    }
    league_id = parseInt(league_id);
    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      var leagueComposite = new fantasy.packs.CompBeans.LeagueComposite({league_id: league_id});


   var promise = fantasy.cstore.cstore.getOrFetchItem(leagueComposite,220);
      promise.done(function(arg) { 
        leagueComposite = arg;

        self.changePageView(new fantasy.packs.Comps.PageDrafting({leagueComposite: leagueComposite}));
      }).fail(function(err) { 
        console.error("ERROR loading league Composite", err);
      });
    });
  },
  yourGame: function(f_game_id,your_f_team_id) { 
  this._showLoadingPage();    
    var self = this;
    if(!f_game_id || !your_f_team_id)
    {
      console.error("f_game_id and your_f_team_id are required!!!");
      return;
    }
    f_game_id = parseInt(f_game_id);
    your_f_team_id = parseInt(your_f_team_id);

    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      var yourGameComposite = new fantasy.packs.CompBeans.YourGameComposite({f_game_id: f_game_id},{f_game_id: f_game_id,your_f_team_id:your_f_team_id });
   var promise = fantasy.cstore.cstore.getOrFetchItem(yourGameComposite,220);
      promise.done(function(arg) { 
        yourGameComposite = arg;

        self.changePageView(new fantasy.packs.Comps.PageMyGameView({gameComposite: yourGameComposite}));
      }).fail(function(err) { 
        console.error("ERROR loading game Composite", err);
      });
    });
//fantasy.packs.Comps.PageMyGameView
  },
  myLeague: function(league_id){
    this._showLoadingPage();    
    var self = this;
    if(!league_id)
    {
      console.error("myLeague league_id is required!!!");
      return;
    }
    league_id = parseInt(league_id);
    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      var leagueComposite = new fantasy.packs.CompBeans.LeagueComposite({league_id: league_id});


   var promise = fantasy.cstore.cstore.getOrFetchItem(leagueComposite,220);
      promise.done(function(arg) { 
        leagueComposite = arg;

        self.changePageView(new fantasy.packs.Comps.PageLeagueYouAreOnView({leagueComposite: leagueComposite}));
      }).fail(function(err) { 
        console.error("ERROR loading league Composite", err);
      });
    });
  },
  dicmoveTypes: function() { 

    var self = this;
    this._showLoadingPage();
    self.changePageView(new fantasy.packs.Comps.PagePointCategories());
  },
  facebookLoginPage: function() { 
  this._showLoadingPage();    
  var league_invite_id = false;
    var invited = false;
    if(league_invite_id && typeof league_invite_id === "string")
      invited = true;

    console.log("facebookLoginPage route. Invited?", invited);
    var self = this;
    var view = new fantasy.packs.Auth.FacebookLoginView({invited: invited});
    self.listenToOnce(view,"logged_in", function() { 
      console.log("facebookLoginPage router listener to logged_in in loginPage -- Got Logged In event, going to check if fully logged in.");
        
        self.userState.askServerForAuthState().done(function() { 
          console.log("facebookLoginPage router PASS userState askServerForAuthState About to navigate to homepage.");
          $(".fd-loading-msg").html("Loading...");      

          if(!invited)
            self.navigateWrapper("/");
          else
            self.navigateWrapper("/accepted_invite/");

        }).fail(function(err) { 
          console.error("facebookLoginPage Trouble checking if user is logged in.",err);
          self.navigateWrapper("/login_trouble/");
        });
    });
    this.changePageView(view);

  },
  dicmoves: function() { 
    var self = this;
      this._showLoadingPage();    
      self.changePageView(new fantasy.packs.Comps.PagePointJournals({sport_id: self.userState.getSiteState().getCurrentSportId()}));
  },
  dicMovePage: function(point_journal_id,url_name) { 
    var self = this;
      this._showLoadingPage();    
      self.changePageView(new fantasy.packs.Comps.PagePointJournal({point_journal_id: point_journal_id, url_name: url_name}));
      console.log("changed to dicMovePage");
   },
leagueTabs: function(league_id, round_id){
    this._showLoadingPage();    
    var self = this;
    round_id = round_id || null;
    if(!league_id)
    {
      console.error("leagueTabs league_id is required!!!");
      return;
    }
    if(round_id!==null)
      round_id = parseInt(round_id);
    league_id = parseInt(league_id);
    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      var leagueComposite = new fantasy.packs.CompBeans.LeagueComposite({league_id: league_id});
      var promise = fantasy.cstore.cstore.getOrFetchItem(leagueComposite,360);
      promise.done(function(arg) { 
        leagueComposite = arg;
        self.changePageView(new fantasy.packs.Comps.PageLeagueWeeksTab({model: leagueComposite, round_id: round_id}));
      }).fail(function(err) { 
        console.error("ERROR loading league Composite", err);
      });
    });
  },
  fantasyTeam: function(league_id,f_team_id){
    this._showLoadingPage();
    var self = this;
    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      var fantasyTeamComposite = new fantasy.packs.CompBeans.FantasyTeamComposite({f_team_id: f_team_id, league_id: league_id});
      fantasyTeamComposite.fetch().done(function() { 
        self.changePageView(new fantasy.packs.Comps.PageFantasyTeamView({fantasyTeamComposite: fantasyTeamComposite}));
      }).fail(function(err) { 
        console.error("ERROR loading fantasyTeam Composite", err);
      });
    });

  },
  about: function() { 
     this._showLoadingPage();    
   
    this.changePageView(new fantasy.packs.Comps.PageAbout());
  },
  seasons: function() { 
    this._showLoadingPage();    

    this.changePageView(new fantasy.packs.Comps.PageSeasons());
  },
  standings: function() { 
    this.changePageView(new fantasy.packs.Comps.PageStandings());
  },    
  contactPage: function() { 
    this.changePageView(new fantasy.packs.Comps.PageContact());
  },
  sportHome: function(sport_id) {
    this._showLoadingPage();

    if(typeof sport_id === "object")
      sport_id = 1;

    sport_id = sport_id || userState.getSiteState().getCurrentSportId();
    var self = this;
      var model = new fantasy.packs.CompBeans.SportComposite({sport_id: sport_id});
      var promise = fantasy.cstore.cstore.getOrFetchItem(model,360);
      promise.done(function(arg) { 
        model = arg;
        self.changePageView(new fantasy.packs.Comps.PageSportWithAthletesView({sportComposite: model}));
      }).fail(function(err) { 
        console.error("ERROR loading SportComposite", err);
      });

  },
  leagueRound: function(league_id,round_id) { 
    this._showLoadingPage();    
    console.log("leagueRound route start");
    var self = this;
    self.requireUserLoadedAndCurrentRoundIDed(function() { 
      var leagueComposite = new fantasy.packs.CompBeans.LeagueComposite({league_id: league_id});
      leagueComposite.fetch().done(function() { 
        self.changePageView(new fantasy.packs.Comps.PageRoundForLeague({leagueComposite: leagueComposite, league_id: league_id, round_id: round_id}));
      }).fail(function(err) { 
        console.error("ERROR loading league Composite", err);
      });
    });

  },

  athlete: function(athlete_id) {

  this._showLoadingPage();    
    console.log("athlete route start");
    var self = this;
      var model = new fantasy.packs.CompBeans.AthleteComposite({athlete_id: athlete_id},{round_id: self.userState.getSiteState().getCurrentRoundId()});
      var promise = fantasy.cstore.cstore.getOrFetchItem(model,360);

      promise.done(function(arg) { 
        model = arg;
        self.changePageView(new fantasy.packs.Comps.PageAthlete({athleteComposite: model, athlete_id: athlete_id}));
      }).fail(function(err) { 
        console.error("ERROR loading league Composite", err);
      });

  },
  handlePointJournalAddEdit: function() { 
    console.log("handlePointJournalAddEdit");
    var self = this;
    self.requireGroup("ADMIN", function() {    
        self.changePageView(new fantasy.packs.Admin.PagePointJournalAddEdit());
      });

  },
  handleAdminSeason: function() { 
    var self = this;
    self.requireGroup("ADMIN", function() { 
        self.changePageView(new fantasy.packs.Admin.PageSeasonAddEdit());
      });
  }
});
