
var fantasy = fantasy || {};
fantasy.packs = fantasy.packs || {};
fantasy.packs.Admin = fantasy.packs.Admin || {};

fantasy.packs.Admin.SeasonEditAddFormView = fantasy.present.Classes.AssignableFormView.extend({ 
	className: "form-view edit-add-season-form-view",
	template: fantasy.templates.admin_season_form_edit_add,
	tagName: "div",
	events: { 
		 "change input" : "snapValues",
		 "change #awarded_dt" : "updateLocalTimeDisplay"

	},
	initialize: function(options)
	{
		var self = this;

		options = options || {};
		if(!options || !options.model)
		{
			options.model = new fantasy.packs.CompBeans.Season({sport_id: 1});
		}

		options.customValidator = this.validator;
		fantasy.present.Classes.AssignableFormView.prototype.initialize.apply(this,[options]);
		var allSportsCollection = new fantasy.packs.CoreCollections.SportsAll();
		this.reenable_submit_button_on_success = true;


		this.listenTo(this,"successful_submission", function() { 
			self.model.id = null;
			self.model.unset("season_id");
			//self.model = new fantasy.packs.CompBeans.PointJournal(self.model.attributes);
			self.model = new fantasy.packs.CompBeans.Season({sport_id: 1});

		});

		this.sportSelector = new fantasy.packs.UXWidgets.SelectInput( {
			option_model_name_fieldname: "name",
			option_model_value_fieldname: "sport_id",
			data_model_fieldname: "sport_id",
			data_model: this.model,
			options_collection: allSportsCollection
		 });

		allSportsCollection.fetch();
		this.addView(this.sportSelector,{selector:".fd-dz-sport-selector"});


	},
	updateLocalTimeDisplay: function() { 
		var timeStr = this.$el.find("#start_date").val();
		this.model.set("start_date",timeStr);
		this.model.makeLocalDateTimeField("start_date");
		this.$el.find(".start_date_local").text(this.model.get("start_date_local"));

		timeStr = this.$el.find("#draft_date").val();
		this.model.set("draft_date",timeStr);
		this.model.makeLocalDateTimeField("draft_date");
		this.$el.find(".draft_date_local").text(this.model.get("draft_date_local"));

		timeStr = this.$el.find("#end_date").val();
		this.model.set("end_date",timeStr);
		this.model.makeLocalDateTimeField("end_date");
		this.$el.find(".end_date_local").text(this.model.get("end_date_local"));

	},

	postRender: function() { 
		this.$el.find("#start_date").flatpickr({
			// utc: true,
			defaultDate: this.model.get("start_date"),
			enableTime: true,
		});
		this.$el.find("#draft_date").flatpickr({
			// utc: true,
			defaultDate: this.model.get("draft_date"),
			enableTime: true,
		});
		this.$el.find("#end_date").flatpickr({
			// utc: true,
			defaultDate: this.model.get("end_date"),
			enableTime: true,
		});		
	},
	// updateRanges: function() { 
	// 	var pointsVal = this.$el.find("#points").val();
	// 	this.$el.find("div[for=points]").text(pointsVal);
	// },
	validator: function(candidate_model) { 
		candidate_model.set("sport_id", parseInt(candidate_model.get("sport_id")));
	},
	massageModel: function() { 
		this.model.set("sport_id", parseInt(this.model.get("sport_id")));

	}
});

fantasy.packs.Admin.PageSeasonAddEdit = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-admin-season",
	bodyClasses: "fdbc-admin",
	template: fantasy.templates.admin_page_season_add_edit,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.setContent({});
		this.view = new fantasy.packs.Admin.SeasonEditAddFormView();
		this.addView(this.view,{selector:".fd-dz-form-area"});
		this.render();
	}
});