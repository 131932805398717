fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.AthleteInMyStartingLineupItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-athlete-starter fd-athlete-in-my-starting-lineup-view",
	template: fantasy.templates.sub_athlete_in_my_starting_lineup_view,
events: { 
		"click .fd-action-remove-from-lineup": "handleRemoveFromLineup"
	},
	handleRemoveFromLineup: function(e) { 
		console.log("handleRemoveFromLineup click");
		this.$el.find(".fd-action-remove-from-lineup").html("...").attr("disabled",true);
		this.trigger("remove_from_lineup",this.model);
	}

});

fantasy.packs.Comps.AthletesInMyStartingLineupListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-athlete-starter-list  fd-athlete-in-my-starting-lineup-list",
	subViewClass: fantasy.packs.Comps.AthleteInMyStartingLineupItemView,
	massageCreatedSubView: function(subview) { 
		this.listenTo(subview,"remove_from_lineup",this.handleRemoveFromLineup);
		return subview;
	},
	handleRemoveFromLineup: function(model) { 
		this.trigger("remove_from_lineup",model);
	}
});

fantasy.packs.Comps.AthleteInMyStartingLineupForTabView =  fantasy.packs.Comps.AthleteInMyStartingLineupItemView.extend({ 
	className: "fd-list-item fd-athlete-starter fd-athlete-in-my-starting-lineup-view",
	template: fantasy.templates.sub_athlete_in_my_starting_lineup_view_for_tab,
});

fantasy.packs.Comps.AthletesInMyStartingLineupForTabView =  fantasy.packs.Comps.AthletesInMyStartingLineupListView.extend({ 
	className: "fd-list fd-athlete-starter-list  fd-athlete-in-my-starting-lineup-list",
	subViewClass: fantasy.packs.Comps.AthleteInMyStartingLineupForTabView,
	postRender: function() { 
		console.log("AthletesInMyStartingLineupForTabView postRender");
		
		this.setFixedWidthByCalculatingChildrenWidth(0);
	},
	handleParentAddedToDom: function() { 
		console.log("AthletesInMyStartingLineupForTabView handleParentAddedToDom");
		this.setFixedWidthByCalculatingChildrenWidth(0);

	}
});


fantasy.packs.Comps.AthleteInTheirStartingLineupItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-athlete-starter fd-athlete-in-my-starting-lineup-view",
	template: fantasy.templates.sub_athlete_in_their_starting_lineup_view
});

fantasy.packs.Comps.AthletesInTheirStartingLineupListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-athlete-starter-list fd-athlete-in-my-starting-lineup-list",
	subViewClass: fantasy.packs.Comps.AthleteInTheirStartingLineupItemView,
	postRender: function() { 
		this.setFixedWidthByCalculatingChildrenWidth(0);
	},
	handleParentAddedToDom: function() { 
		this.setFixedWidthByCalculatingChildrenWidth(0);
	}
});


fantasy.packs.Comps.AthleteItemInYourFTeamPickableItem =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-athlete-item athlete",
	events: { 
		"click .fd-action-add-to-lineup": "handleAddToLineup"
	},
	template: fantasy.templates.sub_athlete_item_in_your_f_team_pickable,
	handleAddToLineup: function(e) { 
		console.log("handleAddToLineup click");
		this.$el.find(".fd-action-add-to-lineup").html("...").attr("disabled",true);

		this.trigger("add_to_lineup",this.model);
	}
});

fantasy.packs.Comps.AthletesInYourFTeamViewPickable =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list  fd-athletes-in-fantasy-team fd-athlete-list fd-athletes-in-your-team-pickable",
	subViewClass: fantasy.packs.Comps.AthleteItemInYourFTeamPickableItem,
	massageCreatedSubView: function(subview) { 
		this.listenTo(subview,"add_to_lineup",this.handleAddToLineup);
		return subview;
	},
	handleAddToLineup: function(model) { 
		console.log("handleAddToLineup listview perc up");
		this.trigger("add_to_lineup",model);
	},
		postRender: function() { 
		console.log("AthletesInYourFTeamViewPickable postRender");
		
		this.setFixedWidthByCalculatingChildrenWidth(0);
	},
	handleParentAddedToDom: function() { 
		console.log("AthletesInYourFTeamViewPickable handleParentAddedToDom");
		this.setFixedWidthByCalculatingChildrenWidth(0);

	}
});


