/**



<div class="fd-dz-intro"></div>
<div class="fd-dz-league-info"></div>
<div class="fd-dz-round-info"></div>
<div class="fd-dz-game-info"></div>

<div class="row">
	<div class="col-md-4">
		<div class="fd-dz-my-roster"></div>
	</div>
	<div class="col-md-4">
		<div class="fd-dz-my-starters"></div>
	</div>
	<div class="col-md-4">
		<div class="fd-dz-their-starters"></div>
	</div>

</div>
**/

fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageMyGameView = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-my-game",
	template: fantasy.templates.page_my_game,
	events: { 
	},
	initialize: function(options) {
		console.log("PageMyGameView INIT START",options);
		var self = this;
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.gameComposite = options.gameComposite;
		window.gameComposite = this.gameComposite;
		
		fantasy.socket.socketManager.listenToLeagueRoom(this.gameComposite.getModel("f_game_core").get("league_id"));

		// this.listenTo(fantasy.socket.socketManager,"draft_pick_made", _.bind(this.handleDraftPickMadeBySomeone,this));
		// this.listenTo(fantasy.socket.socketManager,"draft_cancel", _.bind(this.handleDraftCancelServerPing,this));

		this.setContent(this.gameComposite);


		this.yourLineupView = new fantasy.packs.Comps.AthletesInMyStartingLineupListView({collection: this.gameComposite.getCollection("your_team_lineup")});
		this.theirLineupView = new fantasy.packs.Comps.AthletesInTheirStartingLineupListView({collection: this.gameComposite.getCollection("their_team_lineup")});
		this.yourRosterView = new fantasy.packs.Comps.AthletesInYourFTeamViewPickable({collection: this.gameComposite.getYourLeftOverRoster()});

		this.listenTo(this.yourRosterView,"add_to_lineup",this.handleAddToLineup);
		this.listenTo(this.yourLineupView,"remove_from_lineup",this.handleRemoveFromLineup);

		this.roundView = new fantasy.packs.Comps.RoundMastView({model: this.gameComposite.getModel("round_core")});
		this.leagueView = new fantasy.packs.Comps.LeagueCoreMastView({model: this.gameComposite.getModel("league_core")});

		self.addView(self.roundView, {selector: ".fd-dz-round-info"});
		self.addView(self.leagueView, {selector: ".fd-dz-league-info"});

		self.addView(self.yourLineupView, {selector: ".fd-dz-my-starters"});
		self.addView(self.theirLineupView, {selector: ".fd-dz-their-starters"});
		self.addView(self.yourRosterView, {selector: ".fd-dz-my-roster"});
		self.sportCore = this.gameComposite.getModel("sport_core");
		console.log("self.sportCore: ", self.sportCore);
		self.f_team_starters = self.sportCore.get("f_team_starters");
		self.render();

	},
	handleAddToLineup: function(athlete_core_model) { 
		var self = this;
		self.max_lineup_size = self.sportCore.get("f_team_starters");

		if(self.gameComposite.getCollection("your_team_lineup").length >= self.max_lineup_size-1)
		{
			self.$el.addClass("lineup-full");
			self.$el.removeClass("lineup-not-full");

		}
		else
		{
			self.$el.removeClass("lineup-full");
			self.$el.addClass("lineup-not-full");

		}
		if(self.gameComposite.getCollection("your_team_lineup").length >= self.max_lineup_size)
		{
			console.error("Attempt to add more players to roster than allowed!");
			return;
		}

		console.log("Still have room to add more folks: self.max_lineup_size, lineup length ",self.max_lineup_size,self.gameComposite.getCollection("your_team_lineup").length );
		var teamStarter = new fantasy.packs.CompBeans.TeamStarterRoundRel({
			name: "Starter",
			f_team_id: self.gameComposite.your_f_team_id,
			athlete_id: athlete_core_model.getId(),
			position_id: athlete_core_model.get("primary_position_id"),
			round_id: self.gameComposite.getModel("f_game_core").get("round_id"),
			f_game_id: self.gameComposite.getId(),
		});
		teamStarter.save().done(function() { 
			self.refreshLists();
			self.checkForFullness();
		});

	},
	checkForRoundLocked: function() { 
		var self = this;
		var startInFuture = this.gameComposite.getModel("round_core").isStartInFuture();
		if(startInFuture)
		{
			self.$el.removeClass("round_started");
			self.$el.addClass("round_starts_in_future");
		}
		else 
		{
			self.$el.removeClass("round_starts_in_future");
			self.$el.addClass("round_started");
		}
	
	},
	checkForFullness: function() { 
		var self = this;

		self.max_lineup_size = self.sportCore.get("f_team_starters");
		console.log("lineup size, max_lineup_size",self.gameComposite.getCollection("your_team_lineup").length, self.max_lineup_size);
		if(self.gameComposite.getCollection("your_team_lineup").length >= self.max_lineup_size)
		{
			self.$el.addClass("lineup-full");
			self.$el.removeClass("lineup-not-full");

		}
		else
		{
			self.$el.removeClass("lineup-full");
			self.$el.addClass("lineup-not-full");
		}
	},
	refreshLists: function() { 
		var self = this;
		return self.gameComposite.getCollection("your_team_lineup").fetch().done(function() { 
			self.gameComposite.calculateAvailableRoster();
			self.checkForFullness();
			self.checkForRoundLocked();
		});
	},
	handleRemoveFromLineup: function(teamStarterRoundRelComposite) {
		var self = this;
		teamStarterRoundRelComposite.destroy().done(function() { 
			self.refreshLists();
		});

	},
	handleConfirmedDraftPick: function(obj) { 
		var self = this;
		self.just_picked = true;
		obj.view.$el.find("button").attr("disabled", true).html("Picking...");
		var athlete = obj.model;
		var draftPick = new fantasy.packs.CompBeans.DraftPickerModel({
			athlete_id: athlete.getId(),league_id: this.leagueComposite.getId(),
			created_by_user_id: fantasy.userState.getModel("user_composite").getId(),
			f_team_id: this.ownFantasyTeamComposite.getModel("f_team_core").getId() });
		draftPick.save().done(function() { 
			obj.view.remove();
			console.log("DRAFT PICKED!");
			//TODO ADD LOADING INDICATOR on team refresh
			//self.ownFantasyTeamComposite.refetch();
			self.allRostersCollection.reFetchEach();
			self.athletesLeft.fetch();
			self.leagueComposite.getModel("league_core").fetch().done(function() {   //getModel("league_core").fetch()
				//self.leagueComposite.trigger("sync");
				//self.render();
				console.log("DRAFT PICKED JUST RE-RENDERED PageDrafting Page");
				self.just_picked = false;

			});
		}).fail(function(err) { 
			console.error("Error with draft pick!", err);
			obj.view.$el.find("button").attr("disabled", true).html("Error...");

		});
	},
	handleDraftPick: function(obj) { 
		var self = this;
		this.$el.find(".fd-action-draft-pick").hide();
		fantasy.router.modalManager.areYouSure("are you sure you want to pick " + obj.model.get("first_name"), _.bind(this.handleConfirmedDraftPick,this,obj),
			function() { 
				self.$el.find(".fd-action-draft-pick").show();

			});

	},
	postRender: function() { 
		this.checkForFullness();
		this.checkForRoundLocked();
	},
	remove: function() { 
		fantasy.present.Classes.AssignableContainerView.prototype.remove.apply(this,arguments);
	}
});