$(function() {

	// isBot = true;

  fantasy.timeCheck = new fantasy.packs.CompBeans.TimeCheck();

  function afterTimeCheck(){ 
  	fantasy.userState = new fantasy.packs.State.UserState();

	  window.g_state = function() {
	    return fantasy.userState.attributes;
	  };

		fantasy.navView = new fantasy.packs.Nav.NavView();

		fantasy.navView.navState.addModel("userstate",fantasy.userState );
		if(!isBot){
		    fantasy.userState.askServerForAuthState();
		}
		else
		{
			fantasy.userState.assumeBot();
		}
		fantasy.userState.loadSiteState();


		fantasy.router = new fantasy.utilroute.FantasyRouter({hrefInfoBean: fantasy.navView.navState, userState: fantasy.userState});

		Backbone.history.start({ pushState: true , hashChange: false, root:  "/" });

		$("#df-top-bar").append(fantasy.navView.render().$el);


		fantasy.bottomBar = new fantasy.packs.Nav.BottomBar({model: fantasy.userState});
		$("#df-bottom-bar").append(fantasy.bottomBar.render().$el);

		fantasy.packs.Tracking.trackTimingSinceBodScript("FD Loading Timings","nav_appended","Navigation Appended");

		//_bodScriptRunDateMS
  }

if(isBot) {
	afterTimeCheck();
} else { 
  fantasy.timeCheck.fetch().done(function() { 
	afterTimeCheck();
  });
}

});
