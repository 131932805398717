
var fantasy = fantasy || {};
fantasy.packs = fantasy.packs || {};
fantasy.packs.Admin = fantasy.packs.Admin || {};

fantasy.packs.Admin.EditAddPointJournalFormView = fantasy.present.Classes.AssignableFormView.extend({ 
	className: "form-view edit-add-point-journal-form-view",
	template: fantasy.templates.admin_point_journal_form_edit_add,
	tagName: "div",
	events: { 
		 "change input" : "snapValues",
		 "change #awarded_dt" : "updateLocalTimeDisplay"

	},
	initialize: function(options)
	{
		var self = this;

		options = options || {};
		if(!options || !options.model)
		{
			options.model = new fantasy.packs.CompBeans.PointJournal({sport_id: 1});
		}

		options.customValidator = this.validator;
		fantasy.present.Classes.AssignableFormView.prototype.initialize.apply(this,[options]);
		var allSportsCollection = new fantasy.packs.CoreCollections.SportsAll();
		this.reenable_submit_button_on_success = true;


		this.listenTo(this,"successful_submission", function() { 
			self.model.id = null;
			self.model.unset("point_journal_id");
			//self.model = new fantasy.packs.CompBeans.PointJournal(self.model.attributes);
			self.model = new fantasy.packs.CompBeans.PointJournal({sport_id: 1});

		});

		this.sportSelector = new fantasy.packs.UXWidgets.SelectInput( {
			option_model_name_fieldname: "name",
			option_model_value_fieldname: "sport_id",
			data_model_fieldname: "sport_id",
			data_model: this.model,
			options_collection: allSportsCollection
		 });

		allSportsCollection.fetch();
		this.addView(this.sportSelector,{selector:".fd-dz-sport-selector"});

		var allAthletesCollection = new fantasy.packs.CoreCollections.AthletesAll();

	   	var allAthletesLoadPromise = fantasy.cstore.cstore.getOrFetchItem(allAthletesCollection,3600);
	      	allAthletesLoadPromise.done(function(arg) { 
	      		console.log("GOT ALL ATHLETES BACK FROM getOrFetchItem", arg);
	      	allAthletesCollection = arg;

			self.athleteSelector = new fantasy.packs.UXWidgets.SelectInput( {
				option_model_name_fieldname: "full_name",
				option_model_value_fieldname: "athlete_id",
				data_model_fieldname: "athlete_id",
				data_model: self.model,
				options_collection: allAthletesCollection
			 });
			self.athleteSelector.render();
			// allAthletesCollection.fetch();
			self.addView(self.athleteSelector,{selector:".fd-dz-athlete-selector"});
		}).fail(function(err) { 
			console.err("trouble loading all athletes",err,self);
		});

		var allPointCategoryCollection = new fantasy.packs.CoreCollections.PointCategoryAll();

	   	var allPointCategoryLoadPromise = fantasy.cstore.cstore.getOrFetchItem(allPointCategoryCollection,3600);
	      	allPointCategoryLoadPromise.done(function(arg) { 
	      	allPointCategoryCollection = arg;

			self.categorySelector = new fantasy.packs.UXWidgets.SelectInput( {
				option_model_name_fieldname: "name",
				option_model_value_fieldname: "point_category_id",
				data_model_fieldname: "point_category_id",
				data_model: self.model,
				options_collection: allPointCategoryCollection
			 });
			self.categorySelector.render();
			self.addView(self.categorySelector,{selector:".fd-dz-point_category-selector"});
		}).fail(function(err) { 
			console.err("trouble loading all point categories",err,self);
		});


	},
	updateLocalTimeDisplay: function() { 
		var timeStr = this.$el.find("#awarded_dt").val();
		this.model.set("awarded_dt",timeStr);
		this.model.makeLocalDateTimeField("awarded_dt");
		this.$el.find(".awarded_dt_local").text(this.model.get("awarded_dt_local"));

	},
	postRender: function() { 
		this.$el.find("#awarded_dt").flatpickr({
			// utc: true,
			defaultDate: this.model.get("awarded_dt"),
			enableTime: true,
		});

	},
	// updateRanges: function() { 
	// 	var pointsVal = this.$el.find("#points").val();
	// 	this.$el.find("div[for=points]").text(pointsVal);
	// },
	validator: function(candidate_model) { 

	}
});

fantasy.packs.Admin.PagePointJournalAddEdit = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-admin-point-journal",
	bodyClasses: "fdbc-admin",
	template: fantasy.templates.admin_page_point_journal_add_edit,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.setContent({});
		this.view = new fantasy.packs.Admin.EditAddPointJournalFormView();
		this.addView(this.view,{selector:".fd-dz-form-area"});
		this.render();
	}
});