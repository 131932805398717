fantasy.packs = fantasy.packs || {};
fantasy.packs.State = fantasy.packs.State || {};


/**
For stuff not related to the user that we want to load early.
**/
fantasy.packs.State.SiteState = fantasy.Supers.AbstractState.extend({
initialize: function(attributes, options)
	{
		var self = this;
		 fantasy.Supers.AbstractState.prototype.initialize.apply(this,arguments);
		 this.reinit();
	},
	reinit: function() { 
		this.clear();
		this.resetDefaultState();
		 var currentSeasons = new fantasy.packs.CoreCollections.CurrentSeasons();
		 this.addCollection("current_seasons", currentSeasons);
		 var draftInFutureSeasons = new fantasy.packs.CoreCollections.DraftInFutureSeasons();
		 this.addCollection("draft_in_future_seasons", draftInFutureSeasons);
		 this.set("sport_id",1);


		// NOT SETTING season_id for this YET:
		// It depends on loading a concept of current_season.
		this.currentRoundsForSeasonCollection = new fantasy.packs.CoreCollections.CurrentRoundsForSport([],{sport_id: this.get("sport_id")});
		this.addCollection("current_round_collection", this.currentRoundsForSeasonCollection );

	},
	resetDefaultState: function() { 
		 this.addDeferred("current_seasons_loaded");
		 this.addDeferred("current_round_identified");
	},	
	fetch: function() { 
		var self = this;
		console.log("fetchSiteState");

		var promA = self.getCollection("current_seasons").fetch();
		var promB = self.getCollection("draft_in_future_seasons").fetch();

		console.log("site state  about to call WHEN for promA and promB");
			
		return $.when(promA,promB).done(function() { 
			console.log("site state fetch promA and promB done.");

			var curSeasons = self.getCollection("current_seasons");
			if(!curSeasons || curSeasons.length === 0)
			{
				self.getDeferred("current_round_identified").resolve();
		 		console.log("Note, no current_round found");
				self.trigger("sync");
				console.log("RESOLVING current_seasons_loaded WITHOUT setting a current Season.");
				self.getDeferred("current_seasons_loaded").resolve();		 		
			}
			else
			{
				
				var currentSeason = curSeasons.models[0];
				self.addModel("current_season", currentSeason);
				self.trigger("sync");
				console.log("Just set current_season on site_state", currentSeason, self);
				self.getDeferred("current_seasons_loaded").resolve();
				self._loadRoundsForCurrentSeason(currentSeason.getId());		
			}

		}).fail(function(err) { 
			self.getDeferred("current_seasons_loaded").reject(err);
			self.getDeferred("current_round_identified").reject(err);
		});

	},
	_loadRoundsForCurrentSeason: function(season_id) { 
		var self = this;
		self.currentRoundsForSeasonCollection.season_id = season_id;

 		self.currentRoundsForSeasonCollection.fetch().done(function() { 
		 	var models = self.currentRoundsForSeasonCollection.models;
		 	if(models && models.length > 0)
		 	{
		 		self.addModel("current_round",models[0]);
				self.getDeferred("current_round_identified").resolve();
		 	}
		 	else
		 	{
				self.getDeferred("current_round_identified").resolve();
		 		console.log("Note, no current_round found");
		 	}
			self.trigger("sync");

		 }).fail(function(err) { 
		 	console.error("trouble loading current round", arguments);
			self.getDeferred("current_round_identified").reject(err);
		 });

	},	changeSeason: function(season_id) { 

		var self = this;
		self.ifNotPendingReset("current_round_identified");
		self.ifNotPendingReset("current_seasons_loaded");

		season_id = parseInt(season_id);
		var seasons = self.getCollection("current_seasons");
		var currentSeason = null;
		_.each(seasons.models, function(season) { 
			if(season.getId() === season_id){
				currentSeason = season;
			}
		});
		if(currentSeason)
		{
			self.addModel("current_season", currentSeason);
			self.getDeferred("current_seasons_loaded").resolve();
			self._loadRoundsForSeason(currentSeason.getId());
		}

		return self.getPromise(("current_round_identified"));
	},getCurrentSeason: function() {
	    return this.getModel("current_season");
	},
	getCurrentSeasonId: function() { 
		var season = this.getCurrentSeason();
		if(season)
			return season.getId();
		return null;
	},
	getCurrentSportId: function(){ 
		return this.get("sport_id");
	},
	getCurrentRound: function() { 
		return this.getModel("current_round");
	},
	getCurrentRoundId: function() { 
		var round = this.getCurrentRound();
		if(round)
			return round.getId();
		return null;
	}
});


fantasy.packs.State.UserState = fantasy.Supers.AbstractState.extend({
	initialize: function(attributes, options)
	{
		var self = this;
		 fantasy.Supers.AbstractState.prototype.initialize.apply(this,arguments);

		 var loginModel = new fantasy.packs.Auth.LoggedInUserInfoModel();
		 this.addModel("login_check_model", loginModel);
		 // var currentSeasons = new fantasy.packs.CoreCollections.CurrentSeasons();
		 // this.addCollection("current_seasons", currentSeasons);
		 this.set("sport_id",1);
		 this.set("tk",e7());
		 this.friendsOnlineManager = new fantasy.packs.State.FriendsOnlineManager();
		 this.addModel("friends_online", this.friendsOnlineManager);
		 this.addModel("site_state", new fantasy.packs.State.SiteState());
	},
	reinit: function() { 

		this.clear();
		this.resetDefaultState();

		 var loginModel = new fantasy.packs.Auth.LoggedInUserInfoModel();
		 this.addModel("login_check_model", loginModel);
		 // var currentSeasons = new fantasy.packs.CoreCollections.CurrentSeasons();
		 // this.addCollection("current_seasons", currentSeasons);
		 this.set("sport_id",1);
		 this.set("tk",e7());
		 this.friendsOnlineManager.stopAllAndClear();

	},
	loadSiteState: function() { 
		var self = this;

		return this.getModel("site_state").fetch();
	},
	getSiteState: function() { 
		return this.getModel("site_state");
	},
	getTK: function() { 
		return this.get("tk");
	},
	setNotLoggedIn: function() { 
		this.getDeferred("user_is_authenticated").reject();
	},
	loadLeagueTeasers: function() { 
		var self = this;
		self.ifNotPendingReset("league_teasers_for_user_and_season_loaded");
		console.log("loadLeagueTeasers entry. state", self.getModel("site_state").getDeferred("current_seasons_loaded").state());
		//wait for season info to be loaded.
		self.getModel("site_state").getPromise("current_seasons_loaded").done(function() { 
		console.log("loadLeagueTeasers post promise. state. ", self.getModel("site_state").getDeferred("current_seasons_loaded").state());

			var siteState = self.getSiteState();
			var seasonId = siteState.getCurrentSeasonId();
			if(seasonId === null)
			{
				console.error("call to loadLeagueTeasers but seasonId not IDed.",siteState);
				return;
			}

			//only do this if the user is authed.
			self.getPromise("user_is_authenticated").done(function() { 
				 self.addCollection("league_teasers_for_user_and_season", new fantasy.packs.CompBeans.LeagueTeasersForUserSeason([],{
				 	user_id: self.getLoggedInUserId(), 
				 	season_id: seasonId }
				 	));
				  
				  return self.getCollection("league_teasers_for_user_and_season").fetch().done(function() { 
						self.getDeferred("league_teasers_for_user_and_season_loaded").resolve();
						console.log("Loaded League Teasers for User.");
				  }).fail(function(err) { 
				  	console.error("Trouble loading league teasers", arguments);
						self.getDeferred("league_teasers_for_user_and_season_loaded").reject();

				  });
			});
		});



	},
	/** Only loads login check and user_core **/
	reloadCurrentUserInfo: function() { 
		var self = this;
		var promiseA = self.getLoggedInUser().fetch();
		var promiseB = self.getModel("login_check_model").fetch();
		var compositePromise = $.when(promiseA,promiseB);
		compositePromise.done(function() { console.log("reloaded current user info.");


	}).fail(function() { 
			console.error("trouble reloading current user info", arguments);
		});
		return compositePromise;

	},
	/**
		are we logged in according to our in state memory, without having to hit a server?
		trust a true, but false will be returned even if we haven't checked yet.
	**/
	passiveIsLoggedIn: function() { 
		var authstate =		this.getDeferred("user_is_authenticated").state();
		if(authstate === "resolved")
		{
			return true;
		}
		return false;
	},
	assumeBot: function() { 
		var self = this;
		self.ifNotPendingReset("user_is_authenticated");
		self.ifNotPendingReset("user_loaded");
		self.getDeferred("user_is_authenticated").reject();	
		self.getDeferred("user_loaded").reject();
		// self.getDeferred("current_seasons_loaded").reject();
		// self.getDeferred("current_round_identified").reject();
	},
	/**
	Hits the server
	**/
	askServerForAuthState: function() { 
		var def = new $.Deferred();
		

		var self = this;
		self.set("checking_auth_state", true);
		self.trigger("sync");

		self.ifNotPendingReset("user_is_authenticated");

		var loginCheckModel =  this.getModel("login_check_model");
		loginCheckModel.clear({silent:true});
		loginCheckModel.fetch().done(function() { 
			console.log("loginCheckModel returned");
			if(loginCheckModel.isLoggedIn())
			{
				console.log("loginCheckModel isLoggedIn is true");
				self.changeUser(loginCheckModel.get("user_id")).done(function() { 
					self.getDeferred("user_is_authenticated").resolve();
					def.resolve();
					self.set("checking_auth_state", false);
					self.trigger("sync");
				 });			
			}
			else
			{
				console.log("NOT LOGGED IN.");
				self.getDeferred("user_is_authenticated").reject();	
				def.reject();
				self.set("checking_auth_state", false);
				self.trigger("sync");

				// self.getDeferred("user_loaded").reject();	
			}

		}).fail(function(err) { 
			console.error("Trouble checking if the user is logged in.",err);
				self.fatal_error = true;
				self.getDeferred("user_is_authenticated").reject();	
				def.reject();
				self.set("checking_auth_state", false);
				self.trigger("sync");

			console.error("Trouble checking login status for user.", err);
		});
		return def.promise();
	},
	getLoggedInUser: function() { 
		return this.getModel("user_composite").getModel("user_core");
	},
	isInGroup: function(group_code) { 
		var loginCheckModel = this.getModel("login_check_model");
		if(!loginCheckModel)
			return false;
		if(loginCheckModel.get("is_" + group_code)){
			return true;
		}
		return false;
	},
	/**  
	CALLED BY SUPER CLASS in initialize
	**/
	resetDefaultState: function() { 
		 this.addDeferred("user_is_authenticated");
		 this.addDeferred("user_loaded");
		 this.addDeferred("league_teasers_for_user_and_season_loaded");
	},
	getLoggedInUserId: function() { 
		return this.getModel("user_composite").getId();
	},
	getUserComposite: function() { 
		return this.userComposite;
	},
	changeUser: function(user_id) { 
		console.log("userState changing user to user id", user_id);
		var self = this;

		self.set("loading_user", true);
		self.trigger("sync");

		self.ifNotPendingReset("user_loaded");
		this.set("in_a_league",false);
	   this.userComposite = new fantasy.packs.CompBeans.UserComposite({user_id:user_id});
	   this.addModel("user_composite", this.userComposite);
	   this.userComposite.fetch().done(function() { 

	   		try {
				ga('set', 'userId', "" + self.userComposite.getModel("user_core").getId()); // Set the user ID using signed-in user_id.
			}
			catch(err)
			{
				console.error("Trouble setting GA user", err);
			}

			self.loadLeagueTeasers();

	   		if(self.userComposite.getCollection("leagues") && self.userComposite.getCollection("leagues").length > 0)
	   			self.set("in_a_league",true);

			self.getDeferred("user_loaded").resolve();
			self.set("loading_user", false);

			self.trigger("sync");


		 }).fail(function(err) { 
	 		console.error("we tried loading a user and.... something went wrong.", err);
 			self.getDeferred("user_loaded").reject();
			self.set("loading_user", false);
			self.trigger("sync"); 			
		 });

		 this.trigger("user_changed");
 		return this.getPromise("user_loaded");
	},


});


