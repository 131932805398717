fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageContact = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-contact",
	bodyClasses: "fdbc-contact",
	template: fantasy.templates.page_contact,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.setContent({});
	


		this.render();
	}
});