fantasy.beans = fantasy.beans || {};
fantasy.beans.Classes = fantasy.beans.Classes || {};
fantasy.packs = fantasy.packs || {};
fantasy.packs.Models = fantasy.packs.Models || {};
fantasy.Supers = fantasy.Supers || {};
fantasy.present = fantasy.present  || {};
fantasy.present.Classes = fantasy.present.Classes || {};

fantasy.Collections = fantasy.Collections || {};
fantasy.Collections.Classes = fantasy.Collections.Classes || {};


fantasy.beans.Classes.Base = Backbone.Model.extend({
	  idAttribute: "id",

 initialize: function(attributes, options) {
  		var self = this;

       var my_options =  options || {};        
  		Backbone.Model.prototype.initialize.apply(this,arguments);
        this.listenTo(this,"error", this.defaultSyncErrorHandler);
  		this.listenTo(this,"sync", this.normalize);
  		this.use_real_api = false;
  		this.metaAttributes = {};

  		if(this.idAttribute)
  		{
  			if(attributes && attributes[this.idAttribute]) 
  				this.setId(attributes[this.idAttribute]);
  			else if(attributes && attributes.id)
  				this.setId(attributes.id);
  		}
  		this.loaded = false;

  		if(this.normalize)
  		{
  			try{ 
  				this.normalize();
  			}
  			catch(error)
  			{
  				console.error("ERROR NORMALIZING MODEL in INIT",error, this);
  			}
  		}
  		this.listenTo(this,"sync", function() { 
  			self.loaded = true;
  		});
    },
    /**
	override me.
    validate the data, especially public facing form data. used by formview.
	@return null if no validation problems, otherwise an object per spec
    **/
    validate: function() { 
    	/**
		{ 
			general_error: "<b>I am html error msg</b>",
			field_notes: [{ field_name: "first_name", error: "must be more than 2 characters"}]			
		}
    	**/
    	return null;
    },
    normalize: function() { 

    },
    setMeta: function(name,val) {
    	this.metaAttributes[name] = val;
    	return this;
    },
    getMeta: function(name) { 
    	return this.metaAttributes[name];
    },
    setAsLoaded: function() { 
    	this.loaded = true;
    },
    makeLocalDateTimeField: function(field_name) { 
    	if(this.get(field_name))
    	{
    		var utcstr = this.get(field_name);
    		if(utcstr && utcstr.length > 0)
    		{
    			var mom = moment.utc(utcstr);
    			var local = mom.clone().local();
    			this.set(field_name +"_local",local.format());
    		}
    	}
    },
    dateFormatField: function(field_name, new_field_name, format_str) { 
    	var str = this.get(field_name);
    	if(!str)
    		throw "DATE NOT FOUND " + field_name;
    	var mom = moment.utc(str);
    	var frmt = mom.format(format_str);
    	this.set(new_field_name,frmt);
    	return this;
    },
    dateFormatFieldLocal: function(field_name, new_field_name, format_str) { 
    	var str = this.get(field_name);
    	if(!str)
    		throw "DATE NOT FOUND " + field_name;
    	var mom = moment.utc(str);
    	var local_mom = mom.clone().local();
    	var frmt = local_mom.format(format_str);
    	this.set(new_field_name,frmt);
    	return this;
    },    
    dateAgoFormatField: function(field_name, new_field_name) { 
    	var str = this.get(field_name);
    	if(!str)
    		throw "DATE NOT FOUND " + field_name;
    	var mom = moment.utc(str);
    	var frmt = mom.from(moment.utc());
    	console.log("dateAgoFormatField", { 
    		field_name: field_name,
    		new_field_name: new_field_name,
    		og_str_value: str,
    		mom_formatted: mom.format(),
    		now_moment_utc_format: moment.utc().format(),
    		from: frmt
    	});
    	this.set(new_field_name,frmt);
    	return this;
    },
    addInBetweenNowAndDate: function(field_name_end,name) { 
	   	var endStr = this.get(field_name_end);
	 	var startmom = fantasy.timeCheck.getNowServer(); //moment.utc();
	 	var endmom = moment.utc(endStr);
	 	this.set(name,endmom.isAfter(startmom));
    },
    addInBetweenFlags: function(field_name_start,field_name_end,name) {
    name = name || "is_current";
     
	   	var startStr = this.get(field_name_start);
	   	var endStr = this.get(field_name_end);
	 	var nowmom = fantasy.timeCheck.getNowServer(); //moment.utc();
	 	var startmom = moment.utc(startStr);
	 	var endmom = moment.utc(endStr);
	 	this.set(name,nowmom.isBetween(startmom,endmom));

    },
    addInFuturePastFlags: function(field_name) {
	   	var str = this.get(field_name);
	    	if(!str)
	    		throw "DATE NOT FOUND " + field_name;
	    var mom = moment.utc(str);
	 	var nowmom = fantasy.timeCheck.getNowServer(); //moment.utc();
	 	if(mom.isAfter(nowmom))
	 	{
	 		this.set(field_name + "_in_the_past",false);
	 		this.set(field_name + "_in_the_future",true);
	 	}
	 	else
	 	{
	 		this.set(field_name + "_in_the_past",true);
	 		this.set(field_name + "_in_the_future",false);
	 	}
	 	if(mom.isSame(nowmom,"day"))
	 	{
	 		this.set(field_name + "_is_today",true);
	 	}
	 	else
	 	{
	 		this.set(field_name + "_is_today",false);
	 	}

    },
    conditionalDateFormatOrAgo: function(field_name, new_field_name, format_str, ago_threshold_num,ago_threshold_unit) { 
    	var str = this.get(field_name);
		if(!str)
	    		throw "DATE NOT FOUND " + field_name;
	    var mom = moment.utc(str);
	    var machineUTCMom = fantasy.timeCheck.getNowServer(); //moment.utc();
	    var machinePITMom = machineUTCMom.subtract(ago_threshold_num,ago_threshold_unit);
	    if(mom.isBefore(machinePITMom))
	    	return this.dateFormatField(field_name,new_field_name,format_str);
	    else
	    	return this.dateAgoFormatField(field_name,new_field_name);
	}   ,   
    defaultSyncErrorHandler: function(model, error) {
        if (error.status == 401 || error.status == 403) {
        	
        	//TODO alert the auth system
        	//fantasy.Auth.askServerForAuthState();
        }
    },
    setId: function(new_id_value)
    {
    	this.set(this.idAttribute,new_id_value);
    	this.id = new_id_value;
    	return this;
    },
    getId: function() {
    	return this.get(this.idAttribute);
    },  
    toJSON: function(options) {
    	var attributeObj =  _.clone(this.attributes);  
    	return attributeObj;
    },
	clear: function() { 
		this.setId(null);
		this.metaAttributes = {};
 	    Backbone.Model.prototype.clear.apply(this,arguments);
	},
	isLoaded: function() { 
		if(this.loaded)
			return true;
		return false;
	},
	fetch: function() { 
		var self = this;
  		return Backbone.Model.prototype.fetch.apply(this,arguments).done(function() { 
  			self.loaded = true;
  		});
	},
	/** 
		NOT WORKING
	**/
	fetchOrPopulateFromCache: function() { 
		var self = this;
		promise = fantasy.cstore.cstore.getOrFetchItem(this,this.cache_ttl || 1800);
		return promise.done(function(arg) { 
			arg = arg.clone();
			self.set(_.extend({},arg.attributes));
			self.trigger("sync");
			//only do this if it's not the same model, cause we don't want to sync if we don't have to
			// if(self.cid !== arg.cid) {
			// 	self.trigger("sync");
			// }
		});
	},
	parse: function(response,options) { 
		if(Array.isArray(response)){
			console.error("Model got an array as a response during fetch. BOOOOOO!!!!", response, this);
			throw {msg: "Model got an array as a response during fetch. BOOOOOO!!!!", obj: this, response: response};
		}
		return response;
	}
});


fantasy.beans.Classes.CompositeModel = fantasy.beans.Classes.Base.extend({
	initialize: function(attributes, options)
	{
		 fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
		 this.subModelsMap = {};
		 this.subCollectionsMap = {};
		 this.depthLists = {}; //key is 0,1,2, ints, value is array

	},
	getModel: function(name) {
		return this.subModelsMap[name];
	},
	getCollection: function(name) { 
		return this.subCollectionsMap[name];
	},
	getModelOrCollection: function(name) {
		var obj = this.subModelsMap[name];
		if(obj)
			return obj;
		return this.subCollectionsMap[name];
	},
	/*
	@param name  --- the name for this model -- should be variable survivable, i.e. no spaces, no special characters
	@param model -- the model instance to add 
	*/
	addModel: function(name, model,options)
	{
		var depth = 0;
		if(options && options.depth)
			depth = options.depth;
		model.local_name = name;
		if(options && options.load_fail_okay)
			model.load_fail_okay = options.load_fail_okay;
		else
			model.load_fail_okay = false;

		if(options && options.clone_it)
			model.clone_it = true;

		var self = this;

		if(this.subModelsMap[name])
		{
			this.stopListening(this.subModelsMap[name], "sync normalize_sync");
		}

		this.subModelsMap[name] = model;
		if(model)
		{
			var attributes = _.extend({loaded:model.isLoaded()}, model.attributes, {meta: model.metaAttributes});

			this.set(name,attributes);
			this.listenTo(model,"sync normalize_sync",function() { 
				self.subModelSyncHandler(name,model);
			});	
			this.listenTo(model,"modified", function() { 
				this.trigger("modified");
			});		
			model.parent_model = this;
		}

		var depthList = self.depthLists[depth] || [];
		depthList.push(name);
		self.depthLists[depth] = depthList;
		return self;

	},
	removeDepthListName: function(name) { 
		var self = this;
		if(!self.depthLists || self.depthLists.length ===0)
			return;	
		_.each(_.keys(self.depthLists), function(key) { 
			var list = self.depthLists[key] || [];
			list = _.without(list,name);
			self.depthLists[key] = list;
		});

	},
	removeModelOrCollection: function(name) { 
		if(!name)
			return;
		//unbind our listening
		if(this.subModelsMap[name])
		{
			this.stopListening(this.subModelsMap[name]);
		}
		if(this.subCollectionsMap[name])
		{
			this.stopListening(this.subCollectionsMap[name]);
		}

		//removes it from the attributes:
		this.unset(name);
		this.removeDepthListName(name);
		//removes it from the maps:		
		delete this.subModelsMap[name];
		delete this.subCollectionsMap[name];
		return this;
	},
	removeModel: function(name) { 
		return this.removeModelOrCollection(name);
	},
	removeCollection: function(name) { 
		return this.removeModelOrCollection(name);
	},
	subModelSyncHandler: function(name, model)
	{
		var attributes = _.extend({loaded:model.isLoaded()}, model.attributes, {meta: model.metaAttributes});
		this.set(name, attributes);
		this.trigger("sync");
	},
	addModelOrCollection: function(name,obj, options) { 
		if(obj instanceof Backbone.Model)
		{
			this.addModel(name,obj,options);
		} else if (obj instanceof Backbone.Collection) {
			this.addCollection(name,obj,options);
				
		}
		else {
			var msg = "UNIDENTIFIED non model, non collection attempt to add to comp model";
			console.error(msg,name,obj,options);
			throw msg;
		}
	}, 
	addCollection: function(name, collection,options)
	{
		var self = this;
		var depth = 0;
		if(options && options.depth)
			depth = options.depth;
		if(options && options.load_fail_okay)
			collection.load_fail_okay = options.load_fail_okay;
		else
			collection.load_fail_okay = false;

		collection.local_name = name;

		if(this.subCollectionsMap[name])
		{
			this.stopListening(this.subCollectionsMap[name], "sync");
		}
		self.removeCollection(name);		
		this.subCollectionsMap[name] = collection;
		this.set(name, collection.toJSON({include_loaded:true}));
		this.listenTo(collection,"sync",function() { 
			self.set(name + "_not_empty", (collection.length === 0 ? false : true));			
			self.set(name + "_empty", (collection.length === 0 ? true : false));
			self.set(name + "_length", collection.length );
			self.set(name + "_loaded", collection.loaded ? true : false );

			self.set(name, collection.toJSON({include_loaded:true}));
			self.trigger("sync");
		});
		var depthList = self.depthLists[depth] || [];
		depthList.push(name);
		self.depthLists[depth] = depthList;

		return self;
	},
	fetch: function() { 
		//console.log("CompositeModel fetch start.");
		return this.fetchAllNotLoaded();
	},
	refetch: function() { 
		return this.fetchAll();
	},
	fetchList: function(items, options) {
		//console.log("fetchList",items,options);
		var self = this;
		var promises = [];
		var optionalPromises = [];

		_.each(items, function(item) { 
			var promise = null;
			if(item && item.shouldFetch && !item.shouldFetch())
			{
				console.log("skipping fetch of item that says it shouldn't be fetched", item);
				item.failed_to_load = true;
				if(item && item.set)
				{
					item.set("load_failed", true);
					item.trigger("sync");
				}
				return;
			}

			if(item && item.use_cache && item.mdl_name && item.local_name && fantasy.cstore.cstore)
			{
				//console.log("USING CACHE FOR FETCH LIST ITEM", item);
				promise = fantasy.cstore.cstore.getOrFetchItem(item,item.cache_ttl || 1800);
				promise.done(function(arg) { 
					//console.log("fetched item from cache, ", arg, self, item.local_name,options);
					//add the thing from the cache back.
					if(item.clone_it)
					{
						var from_cache = arg;
						arg = from_cache.clone();
						arg.set(_.extend({},from_cache.attributes));
					}
					self.addModelOrCollection(item.local_name,arg,options);
					//todo brendan experiment
					arg.trigger("sync");
				});
			}
			else
			{
				promise = item.fetch();
				promise.fail(function(err) { 
					console.error("Trouble fetching item in fetchList", this, item, arguments);
					if(item && item.load_fail_okay)
					{
						if(item.set)
						{
							item.set("load_failed", true);
						}
					}
				});
			}
			if(item && item.load_fail_okay)
				optionalPromises.push(promise);
			else
				promises.push(promise);
		});
		var deferred = new $.Deferred();

		 var optionalPromise = $.when.apply($,optionalPromises).always(function() { 
		 	deferred.resolve();
		 });	

		 optionalPromise.fail(function(err) { 
		 	console.log("optional loading had a failure", arguments);
		 });

		 promises.push(deferred.promise());

		return $.when.apply($,promises);	
	},
	fetchAll: function() { 
		return this._fetchAllDepths(false);
	},
	fetchAllOLD: function() { 
		var deferred = new $.Deferred();
		var promises = [];

		_.each(this.subModelsMap, function(item) { 
			promises.push(item.fetch());
		});
		_.each(this.subCollectionsMap, function(collection) { 
			promises.push(collection.fetch());
		});
		return $.when.apply($,promises);	
	},
	fetchDepthLevel: function(depth, only_not_loaded) { 
		var self = this;
		if(self.levelPrep)
			self.levelPrep(depth, only_not_loaded);
		var list = this.depthLists[depth] || [];
		var objs = [];
		//console.log("fetchDepthLevel start, depth", depth,only_not_loaded,list,self);

		_.each(list,function(name) { 
			try {
				var obj = self.getModelOrCollection(name);
				if(obj)
				{
					if(!obj.isLoaded || !only_not_loaded || (obj.isLoaded && only_not_loaded && !obj.isLoaded()))
						objs.push(obj);
					else
					{
						//console.log("not going to fetch an object because already loaded and flagged not to load",obj,self);
					}
				}
				else
				{
					console.error("attempt to find model or collection by name failed", name,self);
				}
			}catch(err)
			{
				console.error("Attempt to assemble list of objs to load, error", name, err, self);
				throw "Attempt to assemble list of objs to load, error with name " + name;
			}
		});
		var promise = self.fetchList(objs,{depth:depth});
		return promise;
	},
	fetchAllNotLoaded: function() { 
		return this._fetchAllDepths(true);
	},
	_fetchAllDepths: function(only_not_loaded) { 
		//console.log("CompositeModel fetchAllNotLoaded.");
		var self = this;
		var deferred = new $.Deferred();
		var resolveFastDeferred = new $.Deferred();
		resolveFastDeferred.resolve();
		var promises = [];
		var i = 0;
		var endReached = false;
		var lastPromise = resolveFastDeferred.promise();

		function makeDoLevelFunc(level) { 
			var myfunc = function() { 
			 	//console.log("successful level fetch, depth i",level);
			 	return _.bind(self.fetchDepthLevel, self, level,only_not_loaded)();
			};
			return myfunc;
		}
		function makeFailLevelFunc(level)  {
			var myfuncb = function(err) { 
			 	console.error("Trouble loading depth level i",level, err, self);
			 	deferred.reject(err);
			};
			return myfuncb;			
		}

		function makeNextPromise(previousPromise, suc,fail_func) { 
			var def = new $.Deferred();
			previousPromise.done(function() { 
				suc().done(function() { 
					def.resolve();
				});
			}).fail(function(err) { 
				fail_func(err);
				def.reject();
			});
			return def.promise();
		}
		//chain the promises on each other.
		for(;!endReached;i++) {
			var list = self.depthLists[i] || null;
			if(!list)
			{
				endReached = true;
			}
			else {
				// lastPromise = lastPromise.done(makeDoLevelFunc(i)).fail(makeFailLevelFunc(i));
				lastPromise = makeNextPromise(lastPromise,makeDoLevelFunc(i)).fail(makeFailLevelFunc(i));
			}
 
		}

		lastPromise.done(function() { 
			//console.log("fetch via depth levels succeeded. last successful level ",i-1,self);
			deferred.resolve(self);
		}).fail(function(err) { 
			console.log("fetch via depth levels ultimately failed.",i-1,err,self);
			deferred.reject(err);
		});
		
		return deferred.promise();
	},		
	fetchAllNotLoadedOLD: function() { 
		//console.log("CompositeModel fetchAllNotLoaded.");

		var deferred = new $.Deferred();
		var promises = [];

		_.each(this.subModelsMap, function(item) { 
			if(!item.isLoaded())
				promises.push(item.fetch());
		});
		_.each(this.subCollectionsMap, function(collection) { 
			promises.push(collection.fetch());
		});
		return $.when.apply($,promises);	
	},	
	clear: function() { 
		_.each(this.subModelsMap, function(item) { 
			item.clear();
		});
		_.each(this.subCollectionsMap, function(collection) { 
			collection.reset();
		});		
		 fantasy.beans.Classes.Base.prototype.clear.apply(this,arguments);

	}
});


/**

Base Collection that should handle universal error handling

**/
fantasy.Collections.Classes.Base =  Backbone.Collection.extend({
	
 initialize: function(models, options) {
 	var self = this;
        this.listenTo(this,"error", this.defaultSyncErrorHandler);
  		Backbone.Collection.prototype.initialize.apply(this,arguments);
  		if(options && options.id)
  			this.id = options.id;
  		this.use_real_api = false;

  		this.idAttribute = this.idAttribute || "id";

  		this.setAttributeFromOptions(options,this.idAttribute);
  		this.setAttributeFromOptions(options,"id",this.idAttribute);
  		this.id_fields_concat = "";

  		if(this.id_fields)
  		{

  			//console.log("Collection: id_fields found", this.id_fields);
  			_.each(this.id_fields,function(id_name) { 
  				self.setAttributeFromOptions(options,id_name);
  				this.id_fields_concat += id_name;
  			});
  		} else {
  				this.id_fields_concat += this.idAttribute;
  		}

  		this.listenTo(this,"refetch_collection", this.fetch);

    },
    fetch: function(options) { 
    	var self = this;
    	var promise =  Backbone.Collection.prototype.fetch.apply(this,arguments);
    	promise.done(function() { 
    		self.loaded = true;
    	});
    	return promise;

    },
    /** OVERRIDING DEFAULT FUNC TO ALLOW MODEL MASSAGE / meta injection **/
        _prepareModel: function(attrs, options) {
        	var self = this;
      if (this._isModel(attrs)) {
        if (!attrs.collection) attrs.collection = this;
        return attrs;
      }
      options = options ? _.clone(options) : {};
      options.collection = this;
      var model = new this.model(attrs, options);
      //begin custom
      if(model && !model.validationError)
      {
      	self.modelInject(model);
      }
      else
      {
      	console.error("not running modelInject because model not found or validation error", model, model.validationError);
      }
      model.setAsLoaded();
      //end custom
      if (!model.validationError) return model;
      this.trigger('invalid', this, model.validationError, options);
      return false;
    },
    /** Override me **/
    modelInject: function(model) { 

    },
    setMetaOnAll: function(name,val) {
    	_.each(this.models, function(model) { model.setMeta(name,val);});
    },
    setAttributeFromOptions: function(options, source_field_name, opt_dest_field_name) { 
    	opt_dest_field_name = opt_dest_field_name || source_field_name;
    	if(options)
    	{
    		if(options[source_field_name])
    		{
    			this[opt_dest_field_name] = options[source_field_name];
    		}
    		else
    		{
    			//console.log("Collection: Can't find idfield attribute ", source_field_name, options);
    		}
    	}
    },
    /** 
	ARGUMENTS TBD
    **/
    defaultSyncErrorHandler: function(collection, error) {
        if (error.status == 401 || error.status == 403) {
        	//fantasy.Auth.askServerForAuthState();
        }
    },
    /**
	BEWARE OF THIS FUNCTION...
    **/
    saveAll: function() {
    	//save all the models in the collections...
      this.each(function(model) {
      	model.save();
  		});
    },
    setId: function(new_id_value)
    {
    	if(this.idAttribute)
    	{
    		this[idAttribute] = new_id_value;
    		this.id = new_id_value;
    	}
    },
    getId: function() {
    	var self = this;
    	if(this.idAttribute && !this.id_fields)
    	{
    		return this[this.idAttribute];
    	} else if (this.id_fields)
    	{
    		var id_concat = "";

  			_.each(this.id_fields,function(id_name) { 
  				id_concat += self[id_name];
  			});
  			return id_concat;
    	}
    	if(this.id)
    		return this.id;
    },
	toJSON: function(options) {
      return this.map(function(model) { return model.toJSON(options); });
    },
    reFetchEach: function() { 
    	var promise = null;
    	var promises_array = [];

    	_.each(this.models, function(model) { 
    		promises_array.push(model.fetch());
    	});

    	promise = $.when.apply($, promises_array);

    	return promise;
    }

});



/**

So this is a type of collection that says, hey, I'm first going to get a shallow list of cores, then I'm going to convert that to a richer
composite model.

expects subclass to extend:
model: class of the composite  (the composite must accept an option of "model" to be used as its core.)
idAttribute: "core_object_pkey_field_name like sport_id",
coreCollectionClass: class of the core collection. It gets the same options as this CCCC collection passed to it when we construct it.
**/
fantasy.Collections.Classes.CoreToCompositeConverterCollection = fantasy.Collections.Classes.Base.extend({
   initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      this.coreCollection = new this.coreCollectionClass(null,options);
      this.savedOptions = options;

    },
    fetch:function(options) {
    	//console.log("CoreToCompositeConverterCollection fetch start",this);
    	var composites = [];
    	var self = this;
    	var deferred = new $.Deferred();

    	/** 
			migrate down the id_fields from the parent to the child collection
    	**/
    	_.each(this.id_fields,function(id_field){ 
    		self.coreCollection[id_field] = self[id_field];
    	});
    	if(self.idAttribute && self[self.idAttribute])
    	{
    		self.coreCollection[self.idAttribute] = self[self.idAttribute];
    	}

    	this.coreCollection.fetch().done(function() { 

    	//console.log("CoreToCompositeConverterCollection core collection fetched",self);


    		var promises = [];

    		_.each(self.coreCollection.models,function(coreModel) { 
    			var composite = new self.model({id: coreModel.getId()},_.extend({},self.savedOptions, {model: coreModel}));
    			composites.push(composite);
    			promises.push(composite.fetch());
    		});

    		$.when.apply($, promises).done(function() { 
    			self.reset(composites);
	    		self.loaded = true;

	    		//SYNC HAS TO HAPPEN BEFORE RESOLVE.
    			self.trigger("sync");    		
    			deferred.resolve(self);

    		}).fail(function(err) { 
    			console.error("trouble with individual fetches of CoreToCompositeConverterCollection composite models",err,self);
    			deferred.reject(err);
    		});

    	}).fail(function(err) { 
    		console.error("trouble loading core collection for CoreToCompositeConverterCollection ", err,self);
   			deferred.reject(err);  		
    	});
    	var promise =deferred.promise();
    	promise.done(function() { 
    		self.loaded = true;
    	});
    	return promise;


    }
});




/**

The state of the user in the app's browsing experience, to track things like:

examples:
*) Has the user turned on "Advanced" mode?
*) Has the user turned on "Night" mode?
*) Has the user already done XYZ, like have they seen the splash welcome pages for first time users?
*) Current GARAGE for screens we're looking at
*) Current CITY
*) Authenticated or Not


**/

fantasy.Supers.AbstractState = fantasy.beans.Classes.CompositeModel.extend({
	initialize: function(attributes, options)
	{
		var self = this;
		 fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);

		 //_deferreds is a map of String Names to JQuery "Deferred" objects 
		 //[Not promises, rather, importantly, Deferreds]
		 this._deferreds = {};

		 this.resetDefaultState();


	},
	resetDefaultState: function() { 
		 

	},
	isPending: function(name) { 
		var deferred = this.getDeferred(name);
		if(deferred) {
			return deferred.state() === "pending";
		}
		else
		{
			throw "deferred by that name not found: " + name;
		}
	},
	ifNotPendingReset: function(name) { 
		if(!this.isPending(name))
			this.addDeferred(name);
	},

	/**
		Inside this state, lookup the deferred by this name, and then set a backbone attribute
		variable based on that name, with the deferred's current state (i.e. in progress, pending,
		resolved, rejected, etc.).


		@param name
	**/
	updateStatusAttribute: function (name) { 
		var deferred = this.getDeferred(name);
		if(deferred) {
			this.set(name, deferred.state());
		}
	},
	/**
	for this name, make a new jquery deferred, register it, and return me the promise.

	@param name
	@returns promise
	**/
	addDeferred: function(name) { 
		var self = this;
		var deferred = new $.Deferred();
		deferred.randid = Math.floor(Math.random() * 10000000) + 1 ;
		this._deferreds[name] = deferred;
		self.updateStatusAttribute(name);

		deferred.always(function() { 
			self.updateStatusAttribute(name);
		});

		return deferred.promise();
	},
	/**
	for this name, get me the jquery deferred object

	@param name
	@returns jquery deferred object
	**/
	getDeferred: function(name) {
		return this._deferreds[name];
	},
	/**
	for this name, get me a promise for it.

	@param name
	@returns promise
	**/
	getPromise: function(name) { 
		var deferred = this.getDeferred(name);
		if(!deferred)
		{
			var err_msg = "UserState: someone asked for a promise named " + name + " but we can't find its deferred. ";
			console.error(err_msg);
			throw err_msg;
		}
		return deferred.promise();
	},
	
	/**
	
	given an array of NAMES of registered deferreds that we expect to already be registered,
	give me a composite "when" based promise and register its deferred in the registrar.

	@param name the name of the new composite
	@param child_names array of strings that are names of already registered deferreds.
	@returns Promise of the WHEN based on the child_

	**/
	addCompositeDeferred: function(name, child_names) {
		var self= this;
		var deferred = new $.Deferred();
		var array_of_deferreds = [];
		_.each(child_names, function(name) {
			array_of_deferreds.push(self.getPromise(name));
		 });
		var whenPromise = $.when.apply($, array_of_deferreds);

		//we translate the whenPromise into a deferred object we can have access to. 
		whenPromise.done(function(arg) { 
			deferred.resolve(arg);
		}).fail(function(arg) { 
			deferred.reject(arg);
		});

		this._deferreds[name] = deferred;
		return deferred.promise();
	}
});



