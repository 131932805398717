fantasy.packs.CoreCollections = fantasy.packs.CoreCollections || {};

fantasy.packs.CoreCollections.SportsAll = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Sport,
        mdl_name: "SportsAll",
    use_cache: true,  	
	url: "/api/sport/"
});

fantasy.packs.CoreCollections.InvitesToMe = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.LeagueInvite,
        mdl_name: "InvitesToMe",
    use_cache: false,  	
	url: "/api/league_invite/incoming/"
});


fantasy.packs.CoreCollections.AthletesAll = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesAll",
    use_cache: true,  	
	url: "/api/athlete/",
	comparator: function(athlete){
   		return athlete.get("last_name");
	}
});



fantasy.packs.CoreCollections.AthletesForSport = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesForSport",
    use_cache: true,    	
	url: function() { return "/api/sport/" + this.sport_id + "/athletes/";},
	idAttribute: "sport_id",
	comparator: function(athlete){
   		return athlete.get("last_name");
	}
});

fantasy.packs.CoreCollections.AthletesLeftForLeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesLeftForLeague",
    use_cache: false,    	
	url: function() { return "/api/athlete/left_to_draft/?sport_id=" + this.sport_id + "&league_id=" + this.league_id;},
	idAttribute: "league_id",
	 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      if(options && options.sport_id)
      	this.sport_id = options.sport_id;
    },
    comparator: function(athlete){
   		return athlete.get("last_name");
	}
});


fantasy.packs.CoreCollections.AthletesInLeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesInLeague",
    use_cache: true,    	
	url: function() { return "/api/league/" + this.league_id + "/athletes_in_league/";},
	idAttribute: "league_id",
	comparator: function(athlete){
   		return athlete.get("last_name");
	}
});





fantasy.packs.CoreCollections.AthletesOnRealTeam = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesOnRealTeam",
    use_cache: true,    	
	url: function() { return "/api/real_team/" + this.real_team_id + "/athletes/";},
	idAttribute: "real_team_id",
	comparator: function(athlete){
   		return athlete.get("last_name");
	}
});

fantasy.packs.CoreCollections.LeaguesForUser = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.League,
	url: function() { return "/api/user/" + this.user_id + "/leagues/";},
	idAttribute: "user_id",
	comparator: function(a,b) { 
		if(!a || !b)
			return 0;
		var a_created_ts = a.get("created_ts");
		var b_created_ts = b.get("created_ts");
		if(!a_created_ts && b_created_ts)
			return 1;
		if(a_created_ts && !b_created_ts)
			return -1;		

		if(a_created_ts > b_created_ts)
			return -1;		

		return 1;

	}
});

fantasy.packs.CoreCollections.LeaguesForUserAndSeason = fantasy.Collections.Classes.Base.extend({
	mdl_name: "AthletesOnRealTeam",
    use_cache: true,
    cache_ttl: 600,     
	model: fantasy.packs.CompBeans.League,
	url: function() {
		return "/api/user/" + this.user_id + "/query/leagues/?dest.season_id=" + this.season_id;
	},
	id_fields: ["user_id","season_id"]
});

fantasy.packs.CoreCollections.UsersInALeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.User,
	url: function() { return "/api/league/" + this.league_id + "/users/";},
	idAttribute: "league_id"
});


// fantasy.packs.CoreCollections.OutstandingInvites = fantasy.Collections.Classes.Base.extend({
// 	model: fantasy.packs.CompBeans.LeagueInvite,
// 	url: function() { return "/api/league/" + this.league_id + "/league_invites/";},
// 	idAttribute: "league_id",
// 	parse: function(response,options){
// 		if(!response)
// 			return;
// 		var cleanedList = [];
// 		var emailMap = {};

// 		_.each(response,function(obj) { 
// 			if(obj && !obj.accepted_flag && !obj.declined_flag && !emailMap[obj.invited_email]){
// 				cleanedList.push(obj);
// 				emailMap[obj.invited_email] = true;
// 			}
// 		});

// 		return cleanedList;

// 	}
// });


fantasy.packs.CoreCollections.OutboundInvitesFromLeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.LeagueInvite,
	url: function() { return "/api/league_invite/outbound/" + this.league_id;},
	idAttribute: "league_id"	
});


fantasy.packs.CoreCollections.UserLeagueMemsInALeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.UserLeagueMem,
	url: function() { return "/api/league/" + this.league_id + "/user_league_mems/";},
	idAttribute: "league_id"
});

fantasy.packs.CoreCollections.FantasyTeamsInALeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.FantasyTeam,
	url: function() { return "/api/league/" + this.league_id + "/f_teams/";},
	idAttribute: "league_id"
});


fantasy.packs.CoreCollections.FantasyTeamsForUserAndLeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.FantasyTeam,
	url: function() { 

		if(!this.user_id || !this.league_id)
			throw "FantasyTeamsForUserAndLeague requires both a user_id and league_id";
		
		return "/api/query/f_team/?user_id=" + this.user_id + "&league_id=" + this.league_id;},
	idAttribute: "league_id",
   initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      if(options && options.user_id)
      	this.user_id = options.user_id;
    }
});


fantasy.packs.CoreCollections.AthletesOnAFTeam = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
	url: function() { return "/api/f_team/" + this.f_team_id + "/athletes/";},
	idAttribute: "f_team_id",
	comparator: function(athlete){
   		return athlete.get("last_name");
	}
});



fantasy.packs.CoreCollections.PointCategoryAll = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.PointCategory,
        mdl_name: "PointCategoryAll",
    use_cache: true,
    cache_ttl: 6000,  	
	url: "/api/point_category/",
	comparator: function(model){
   		return model.get("name");
	}
});



/**

**/
fantasy.packs.CoreCollections.PointRoundsForAthleteRound = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.AthleteRound,
	url: function() { return "/api/query/athlete_round/?athlete_id=" + this.athlete_id + "&round_id=" + this.round_id;},
	id_fields: ["athlete_id","round_id"],
	initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      console.log("PointRoundsForAthleteRound collection init", arguments);
  }
});


fantasy.packs.CoreCollections.PointJournalsForAthlete = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.PointJournal,
	url: function() { return "/api/athlete/" + this.athlete_id + "/point_journals/";},
	idAttribute: "athlete_id"
});

fantasy.packs.CoreCollections.RecentPointJournals = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.PointJournal,
	url: function() { return "/api/query/point_journal/?limit=" + this.limit + "&offset=" + this.offset + "&sport_id=" + this.sport_id;},
	id_fields: ["limit","offset","sport_id"],
	idAttribute: "sport_id",
        mdl_name: "RecentPointJournals",
    use_cache: true,    
 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
   	  if(!this.limit)
   	  	this.limit = 10;
   	  if(!this.sport_id)
   	  	this.sport_id = 1;
   	  if(!this.offset)
   	  	this.offset = 0;
    },
    parse: function(response,options)
    {
    	console.log("recent point journal parse", arguments);
    	return response;
    }
});



fantasy.packs.CoreCollections.LifetimePointsGroupedSumsForAthleteSport = fantasy.Collections.Classes.Base.extend({
	model: fantasy.beans.Classes.Base,
	url: function() { return "/api/stats/point_journal/?athlete_id=" + this.athlete_id + "&sport_id=" + this.sport_id;},
	id_fields: ["athlete_id","sport_id"],
	idAttribute: "sport_id",
        mdl_name: "LifetimePointSumForAthleteSport",
    use_cache: true,    
 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
   	  if(!this.sport_id)
   	  	this.sport_id = 1;
    }
});



fantasy.packs.CoreCollections.RoundPointsGroupedSumsForAthleteSport = fantasy.Collections.Classes.Base.extend({
	model: fantasy.beans.Classes.Base,
	url: function() { return "/api/stats/point_journal/?athlete_id=" + this.athlete_id + "&sport_id=" + this.sport_id + "&round_id=" + this.round_id;},
	id_fields: ["athlete_id","sport_id","round_id"],
	idAttribute: "athlete_id",
        mdl_name: "RoundPointsGroupedSumsForAthleteSport",
    use_cache: true,    
 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
   	  if(!this.sport_id)
   	  	this.sport_id = 1;
    }
});

//https://localhost.fantasydictator.com/api/stats/point_journal/?athlete_id=7&sport_id=1&no_group=true


fantasy.packs.CoreCollections.RoundsForSeason = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Round,
        mdl_name: "RoundsForSeason",
    use_cache: true,    	
	url: function() { 
		if(!this.season_id)
			throw "season_id not optional for RoundsForSeason";
		
		return "/api/season/" + this.season_id + "/rounds/";},
	idAttribute: "season_id"
});




fantasy.packs.CoreCollections.GamesForLeague = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.FantasyGame,
	url: function() { return "/api/league/" + this.league_id + "/games/";},
	idAttribute: "league_id"
});

fantasy.packs.CoreCollections.GamesForLeagueAndRound = fantasy.Collections.Classes.Base.extend({
    mdl_name: "GamesForLeagueAndRound",
    use_cache: true, 
	model: fantasy.packs.CompBeans.FantasyGame,
	url: function() { 

		if(!this.round_id || !this.league_id)
			throw "GamesForLeagueAndRound requires both a round_id and league_id";
		
		return "/api/query/f_game/?round_id=" + this.round_id + "&league_id=" + this.league_id;},
	id_fields: ["league_id","round_id"],
   initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      if(options && options.round_id)
      	this.round_id = options.round_id;
    }
});

fantasy.packs.CoreCollections.PositionsForSport = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Position,
        mdl_name: "PositionsForSport",
    use_cache: true,    	
	url: function() { return "/api/sport/" + this.sport_id + "/positions/";},
	idAttribute: "sport_id"
});

fantasy.packs.CoreCollections.TeamStarterRoundRelsForTeamRound = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.TeamStarterRoundRel,
	id_fields: ["f_team_id","round_id"],
    mdl_name: "PositionsForSport",  
    use_cache: true,    	
	url: function() { return "/api/query/team_starter_round_rel/?f_team_id=" + this.f_team_id + "&round_id=" + this.round_id;},
	idAttribute: "f_team_id"
});



fantasy.packs.CoreCollections.AthletesForPrimaryPosition = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesForPrimaryPosition",
    use_cache: true, 	
	url: function() { return "/api/position/" + this.position_id + "/athletes/";},
	idAttribute: "position_id"
});

fantasy.packs.CoreCollections.RealTeamsForASport = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.RealTeam,
        mdl_name: "RealTeamsForASport",
    use_cache: true, 		
	url: function() { return "/api/sport/" + this.sport_id + "/real_teams/";},
	idAttribute: "sport_id"
});


fantasy.packs.CoreCollections.AllSeasons = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Season,
        mdl_name: "AllSeasons",
    use_cache: true,  	
	url: "/api/season/",
	getCurrentModels: function() { 
		var iscurrentmodels = this.where({in_season: true});
		console.log("AllSeasons getCurrentModels ", iscurrentmodels,this.models);
		return iscurrentmodels;
	},
	getPastModels: function() { 
		return this.where({is_current: false});
	},
	getDraftInFutureModels: function() { 
		return this.where({draft_in_future: true});
	}

});




fantasy.packs.CoreCollections.CurrentRoundsForSport = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Round,
    mdl_name: "CurrentRoundsForSport",
    idAttribute: "sport_id",
    id_fields: ["sport_id","season_id"],
    use_cache: true,  	
    url: function() { return "/api/round/current_round/" + this.sport_id + "?season_id=" + this.season_id;},
});


fantasy.packs.CoreCollections.CurrentSeasons = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Season,
    mdl_name: "CurrentSeasons",
    use_cache: true,  	
	fetch: function() { 
		var deferred = new $.Deferred();

		var self = this;
		this.allSeasons = new fantasy.packs.CoreCollections.AllSeasons();
  		var a = fantasy.cstore.cstore.getOrFetchItem(this.allSeasons,this.allSeasons.cache_ttl || 1800);
          a.done(function(arg) { 
          	self.allSeasons = arg;

//		return this.allSeasons.fetch().done(function() { 
			var currentModels = self.allSeasons.getCurrentModels();
			self.reset(currentModels);
			self.trigger("sync");
			deferred.resolve();
		}).fail(function(err) { 
			console.error("trouble loading current seasons",arguments);
			deferred.reject(err);
		});	

		return deferred.promise();
	}
	
});


fantasy.packs.CoreCollections.DraftInFutureSeasons = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.Season,
    mdl_name: "DraftInFutureSeasons",
    use_cache: true,  	
	fetch: function() { 
		var deferred = new $.Deferred();

		var self = this;
		this.allSeasons = new fantasy.packs.CoreCollections.AllSeasons();
  		var a = fantasy.cstore.cstore.getOrFetchItem(this.allSeasons,this.allSeasons.cache_ttl || 1800);
          a.done(function(arg) { 
          	self.allSeasons = arg;
			var currentModels = self.allSeasons.getDraftInFutureModels();
			self.reset(currentModels);
			self.trigger("sync");
			deferred.resolve();

		}).fail(function(err) { 
			console.error("trouble loading DraftInFutureSeasons",arguments);
			deferred.reject(err);

		});	
		return deferred.promise();
		
	}
	
});
