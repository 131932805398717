fantasy.packs.UXWidgets = fantasy.packs.UXWidgets || {};

/***
 {
	option_model_name_fieldname: "",
	option_model_value_fieldname: "",
	data_model_fieldname: "",
	data_model: obj
	options_collection: collection
 }
**/
fantasy.packs.UXWidgets.SelectInput =  fantasy.present.Classes.Base.extend({ 
	className: "fdux fd-select-input",
	template: fantasy.templates.select_input,
	events: { 
		"change select": "handleChange"
	},
	initialize: function(options) {
		console.log("fantasy.packs.UXWidgets.SelectInput init",this);
		fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
		this.config(options);
		this.render();
		window.selectInput = this;
	},
	config: function(options) { 
		console.log("fantasy.packs.UXWidgets.SelectInput config",this);

		options = options || {};
		if(options && options.options_collection)
		{
			this.options_collection = options.options_collection;
			this.listenTo(this.options_collection,"sync",_.bind(this.handleSync, this));
			this.data_model = options.data_model;
		}
		else
		{
			throw "options_collection required";
		}
		var local_model = new fantasy.beans.Classes.Base();
		local_model.set("data_model", options.data_model);
		local_model.set("data_model_fieldname", options.data_model_fieldname);
		local_model.set("option_model_value_fieldname", options.option_model_value_fieldname);
		local_model.set("option_model_name_fieldname", options.option_model_name_fieldname);
		if(this.options_collection)
			local_model.set("select_options_length", this.options_collection.length);
		local_model.set("select_options",this.options_collection.toJSON());
		this.setContent(local_model);
		this.listenTo(options.data_model,"change:" + options.data_model_fieldname,_.bind(this.handleValueChangeFromModel,this));

		this.update_data_model_auto = true;
		if(typeof options.update_data_model_auto === "boolean" && !options.update_data_model_auto)
			this.update_data_model_auto = false;
	},
	handleValueChangeFromModel: function() { 
		var val = this.data_model.get(this.model.get("data_model_fieldname"));
		if(this.$el.find("select").val() !== ("" + val))
			this.$el.find("select").val("" + val);
	},
	reflectLatestOptions: function(local_model, collection) { 
		local_model.set("select_options_length", collection.length);	
		local_model.set("select_options",collection.toJSON());
		this.render();
		console.log("fantasy.packs.UXWidgets.SelectInput reflectLatestOptions tail",this);

	},
	handleSync: function() { 	
		console.log("fantasy.packs.UXWidgets.SelectInput handleSync",this);
		if(this.options_collection)
		{
			this.reflectLatestOptions(this.model,this.options_collection);
		}
	},
	handleChange: function() { 
	    var selectedValue = this.$el.find("option:selected").val();
	    var selectedText = this.$el.find("option:selected").text();
		var val = this.data_model.get(this.model.get("data_model_fieldname"));
		if( ("" + val) !== ("" + selectedValue))
		{
			var int_value = parseInt(selectedValue);
			if(this.update_data_model_auto)
				this.data_model.set(this.model.get("data_model_fieldname"),int_value);
			this.trigger("changed_value",int_value);
		}
	},
	postRender: function() { 
		console.log("fantasy.packs.UXWidgets.SelectInput postRender",this);

	}
});

/**

<select name="{{name}}" class="{{classes}}">
	{{#each options}}
		<option {{#if_eq @root.selected_value value}} SELECTED {{/if_eq}} value="{{value}}">{{name}}</option>
	{{/each}}
</select>


**/