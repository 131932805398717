fantasy.packs = fantasy.packs || {};
fantasy.packs.Modal = fantasy.packs.Modal || {};


fantasy.packs.Modal.ModalManager = function() { 

	var self = this;
	_.extend(self, Backbone.Events);


	var dialogShellOrderedList = [];

	var hrefToDialogMap = {};

	self.addToStack = function(dialogShellView) { 
		dialogShellOrderedList.push(dialogShellView);
		hrefToDialogMap = _.indexBy(dialogShellOrderedList,"frag");
	};

	/**
		update our data model structures by removing a modal wrapper
		@param cid the unique, backbone generated ID for the modal wrapper view, to remove that view from the stack.
	**/
	self.removeFromStack = function(cid){
		var localStack = _.filter(dialogShellOrderedList, function(wrapper_candidate){ return wrapper_candidate.cid !== cid; });
		dialogShellOrderedList = localStack; //replace the current dialogShellOrderedList with one that does not have the wrapper we're removing.
		hrefToDialogMap = _.indexBy(dialogShellOrderedList,"frag");
	};

	/**
		Have we serviced the frag (aka route, aka url snip) parameter recently in our current modal stack?
		@param frag
		@returns boolean true if we have a modal that services that frag route in our stack.  false otherwise.
	**/
	self.hasHref = function(href){ 
		if(hrefToDialogMap[href])
			return true;
		return false;
	};

	/**
		look up, in the modal stack of wrapper views, the wrapper view that is registered for that frag
		@param frag
	**/
	self.getModaldialogShellViewByFrag = function(href) { 
		if(hrefToDialogMap[href])
		 return hrefToDialogMap[href];
		else
		return null;
	};

	/** 
		What's the index of the wrapper index for that given frag in our ORDERED modal stack?  0 === bottom
		@returns integer zero or greater for found wrapper view.  -1 for not-found.
	**/
	self.indexOfFrag = function(frag){ 
		if(hrefToDialogMap.length === 0)
			return -1;

		var ind = 0;
		var foundIndex = -1;
		_.each(dialogShellOrderedList,function(wrapper) { 
			if(wrapper.frag === frag)
				foundIndex = ind;
			ind++;
		});

		return foundIndex;
	};

	/** 
		modifies the DATA MODEL OF OUR MODAL STATE (but not the dom) to bring a certain modal to the front.
	Often used at the same time as bringToFrontDOM

	**/
	self.bringToFront = function(ind){ 
		var dialogShellView = dialogShellOrderedList[ind];
		if(!dialogShellView)
		{
			console.error("bring to front can't find indexed wrapper view... skipping.", dialogShellView);
			return;
		}
		var encounteredError = false;

		//first remove it:
		var localStack = _.filter(dialogShellOrderedList, function(wrapperCandidate){ 
			if(typeof wrapperCandidate === "undefined" || !wrapperCandidate)
			{
				encounteredError = true;
				return null;
			} else {
				return wrapperCandidate.cid !== dialogShellView.cid; 
			}
		});

		if(encounteredError)
		{
			console.error("bringToFront encountered error, skipping modal stack manipulation.");
			return;
		}
		dialogShellOrderedList = localStack; //replace the current dialogShellOrderedList with one that does not have the wrapper we're removing.
		//then update map by frag:
		hrefToDialogMap = _.indexBy(dialogShellOrderedList,"frag");

		//then add it again:
		self.addToStack(dialogShellView);

	};

	/** 
	moves a certain modal to the front in the DOM.  But not our modal stack data structure.
	Often used at the same time as bringToFront
	**/
	self.bringToFrontDOM = function(ind) { 
		var dialogShellView = dialogShellOrderedList[ind];
		if(!dialogShellView)
		{
			console.error("bringToFrontDOM bring to front can't find indexed wrapper view... skipping.", dialogShellView);
			return;
		}		
		if($("#fd-modals").children().length === 0)
		{
			console.error("Attempt to bring a modal to front but no modals are open.");
			return;
		}
		$("#fd-modals").children().first().before($("#fd-modals").children()[ind]);
	};

	/**
		prints out to the console, the current state of the stack.
	**/
	self.printDebugOfStack = function() { 

		if(self.working)
		{
			var boundFunc = _.bind(self.printDebugOfStack, self, arguments);
			_.delay(boundFunc,50);
			return;
		}
		self.working=true;

		if(dialogShellOrderedList.length === 0)
		{
			console.log("MODAL STACK IS EMPTY");
			self.working = false;

			return;
		}
		var lines = [];
		_.each(dialogShellOrderedList, function(dialogShellView) { 
			if(dialogShellView)
				lines.unshift(dialogShellView.cid + "  " + dialogShellView.frag);
			else
				console.error("odd behavior debug of modal stack, iterating and found null or undefined...", dialogShellView);
		});
		console.log("STACK:");
		_.each(lines, function(line) { 
			console.log(line);
		});
		console.log("END STACK");
		self.working = false;

	};


	self.isModalOpen = function() { return dialogShellOrderedList.length > 0;	};


	/**
		Closes all modals.  Does not modify the URL.
	**/
	self.closeAll = function() { 
	    $(window).off("popstate.modal_manager");
	    self.popstate_listener_on = false;
	    _.each(dialogShellOrderedList,function(dialogShellView) { 
	    	self.stopListening(dialogShellView);
	    	dialogShellView.remove();
	    });

		dialogShellOrderedList = [];
		hrefToDialogMap = {};
		self.working = false;
		$("#fd-screener").removeClass("fd-obscure");
		$("body").removeClass("modal_disable_nav");

	};

	self.getTopWrapper = function() { 
		if(dialogShellOrderedList.length === 0)
			return null;
		var lastWrapper = dialogShellOrderedList[dialogShellOrderedList.length-1];
		return lastWrapper;
	};

	/**
		If there are modals in our stack, this sets up a listener for the BACK BUTTON being pressed,
		and it closes the modal.

		No params
		No return
	**/
	self.backManagement = function() { 
		if(self.working)
		{
			var boundFunc = _.bind(self.backManagement, self, arguments);
			_.delay(boundFunc,50);
			return;
		}
		self.working = true;
	    //if we don't have any modals in our stack, do nothing.  listener is now OFF.
	    if(dialogShellOrderedList.length === 0){

	   		if(! self.popstate_listener_on){
	   			self.working = false;
	    		return;
	    	}

		    $(window).off("popstate.modal_manager");
		    $(document).off("keyup.modal_manager");

		    self.popstate_listener_on = false;
			console.log("TURNED OFF POPSTATE LISTENER");
			self.working = false;
	    	return;
	    }
	    if(self.popstate_listener_on){
	    	self.working = false;
	    	return;
	    }
		
		self.popstate_listener_on = true;

	    $(document).on("keyup.modal_manager", function(event) { 
	    	if(event.keyCode === 27)
	    	{
		        event.preventDefault();
		        event.stopPropagation();
			    self.handleCloseTopModal(null,false);
	    	}
	    });

	    //okay, so we have a modal if we got here. so, setup a listener for BACK BUTTON PRESSES, name-spaced to us
		$(window).on("popstate.modal_manager", function(event) { 

			//grab the current url fragment 
			var frag = "";
			if(Backbone && Backbone.history && Backbone.history.getFragment)
				frag = Backbone.history.getFragment();

		    console.log("CAUGHT BACK location ", + document.location + ", state: " + JSON.stringify(event.state), frag);

		    //if someone is going back but the frag matches the current wrapper, do nothing.
		    var topWrapper = self.getTopWrapper();
		    if(topWrapper && topWrapper.frag === frag)
		    {
				self.printDebugOfStack();		    	
		    	console.log("in back catch, but frag matches current so doing nothing...", frag, topWrapper, dialogShellOrderedList);
		    	return;
		    }

		    //close us up, but tell it, via second parameter, that we've already "done a back" since this is a back listener.
		    self.handleCloseTopModal(null,true);
		    //recursively run ourselves to turn off or on listener depending on stack size.
		    self.backManagement();
		});
		self.working = false;		
		console.log("SETUP POPSTATE LISTENER");
	};


	self.areYouSure = function(message, success, cancel) { 
		var subview = new fantasy.packs.Modal.AreYouSureView({message: message, success: success, cancel: cancel});
		self.wrapAndLaunchModal(subview,{triggerBackOnSubviewRemoval: false});
	};
	/**
	@param subview  the backbone view to be placed in the modal
	@param options the object containing things like    
			option  triggerBackOnSubviewRemoval     boolean true/false  if false, will not trigger
			the system to "GO BACK" in the ADDRESS BAR.
 
	**/
	self.wrapAndLaunchModal = function(subview, options)
	{

		if(self.working)
		{
			console.log("********* wrap and launch working delay");
			var boundFunc = _.bind(self.wrapAndLaunchModal, self, arguments);
			_.delay(boundFunc,50);
			return;
		}
		self.backManagement();
		self.working = true;
		var frag = "";
		
		if(Backbone && Backbone.history && Backbone.history.getFragment)
			frag = Backbone.history.getFragment();
		console.log("MODAL MANAGER WRAP AND LAUNCH", frag);

		self.printDebugOfStack();		    	


		if(dialogShellOrderedList.length > 0)
		{
			var topModal = dialogShellOrderedList[dialogShellOrderedList.length-1];
			if(topModal && topModal.frag === frag)
			{
				console.log("We're not going to launch the same modal again. Doing nothing.", topModal, frag);
				self.working = false;
				return;
			}
			if(!topModal)
			{
				console.log("modal stack is changin' out from under us!, inside wrap and launch.");
			}
		}

		if(self.hasHref(frag))
		{

			if(options && options.via_back_button)
			{
				console.log("wrapAndLaunchModal, modal already in stack, got here via the Back Button... ", options);
				//going to attempt to remove previous if it exists.
				if(options.previous_frag)
				{
					var prevModaldialogShellView = this.getModaldialogShellViewByFrag(options.previous_frag);
					if(prevModaldialogShellView) {
						console.log("wrapAndLaunchModal: modal already in stack, we believe we got here via back button, the last page was also a modal, about to try to remove the OLD Modal");
						self.removeFromStackAndDom(prevModaldialogShellView);
					} else
					{
						console.log("wrapAndLaunchModal: modal already in stack, but previous page doesn't seem to be an open modal.");
					}
				}

			}
			else
			{
				console.log("wrapAndLaunchModal, modal already in stack, we believe we did NOT get here via back button", options);
			}

			var indexOfFrag = self.indexOfFrag(frag);
			self.bringToFront(indexOfFrag);
			self.bringToFrontDOM(indexOfFrag);
			

			self.dealWithDismissability();
		
			console.log("This frag already had a modal, bringing it to front", frag);
			self.printDebugOfStack();		    	
				self.working = false;
			self.backManagement();

			return;
		}
		else if(options && options.via_back_button)
		{
			//current route is Not in the stack.  but we think we got here via back button.
				if(options.previous_frag)
				{
					var prevModaldialogShellViewGamma = this.getModaldialogShellViewByFrag(options.previous_frag);
					if(prevModaldialogShellViewGamma) {
						console.log("wrapAndLaunchModal: modal NOT already in stack, we believe we got here via back button, the last page was also a modal, about to try to remove the OLD Modal");
						self.removeFromStackAndDom(prevModaldialogShellViewGamma);
					} else
					{
						console.log("wrapAndLaunchModal: modal NOT already in stack, but previous page doesn't seem to be an open modal.");
					}
				}
		}



		options = options || {};



		var mergedOptions = _.extend({}, options, {subview:subview});
		var dialogShellView = new fantasy.packs.Modal.ModaldialogShellView(mergedOptions);
		if(options.undismissable)
			dialogShellView.undismissable = true;
		if(options.disable_nav)
			dialogShellView.disable_nav = true;
		

		dialogShellView.frag = frag;

		//bcc
		dialogShellView.render();

		self.listenToOnce(dialogShellView, "close_intent", self.handleCloseIntent);
		self.listenToOnce(dialogShellView, "silent_close_intent",self.handleSilentCloseIntent);
		self.addToStack(dialogShellView);
		$("#fd-modals").append(dialogShellView.$el);
		$("#fd-screener").addClass("fd-obscure");

		if(options.wrapperClass)
				$(".fd-modal-wrapper").each(function() {
					if($(this).hasClass('outer-modal')) { 
						console.log("it does");
					} else {
						$(this).addClass(options.wrapperClass);
					}
				});

		self.dealWithDismissability();
		self.working = false;

		self.backManagement();
		if(subview && subview.afterDom)
			subview.afterDom();
		
	};

	self.dealWithDismissability = function() { 
		var dialogShellView = dialogShellOrderedList[dialogShellOrderedList.length-1];

		if(dialogShellView && dialogShellView.undismissable) {
			$("#fd-screener").off("click.modal_manager");
			
			$("#fd-inner-screener").off("click.modal_manager");
		} else {
			$("#fd-screener").off("click.modal_manager");			
			$("#fd-screener").on("click.modal_manager",self.handleCloseTopModal);

			$("#fd-inner-screener").off("click.modal_manager");			
			$("#fd-inner-screener").on("click.modal_manager",self.handleCloseTopModal);
		}

		if(dialogShellView && dialogShellView.disable_nav)
		{
			$("body").addClass("modal_disable_nav");
		}
		else
		{
			$("body").removeClass("modal_disable_nav");
		}

	};

	/**
	
	close the specific modal.  If a "back" hasn't already happened, trigger one so that the URL goes backwards.

	@param dialogShellView the wrapper view to be closed.
	@param backAlreadyHappened boolean -- true if a back button press is how we got here in the first place.
	**/
	self.handleCloseIntent = function(dialogShellView, backAlreadyHappened)
	{
		self.handleSilentCloseIntent(dialogShellView);
		self.backManagement();		
		if(! backAlreadyHappened) { 
			console.log("TRIGGER BACK: window.history.back to make sure URL is proper.");
			window.history.back();
		}
	};


/**
	does not handle back listeners, does not handle ig screener opacity, JUST removes the modal wrapper from the dom and the 
	stack. That's it.
**/
	self.removeFromStackAndDom = function(dialogShellView){
		if(dialogShellView.removed_from_manager_stack)
		{
			console.error("modal manager, trying to remove modal that was already removed from stack");
			return;
		}
		console.log("modal stack before close, then cid, then frag", dialogShellOrderedList, dialogShellView.cid, dialogShellView.frag);
	
		self.removeFromStack(dialogShellView.cid);

		console.log("modal stack after close", dialogShellOrderedList);

		dialogShellView.removed_from_manager_stack = true;
		dialogShellView.remove();
	};

	/**

	Do the work of closing a modal, silently, without triggering a "url back."
	If we're the last modal to close, turn off the obscuring layer.

	@param dialogShellView the wrapper view to be closed.
	**/
	self.handleSilentCloseIntent = function(dialogShellView)
	{
		if(self.working)
		{
			console.log("handleSilentCloseIntent delay due to working flag");
			var boundFunc = _.bind(self.handleSilentCloseIntent, self, arguments);
			_.delay(boundFunc,50);
			return;
		}		
		self.working = true;
		self.printDebugOfStack();

		self.removeFromStackAndDom(dialogShellView);

		self.dealWithDismissability();

		self.working = false;
		self.backManagement();

		if(dialogShellOrderedList.length === 0)
		{
			$("#fd-screener").removeClass("fd-obscure");
		}
	};

	/**
		Close the top-most modal.
	**/
	self.handleCloseTopModal = function(e, backAlreadyHappened) { 
		if(e) 
		{
	        e.preventDefault();
	        e.stopPropagation();
		}

		if(dialogShellOrderedList.length === 0)
			return;
		var lastWrapper = dialogShellOrderedList[dialogShellOrderedList.length-1];
		if(lastWrapper.triggerBackOnSubviewRemoval)
			self.handleCloseIntent(lastWrapper, backAlreadyHappened);
		else
		{
			self.handleCloseIntent(lastWrapper, true);
		}
	};

	//SETUP LISTENER FOR SCREENER CLOSER.
	$("#fd-screener").on("click.modal_manager",self.handleCloseTopModal);
	$("#fd-inner-screener").on("click.modal_manager",self.handleCloseTopModal);
	return self;

};


/**

Simple container view that takes a "subview" as an option, and wraps it.

option  triggerBackOnSubviewRemoval     boolean true/false  if false, will not trigger
the system to "GO BACK" in the ADDRESS BAR.


**/
fantasy.packs.Modal.ModaldialogShellView = fantasy.present.Classes.ContainerView.extend({
	className: "fd-modal-wrapper",
    events:     {  "click close-this-modal": "triggerCloseIntent"},
	
	initialize: function(options)
	{
		console.log("ModaldialogShellView init");
		this.triggerBackOnSubviewRemoval = true;
		if(options && typeof options.triggerBackOnSubviewRemoval === "boolean")
		{
			this.triggerBackOnSubviewRemoval = options.triggerBackOnSubviewRemoval;
		}
		 fantasy.present.Classes.ContainerView.prototype.initialize.apply(this,arguments);
		 if(options && options.undismissable)
		 {

		 }
		 else
		 {
			 this.closerView = new fantasy.packs.Modal.CloserView();
			 this.closerView.render();
			 this.addView(this.closerView,{});
			 if(this.triggerBackOnSubviewRemoval)
				 this.listenToOnce(this.closerView,"close_intent", this.triggerCloseIntent); 
				else
				 this.listenToOnce(this.closerView,"close_intent", this.triggerSilentClose); 

		 }

		 this.triggeredCloseIntent = false;

		 if(options && options.subview)
		 {
		 	this.subview = options.subview;

		 	if(this.triggerBackOnSubviewRemoval)
		 		this.listenToOnce(this.subview,"being_removed",this.triggerCloseIntent );
		 	else
		 		this.listenToOnce(this.subview,"being_removed",this.triggerSilentClose );
			console.log("ModaldialogShellView about to addView the subview");

		 	this.addView(this.subview);
		 }

		console.log("ModaldialogShellView init END",this);


	},
	triggerSilentClose: function(e)
	{

		if(e) 
		{
	        e.preventDefault();
	        e.stopPropagation();
		}

		if(this.triggeredCloseIntent)
		{
			return;
		}

		this.triggeredCloseIntent = true;
		this.trigger("silent_close_intent", this);
		return;
	},
	triggerCloseIntent: function(e)
	{

		if(e) 
		{
	        e.preventDefault();
	        e.stopPropagation();
		}

		if(this.triggeredCloseIntent)
		{
			return;
		}

		this.triggeredCloseIntent = true;
		this.trigger("close_intent", this);
		return;
	},
	remove: function()
	{
		//important to do this so that when children get removed, the "being_removed" events they percolate
		//don't get interpreted as useful instructions.
		this.triggeredCloseIntent = true;
  	    fantasy.present.Classes.ContainerView.prototype.remove.apply(this,arguments);
	},
	postRender: function() { 
		console.log("ModaldialogShellView postRender");

	}
});


/**
Utility close view.
**/
fantasy.packs.Modal.CloserView = fantasy.present.Classes.Base.extend({
	className: "fd-closer",
    template: fantasy.templates.mcv,
    events:     {  "click": "handleClick"},
    handleClick: function(e)
    {
    	console.log("closer view handle click");
		if(e) 
		{
	        e.preventDefault();
	        e.stopPropagation();
		}
		this.trigger("close_intent");
    }

});

/**
Utility close view.
**/
fantasy.packs.Modal.AreYouSureView = fantasy.present.Classes.Base.extend({
	className: "are-you-sure-view",
    template: fantasy.templates.are_you_sure,
    events:     {  
    	"click .confirm": "confirm",
    	"click .cancel": "cancel"
    },
	initialize: function(options)
	{
  	    fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
  	    if(options.success)
  	    	this.successCB = options.success;
  	    if(options.cancel)
  	    	this.cancelCB = options.cancel;
    	this.message = options.message || "Are you sure?";
    	this.setContent({message: this.message});
    	this.render();
	},    
    confirm: function(e)
    {
		if(e) 
		{
	        e.preventDefault();
	        e.stopPropagation();
		}
		if(this.successCB)
			this.successCB();
		this.trigger("confirmed");
		this.remove();
    },
    cancel: function(e)
    {
		if(e) 
		{
	        e.preventDefault();
	        e.stopPropagation();
		}
		if(this.cancelCB)
			this.cancelCB();
		this.trigger("canceled");
		this.remove();
    }

});

