fantasy.packs.Comps.PointJournalItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-point-journal-item",

	template: fantasy.templates.sub_point_journal_item_view
});

fantasy.packs.Comps.PointJournalListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list point-journal-list",
	subViewClass: fantasy.packs.Comps.PointJournalItemView
});


fantasy.packs.Comps.PointJournalItemFullView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-point-journal-item fd-point-journal-full-item",

	template: fantasy.templates.sub_point_journal_item_full_view
});

fantasy.packs.Comps.PointJournalFullListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list point-journal-full-list",
	subViewClass: fantasy.packs.Comps.PointJournalItemFullView
});
