fantasy.beans = fantasy.beans || {};
fantasy.beans.Classes = fantasy.beans.Classes || {};

fantasy.present = fantasy.present  || {};
fantasy.present.Classes = fantasy.present.Classes || {};

fantasy.Collections = fantasy.Collections || {};
fantasy.Collections.Classes = fantasy.Collections.Classes || {};


 fantasy.present.Classes.Base = Backbone.View.extend({
 	className: "",
  events: {
  	'keydown .enterclick' : 'baseKeydownHandler'
  },
  /**
	translates ENTER button into CLICK
  **/
  baseKeydownHandler: function(evt) {
	if(evt.which && evt.which == 13) 
		{
		  	evt.preventDefault();
		  	evt.stopPropagation();
		  	$(evt.currentTarget).trigger("click");
		}
  },
 
  	initialize: function(options)
	{
		Backbone.View.prototype.initialize.apply(this, arguments);

		if(typeof this.renderOnSync !== "undefined")
		{

		}
		else
		{
			this.renderOnSync = true;
		}

		//console.log("initialize view with options: ",options);

		if(options)
		{
			this.renderOnCollectionUpdate = (options.renderOnCollectionUpdate ? true : (this.renderOnCollectionUpdate ? true : false));

			if(typeof options.renderOnSync !== "undefined")
				this.renderOnSync = options.renderOnSync;

			if(options.model)
			{
				this.setContent(options.model);
			}
			else if(options.collection)
			{
				this.setContent(options.collection);
			}

			if(options.template)
				this.template = options.template;

		}
		this.listenTo(this,"added_to_dom", this.handleAddedToDom);
	},
	handleAddedToDom: function() { 

	},
	/**
		Called when an underlying collection, if it exists, triggers a sort event.
		Override in a subclass to handle sorting.
	**/
	handleSort: function() { 

	},
	setContent: function(arg)
	{
		var self = this;

		//console.log('setting content on view: ', arg);
		//ARG is either the MODEL or the COLLECTION
		if(arg)
		{


			if(arg instanceof Backbone.Model)
				this.model = arg;
			else if (arg instanceof Backbone.Collection) {
				this.collection = arg;
				this.listenTo(this.collection,"sort", function() { 
					self.handleSort();
				});
			}
			else if (typeof arg === "object")
			{
				arg = new fantasy.beans.Classes.Base(arg);
				this.model = arg;
			} else {
				console.error("unknown content type in view setContent ", arg, typeof arg);
			}

			if(this.renderOnSync) {
				if(this.syncListenJustOnce)
					this.listenToOnce(arg,"sync", this.handleSync);
				else {
					this.listenTo(arg,"sync normalize_sync", this.handleSync);

				}
			}
			else
			{
				console.log("NOT setting up sync listeners for view", this);
			}

			if(this.renderOnCollectionUpdate)
			{
				this.listenTo(arg,"update reset destroy", function() { 
					console.log("Caught UPDATE event, calling render.", self);
					self.render();
				});
			}

			//console.log("setup listener for sync render.. arg id: " + arg.id);

		}
		return this;

	},
	handleSync: function() { 
		this.render();
	},
	_strictRender: function(options) {
		options = options || {};

		var html = this._fauxRender();

		if(this.skip_render_via_diff){
			if(this.$el.html() === html)
			{
				console.log("skipping render, diff is identical");
			}
			else
			{
				this.$el.html(html);
			}
		}
		else {
			this.$el.html(html);
		}
		// if(this.template)
		// {
		// 	var attributes = {};
		// 	if(this.model){
		// 		attributes = _.extend({},this.model.attributes);
		// 	} else if (this.collection) {
		// 	//BEWARE -- we don't want to do this often...
		// 		attributes = this.collection.toJSON({include_loaded:true});
		// 	}


		// 	if(this.model)
		// 	{

		// 		attributes.is_new = this.model.isNew();
		// 		attributes.loaded = this.model.loaded;

		// 		if(this.model.metaAttributes)
		// 			attributes.meta = _.extend({},this.model.metaAttributes);
		// 	}
		// 	if(g_state)
		// 		attributes.userstate = g_state();

		// 	var html = this.template(attributes);
		// } else
		// {
		// 	console.log("no template found.", this);
		// }

	},
	/** returns the html that would be rendered **/
	_fauxRender: function() { 
		var html = "";

		if(this.template)
		{
			var attributes = {};
			if(this.model){
				attributes = _.extend({},this.model.attributes);
			} else if (this.collection) {
			//BEWARE -- we don't want to do this often...
				attributes = this.collection.toJSON({include_loaded:true});
			}


			if(this.model)
			{

				attributes.is_new = this.model.isNew();
				attributes.loaded = this.model.loaded;

				if(this.model.metaAttributes)
					attributes.meta = _.extend({},this.model.metaAttributes);
			}
			if(g_state)
				attributes.userstate = g_state();

			 html = this.template(attributes);
		} else
		{
			console.log("no template found.", this);
		}
		return html;
	},
	render: function() { 
		this._strictRender();

		if(this.postRender)
			this.postRender();

	    var classes = _.result(this, 'className');
	    this.$el.addClass(classes);

		//this.$el.attr('class', _.result(this, 'className'));
		return this;
	},
	remove: function() { 
		this.trigger("being_removed");
		if(this.collection)
		{
			this.stopListening(this.collection);
		}
		if(this.model)
		{
			this.stopListening(this.model);
		}
		Backbone.View.prototype.remove.apply(this, arguments);
		return this;

	},


});

 /** 
 Allow for any depth extending with compiling of events
 -- This allows subclasses of views to have their own events but also to incorporate the parent events.
 NOTE -- events with the same selector signature will be replaced.

 **/
 fantasy.present.Classes.Base.extend = function(child){
    var view = Backbone.View.extend.apply(this, arguments);
    view.prototype.events = _.extend({}, this.prototype.events, child.events);
    return view;
};

/**
ContainerView: Lets you have one view that contains other views.

**/
fantasy.present.Classes.ContainerView = fantasy.present.Classes.Base.extend({
	className: "con-v",
	
	initialize: function(options)
	{
		 fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
		 this.subviews = [];
		 if(options)
		 {
		 	if(options.emptyEveryRender)
		 		this.emptyEveryRender = options.emptyEveryRender;
		 }

	},
	addView: function(view, options) {
		var myOptions = options || {};

		if(!this.subviews)
			this.subviews = [];

		if(options && options.selector)
			view.selector = options.selector;

		//only used by subclass, assignable container view
		if(options && options.fineWithAppend)
			view.fineWithAppend = options.fineWithAppend;

		view.parentView = this;
		this.subviews.push(view);
		return this;
	},
	handleAddedToDom: function() { 
		_.each(this.subviews, function(view){ 
			if(view.handleParentAddedToDom)
				view.handleParentAddedToDom(); 
		});
	},
	handleParentAddedToDom: function() { 
		_.each(this.subviews, function(view){ 
			if(view.handleParentAddedToDom)
				view.handleParentAddedToDom(); 
		});
	},			
	render: function() { 
		//console.log("SUPER Container View Render Start");
		if(this.subviews)
		{
			var self = this;
			if(this.emptyEveryRender)
				self.$el.empty();
			if(this.subviews) {
			_.each(this.subviews, function(subview) { 
				self.$el.append(subview.$el);
				subview.appended_to_container = true;	
			});
			}
		}

		if(this.postRender)
			this.postRender();		
		return this;
	},
	rerenderEach: function() { 
		console.log("SUPER Container View rerenderEach Start");
		_.each(this.subviews, function(view){ view.render(); });
		return this;
	},
	remove: function() { 
		this.removing = true;
		this.removeAllSubs();
		fantasy.present.Classes.Base.prototype.remove.apply(this, arguments);
		return this;
	},
	removeAllSubs: function() { 
		_.each(this.subviews, function(view){ view.remove(); });
			this.subviews = [];
	}

});


fantasy.present.Classes.AssignableContainerView = fantasy.present.Classes.ContainerView.extend({
	addView: function(view, options) {
		fantasy.present.Classes.ContainerView.prototype.addView.apply(this, arguments);
		var self = this;
		if(view.selector && !view.fineWithAppend && self.$el && self.$el.find(view.selector) && self.$el.find(view.selector).length > 0)
		{
			self.assign(view,view.selector);
		}
   	},
	render : function () {
		var self = this;
		self.$el.empty();
		this._strictRender();

		if(this.subviews)
		{
			_.each(this.subviews, function(subview) { 
				var element = null;
				if(!subview.selector && !subview.fineWithAppend){
					console.error("childview in AssignableContainerView doesn't have a selector. NOT RENDERING",self, subview);
					

				}
				else if(subview.selector && !subview.fineWithAppend)
					self.assign(subview,subview.selector);
				else if(subview.fineWithAppend && !subview.selector)
				{
					 element = document.createElement("div" || subview.tagName);
					subview.setElement(element);
					subview.render();
					$(element).addClass(subview.className);
					self.$el.append(element);
				}
				else if(subview.fineWithAppend && subview.selector)
				{
					 element = document.createElement("div" || subview.tagName);
					subview.setElement(element);
					subview.render();
					self.$el.find(subview.selector).append(element);
				}
			});
		}
		if(this.postRender)
			this.postRender();	
	    return this;
	},
	assign : function (view, selector) {
		var data_obj = view.model || view.collection;

		//NOTE: this.$ === this.$el.find
		var elem = this.$(selector);
		if(!elem || (elem && elem.length === 0))
		{
			console.error("Can't find an assignable element with selector ", selector, " in this container $el: ", this.$el, " for subview: ", view, " as part of assignable container", this);
			return;
		}
	    view.setElement(elem);
	    var classes = _.result(view, 'className');
	    view.$el.addClass(classes);
		if(data_obj)
		{
			if(data_obj.loaded)
			{
				elem.removeClass("fd-loading");
			}
		}

	    view.render();
	}
});


/**
Uses factory pattern to lookup/create a view per model.  You can extend this and then override the makeSubView function
to give it custom functionality.  And/or you can just set a default subViewClass on your subclass or instance.

This class makes it so we don't have to iterate in handlebars on things as much and that individual models inside a 
collection, when updated, can just trigger a re-render on one part of the interface rather than the entire list.

**/
fantasy.present.Classes.CollectionView = fantasy.present.Classes.Base.extend({
	className: "collection",
	syncListenJustOnce: true,
	initialize: function(options)
	{
		this.subviews = [];
		this.subviewsByModelId = {};
		this.syncListenJustOnce = true;
		this.renderOnCollectionUpdate = true;

		 fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);

		 if(options)
		 {
		 	if(options.subViewClass)
		 		this.subViewClass = options.subViewClass;

		 	if(options.subtemplate)
		 		this.subtemplate = options.subtemplate;
		 }
	},
	handleAddedToDom: function() { 
		_.each(this.subviews, function(view){ 
			if(view.handleParentAddedToDom)
				view.handleParentAddedToDom(); 
		});
	},	
	handleParentAddedToDom: function() { 
		_.each(this.subviews, function(view){ 
			if(view.handleParentAddedToDom)
				view.handleParentAddedToDom(); 
		});
	},	
	makeSubView: function(model) { 
		//console.log("CollectionView makeSubView:", model);
		var opts = {model: model};

		if(this.subtemplate)
			opts.template = this.subtemplate;

		var theView = null;
		if(this.subViewClass)
			theView = new this.subViewClass(opts);
		else
			theView =  new  fantasy.present.Classes.Base(opts);

		var self = this;

		if(this.massageCreatedSubView)
			theView = this.massageCreatedSubView(theView);
		return theView;
	},
	setFixedWidthByCalculatingChildrenWidth: function(plus_extra, min, min_per) { 
		var children = this.$el.children();
		var numElements = children.length;
		var totalWidth = 0;
		min = min || 0;
		min_per = min_per || 0;
		var minBasedOnMinPer = min_per * numElements;

		_.each(children,function(child) { 
			totalWidth = totalWidth + $(child).outerWidth(true);//include margin true
		});
		totalWidth = totalWidth + plus_extra;
		if(totalWidth < min)
			totalWidth = min;
		if(totalWidth < minBasedOnMinPer)
			totalWidth = minBasedOnMinPer;

		this.$el.width(totalWidth);
	},
	getViewByModelId: function(model_id) { 
		return this.subviewsByModelId[model_id];
	},
	eachSubview: function(func) { 
		if(this.subviews && this.subviews.length > 0)
			_.each(this.subviews,func);
	},
	render: function() { 
		var self = this;

		self.removeAllSubViews();
		self.$el.empty();

		if(this.collection)
		{
			_.each(this.collection.models, function(model) { 
				var subview = self.makeSubView(model);
				self.subviews.push(subview);
				self.subviewsByModelId[model.getId()] = subview;
				self.$el.append(subview.render().$el);
			});
			if(this.collection.models.length === 0 && this.collection.loaded)
			{
				self.$el.html("<span class=\"collection-view-no-items\">No items.</span>");
			}
			else if(this.collection.models.length === 0)
			{
				self.$el.html("<span class=\"collection-view-loading\">Loading...</span>");

			}
		}
		if(this.postRender)
			this.postRender();
		return this;
	},
	/**
		Called when an underlying collection, if it exists, triggers a sort event.
		Override in a subclass to handle sorting.
	
	**/
	handleSort: function() { 
		console.log("HandleSort!");
		//1. iterate through new collection, and then look up its subview, and dom prepend view to start of container.
		var self = this;
		if(!self.$el)
		{
			console.error("Trouble finding own $el in collection view during handleSort,", this);
		}
		var sorted_subviews = [];
		var ind = 0;
		var lastDom = null;
		_.each(this.collection.models, function(model) { 

			var subview = self.getViewByModelId(model.getId());
			if(!subview)
				return;
							
			self.subviews = _.without(self.subviews, subview);
			sorted_subviews.push(subview);


			if(lastDom === null)
			{
				if(!self || !self.$el)
				{
					console.error("trouble finding $el in handleSort of Collection View. self: ", self);
				}
				self.$el.prepend(subview.$el);
				lastDom = subview.$el;
			}
			else
			{
				lastDom.after(subview.$el);
				lastDom = subview.$el;				
			}
			subview.$el.attr("last_sort_ord", ind);
			ind++;
		});
		self.subviews = sorted_subviews;
	},	
	removeAllSubViews: function() { 
		this.eachSubview(function(subview) { 
			if(subview)
				subview.remove();
		});
		this.subviews = [];
		this.subviewsByModelId = {};	
	},
	remove: function() { 
		this.removeAllSubViews();
		fantasy.present.Classes.Base.prototype.remove.apply(this,arguments);
	}

});


/**
Use for PAGINATION STUFF --- let's say you load a CollectionView that is for the first 10 news articles.  But then let's say
you have infinite scroll, or a "load more" button --- this class helps deal with the situation where you're going to keep adding
sets of views (i.e. first set of news articles, then another set, then another, etc)
**/
fantasy.present.Classes.MultiCollectionView = fantasy.present.Classes.ContainerView.extend({ 
	className: "multi-collection",
	initialize: function(options)
	{
		fantasy.present.Classes.ContainerView.prototype.initialize.apply(this,arguments);
		this.collections = [];
		if(options)
		{
			if(options.subViewClass)
		 		this.subViewClass = options.subViewClass;
			if(options.collectionViewClass)
		 		this.collectionViewClass = options.collectionViewClass;
		 	else
		 		this.collectionViewClass = fantasy.present.Classes.CollectionView;

		 	if(options.subViewDecorator)
		 		this.subViewDecorator = options.subViewDecorator;
		 	else
		 		this.subViewDecorator = false;
		}

	},
	addCollection: function(collection) { 
		this.collections.push(collection);
		var collectionView = new this.collectionViewClass({emptyEveryRender: false, collection: collection, subViewClass: this.subViewClass, subViewDecorator: this.subViewDecorator});
		this.addView(collectionView);
	},
	removeAllSubs: function() { 
		fantasy.present.Classes.ContainerView.prototype.removeAllSubs.apply(this,arguments);
		this.collections = [];
	},
	hasAnyCollections: function() { 
		if(this.collections && this.collections.length > 0)
			return true;
		return false;

	}
});



