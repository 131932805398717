/**
Abstract router with common functions goes here.

This should be subclassed by the actual router class you want to use. 

 Do not add application specific routes here.

**/

var fantasy = fantasy || {};
fantasy.packs = fantasy.packs || {};

fantasy.utilroute = fantasy.utilroute || {};


fantasy.utilroute.RtopClass = Backbone.Router.extend({

  routes: {
    // "help":                 "help",    // #help
  },
  execute: function(callback, args, name) {


	var route = Backbone.history.getFragment();
	if(!args)
		args = [];
	if(args.length === 1 && args[0] === null)
		args.pop();

	var info = {};

	if(this.routesStack.length >= 2 && !this.page_link_clicked && this.routesStack[this.routesStack.length-2] === route)
	{
		this.backDetected = true;
		this.longerBackDetected = true;
		info.previous_frag = this.routesStack[this.routesStack.length-1];
		this.routesStack.pop();
		info.via_back_button = true;
	}
	else
	{
		info.previous_frag = this.routesStack[this.routesStack.length-1];
		info.via_back_button = false;
	    this.routesStack.push(route);
	}
	args.push(info);

    if (callback) callback.apply(this, args);
    
	this.page_link_clicked = false;
	this.backDetected = false;

  },
	initialize: function(options) {
		var self = this;
		Backbone.Router.prototype.initialize.apply(this,arguments);
		this.on('all', this.routeEventLogger); 
		this.on('route', this.routeActions);
		this.routesStack = [];
		this.page_link_clicked = false;
		this.backDetected = false;
		this.longerBackDetected = false;

		this.base_site_title = "";

		this.div_page_container_id = "body-part";
		this.refresh_current = false;
		$("body").off("click.RtopClass");

		$("body").on("click.RtopClass", ".innerlink", this.interceptInAppLink.bind(this));
		$("body").on("click.RtopClass", ".go_back", this.handleGoBack.bind(this));
		$("body").on("click.RtopClass", ".externallink", this.interceptExternalLink.bind(this));
		this.previousScrollTopPos = 0;

		if(options)
		{
			if(options.hrefInfoBean){
				this.hrefInfoBean = options.hrefInfoBean;
				this.listenTo(this.hrefInfoBean,"navigate_request", this.navigateWrapper );
			}
			if(options.userState)
			{
				this.userState = options.userState;
			}
		}

	},

	routeEventLogger: function(arg) { 
		console.log("ROUTER EVENT", arg);
	},
	/**

	**/
	interceptInAppLink: function(e) { 
		this.page_link_clicked = true;
		console.log("RtopClass interceptInAppLink ", e);
		var curScrollPos = $("#mainContainer").scrollTop();
		this.previousScrollTopPos = curScrollPos;

		// //scroll to top
		// $("#mainContainer").animate({ scrollTop: 0 }, "fast");

		//immediately close nav
		$("#fd-top-nav .navbar-collapse").height(0);
		//close nav proper
        $("#fd-top-nav #navbar").collapse('hide');

			  	e.preventDefault();
			  	e.stopPropagation();
			  	var currentTarget = $(e.currentTarget);
			  	var href = currentTarget.attr("href");
			  	this.navigateWrapper(href);
	},
	interceptExternalLink: function(e) { 

	  	e.preventDefault();
	  	e.stopPropagation();
	  	var currentTarget = $(e.currentTarget);
	  	var href = currentTarget.attr("href");		
	  	//track
	  	console.log("interceptExternalLink", href);
	  	window.location.href = href;
	},
	handleGoBack:  function(e) { 

		//TODO: consider not setting this flag.
		//this.page_link_clicked = true;
		console.log("RtopClass handleGoBack ", e);
			  	e.preventDefault();
			  	e.stopPropagation();
		this.longerBackDetected = true;
		window.history.back();

	},
	getParam: function(field, query) {
		var match = RegExp('[?&]' + field + '=([^&]*)').exec(query);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	},
	navigateWrapper: function(href) { 
		console.log("navigateWrapper ROUTER: navigation:", href);
		this.navigate(href, {trigger: true}); // update browser URL

	},
	removeOldPage: function() { 
		if(this.currentPageView){
			this.stopListening(this.currentPageView);
			if(this.currentPageView.bodyClasses)		
				$("body").removeClass(this.currentPageView.bodyClasses);
			this.currentPageView.remove();		
		}

		$("#" + this.div_page_container_id).empty();
	},
	trackPageView: function(frag_href) {
        var metainfo = { href: frag_href };
	},
	_untrackedPageShow: function(view) { 
		$("#" + this.div_page_container_id).empty().append(view.$el);
		console.log("just did _untrackedPageShow");
	},
	_processTitle: function(pageView, frag) { 

		var title_part = null;
		var page_title = this.base_site_title;

		if(pageView && pageView.page_title){
			title_part = pageView.page_title;
		}
		if(pageView && pageView.getPageTitle){
			title_part = pageView.getPageTitle();
		}		
		if(!title_part) {
			var fragParts = frag.split("/");
			if(fragParts && fragParts.length > 0)
				title_part = fragParts[0];
		}
		title_part = title_part || "";
		if(title_part && title_part.length > 0)
		{
			title_part = title_part.charAt(0).toUpperCase() + title_part.substring(1);
		}
		this.updatePageTitleSecondary(title_part);
	},
	updatePageTitleSecondary: function(suffix) { 
		this._changePageTitle(this.base_site_title + " | " + suffix);
	},
	_changePageTitle: function(new_title){ 

		try {
		    document.getElementsByTagName('title')[0].innerHTML = new_title.replace('<','&lt;').replace('>','&gt;').replace(' & ',' &amp; ');
		}
		catch ( Exception ) { }
		document.title = new_title;

	},
	changePageView: function(pageView) { 
		var self = this;
		var frag = "";
		try {
		if(Backbone && Backbone.history && Backbone.history.getFragment)
			frag = Backbone.history.getFragment();
		}
		catch(err)
		{
			console.error("squashing fragment access err", err);
		}

		if(frag === this.last_page_frag && (!this.refresh_current))
		{
			console.log("New frag equals last page frag, so not changing pages.", frag, this.last_page_frag);
			return;
		}

		this.removeOldPage();
		this.currentPageView = pageView;

		this._processTitle(pageView,frag);

		this.last_page_frag = frag;
		//console.log("last frag", frag);

	//	this.listenTo(pageView, "inner_nav_intent", this.navigateWrapper);
		$("#" + this.div_page_container_id).append(pageView.$el);
		if(pageView && pageView.bodyClasses)
		{
			$("body").addClass(pageView.bodyClasses);
		}
		if(this.hrefInfoBean)
		{
			this.hrefInfoBean.set("currentPageId", pageView.id);
			this.hrefInfoBean.set("route_fragment", frag);
			var url_parts = frag.split("/");
			var first_url_part = url_parts[0] || false;
			var second_url_part = url_parts[1] || false;
			this.hrefInfoBean.set("section", first_url_part);
			this.hrefInfoBean.set("subsection", second_url_part);
			this.hrefInfoBean.trigger("sync");
			//console.log("section", first_url_part,"subsection",second_url_part);
			//var pageNavData = this.hrefInfoBean.get("page_nav_data");
		}



		//$("html, body").animate({ scrollTop: 0 }, "fast");
		pageView.trigger("added_to_dom");

		if(self.longerBackDetected && self.previousScrollTopPos)
		{	
			//console.log("BACK DETECTED -- about to scroll to previous scroll top ",self.previousScrollTopPos);
			$("#mainContainer").scrollTop(self.previousScrollTopPos);
			//disabling animation style:
			//$("#mainContainer").animate({ scrollTop: self.previousScrollTopPos }, "fast");

			// _.delay(function() { 
			// 	console.log("DELAYED about to scroll to previous scroll top ",self.previousScrollTopPos);
			// 	$("#mainContainer").animate({ scrollTop: self.previousScrollTopPos }, "fast");

			// },100);

		}
		else {
			//console.log("no back detected.", self.longerBackDetected, self.previousScrollTopPos);
			$(window).scrollTop(0);	
			$("#mainContainer").scrollTop(0);
			//disabling animation style:
			//$("#mainContainer").animate({ scrollTop: 0 }, "fast");
		}

	    self.refresh_current = false;
        self.trackPageView(frag);
        self.longerBackDetected = false;
	}

});
