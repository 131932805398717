fantasy.packs.Comps = fantasy.packs.Comps || {};



fantasy.packs.Comps.EditConfirmUserHandleForm = fantasy.present.Classes.FormView.extend({ 
	template: fantasy.templates.sub_form_edit_handle,
	events: { 
		"click .reinit": "reinit"
	},
	initialize: function(options)
	{
		fantasy.present.Classes.FormView.prototype.initialize.apply(this,arguments);
		this.original_handle = this.model.get("handle");

		var handleUpdateModel = new fantasy.packs.CompBeans.HandleUpdateModel({user_id: this.model.getId(), handle: this.model.get("handle")});
		this.setContent(handleUpdateModel);
		this.handleCheckModel = new fantasy.packs.CompBeans.HandleCheck();
		this.throttledCheckIfTaken = _.throttle(_.bind(this.checkIfTaken,this),500);
	},
	postRender: function() { 
		this.$el.off("keyup.checkIfAvailable","#handle");
		this.$el.on("keyup.checkIfAvailable","#handle", this.throttledCheckIfTaken);

		this.$el.off("click.checkIfAvailable","#handle");
		this.$el.on("click.checkIfAvailable","#handle", this.throttledCheckIfTaken);

		this.$el.off("keyup.conformHandle","#handle");
		this.$el.on("keyup.conformHandle","#handle", _.bind(this.removeIllegalCharacters,this));

		this.$el.off("click.conformHandle","#handle");
		this.$el.on("click.conformHandle","#handle", _.bind(this.removeIllegalCharacters,this));



	},

	refreshTakenOrNotDisplay: function() { 
		var taken = false;
		var your_original = false;

		if(this.handleCheckModel && !this.handleCheckModel.get("available") && this.handleCheckModel.loaded)
		{
			taken = true;
		}
		if(this.handleCheckModel && this.handleCheckModel.get("handle") === this.original_handle)
		{
			your_original = true;
		}

		if(your_original) 
		{
			this.$el.removeClass("handle-taken");
			this.$el.removeClass("handle-available");
			this.$el.removeClass("handle-loading");	
			this.$el.addClass("handle-original");
			this.$el.find(".submit").attr("disabled", false);
			this.$el.find(".availability-status").html("No Change");
		}
		else if(taken) 
		{
			this.$el.addClass("handle-taken");
			this.$el.removeClass("handle-available");
			this.$el.removeClass("handle-loading");			
			this.$el.removeClass("handle-original");	
			this.$el.find(".submit").attr("disabled", true);
			this.$el.find(".availability-status").html("Not Available");
		}
		else if(this.handleCheckModel && this.handleCheckModel.loaded)
		{
			//def available
			this.$el.addClass("handle-available");
			this.$el.removeClass("handle-taken");
			this.$el.removeClass("handle-loading");			
			this.$el.removeClass("handle-original");	
			this.$el.find(".submit").attr("disabled", false);
			this.$el.find(".availability-status").html("Available!");

		}
		else {
			//still loading
			this.$el.removeClass("handle-taken");		
			this.$el.removeClass("handle-available");			
			this.$el.addClass("handle-loading");			
			this.$el.removeClass("handle-original");
			this.$el.find(".submit").attr("disabled", true);
			this.$el.find(".availability-status").html("Checking Availability...");
		}

	},
	removeIllegalCharacters: function() { 
		
		var curVal = this.$el.find("#handle").val();
		if(curVal === this.last_illegal_check_val)
			return;
		var newVal = fantasy.util.conformHandle(curVal);
		this.$el.find("#handle").val(newVal);
		this.last_illegal_check_val = newVal;

	},
	checkIfTaken: function() { 
		var self = this;
		var curVal = this.$el.find("#handle").val();
		if(curVal === this.handleCheckModel.get("handle"))
			return;

		if(curVal.length < 4 || curVal.length > 16)
		{
			this.handleCheckModel.setId(curVal);
			this.handleCheckModel.set("available", false);
			self.handleCheckModel.set("error", true);
			self.refreshTakenOrNotDisplay();
			return;
		}

		this.handleCheckModel.setId(curVal);
		this.handleCheckModel.loaded = false;
		self.handleCheckModel.set("error", false);
		this.handleCheckModel.fetch().done(function() { 
			self.refreshTakenOrNotDisplay();
		}).fail(function(err) { 
			self.handleCheckModel.set("available", false);
			self.handleCheckModel.set("error", true);
			self.refreshTakenOrNotDisplay();
		});
	}

});


				// 	res.json({handle: handle, available:true});
				// 	return;
				// }

				// res.json({handle: handle, available:false});	