//fantasy.packs.CompBeans.PointJournalCompositesForAthlete
fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};

fantasy.packs.CompBeans.AthleteComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "athlete_id",
     mdl_name: "AthleteComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
    console.log("AthleteComposite", attributes,options);

      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.model)
        this.addModel("athlete_core",options.model);
      else 
        this.addModel("athlete_core",new fantasy.packs.CompBeans.Athlete({athlete_id: this.getId()}));
     if(options && options.round_id)
      this.round_id = options.round_id;
      //TODO baked in sport_id multisport support TODO
      //this.addModel("lifetime_point_sum", new fantasy.packs.CompBeans.LifetimePointSumForAthleteSport({sport_id:1,athlete_id: this.getId()}));

      this.addCollection("all_points",new fantasy.packs.CompBeans.PointJournalCompositesForAthlete(null,{athlete_id: this.getId()}));

      this.addModel("position_core", new fantasy.packs.CompBeans.Position({position_id: this.get("primary_position_id")}),{depth:1});
      this.addModel("real_team_core", new fantasy.packs.CompBeans.RealTeam({real_team_id: this.get("real_team_id")}),{depth:1});

      //TODO multisport support
      this.addCollection("lifetime_stats_row", new fantasy.packs.CompBeans.AthleteLifetimeStatRowAllCats(null,{athlete_id: this.getId(), sport_id: 1}),{depth:1});
      if(this.round_id)
        this.addCollection("this_round_stats_row", new fantasy.packs.CompBeans.AthleteRoundStatRowAllCats(null,{athlete_id: this.getId(), sport_id: 1, round_id: this.round_id}),{depth:1});
    },
        levelPrep: function() { 
      var athleteCore = this.getModel("athlete_core");
      if(athleteCore.loaded)
      {
          this.getModel("position_core").set("position_id",athleteCore.get("primary_position_id"));
          this.getModel("real_team_core").set("real_team_id",athleteCore.get("real_team_id"));

      }

    }

  });

fantasy.packs.CompBeans.AthleteSummaryComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "athlete_id",
     mdl_name: "AthleteSummaryComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.model)
        this.addModel("athlete_core",options.model);
      else 
        this.addModel("athlete_core",new fantasy.packs.CompBeans.Athlete({athlete_id: this.getId()}));

      this.addModel("position_core", new fantasy.packs.CompBeans.Position({position_id: this.get("primary_position_id")}),{depth:1});
      this.addModel("real_team_core", new fantasy.packs.CompBeans.RealTeam({real_team_id: this.get("real_team_id")}),{depth:1});

    },
        levelPrep: function() { 
      var athleteCore = this.getModel("athlete_core");
      if(athleteCore.loaded)
      {
          this.getModel("position_core").set("position_id",athleteCore.get("primary_position_id"));
          this.getModel("real_team_core").set("real_team_id",athleteCore.get("real_team_id"));

      }

    }

  });


fantasy.packs.CompBeans.AthleteThisWeekAndAlltimeComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "athlete_id",
     mdl_name: "AthleteThisWeekAndAlltimeComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.model)
        this.addModel("athlete_core",options.model);
      else 
        this.addModel("athlete_core",new fantasy.packs.CompBeans.Athlete({athlete_id: this.getId()}));
     if(options && options.round_id)
      this.round_id = options.round_id;

       this.addCollection("lifetime_stats_row", new fantasy.packs.CompBeans.AthleteLifetimeStatRowAllCats(null,{athlete_id: this.getId(), sport_id: 1}),{depth:1});
       this.addCollection("this_round_stats_row", new fantasy.packs.CompBeans.AthleteRoundStatRowAllCats(null,{athlete_id: this.getId(), sport_id: 1, round_id: this.round_id}),{depth:1});
      // var model = new fantasy.packs.CompBeans.AthleteThisWeekAndAlltimeComposite({athlete_id: athlete_id, round_id});

    }

  });

fantasy.packs.CompBeans.AthleteWithRoundDualTotalsComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "athlete_id",
    id_fields: ["athlete_id","round_id"],
     mdl_name: "AthleteWithRoundDualTotalsComposite",
    use_cache: true,      
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options && options.model)
        this.addModel("athlete_core",options.model);
      else 
        this.addModel("athlete_core",new fantasy.packs.CompBeans.Athlete({athlete_id: this.getId()}));

      if(options && options.round_id)
        this.round_id = options.round_id;
      this.sport_id = 1;
      if(options && options.sport_id)
        this.sport_id = options.sport_id;      

      console.log("fantasy.packs.CompBeans.AthleteWithRoundDualTotalsComposite init", arguments);

      this.addModel("position_core", new fantasy.packs.CompBeans.Position({position_id: this.get("primary_position_id")}),{depth:1});
      this.addModel("real_team_core", new fantasy.packs.CompBeans.RealTeam({real_team_id: this.get("real_team_id")}),{depth:1});
 this.addCollection("athlete_rounds", new fantasy.packs.CoreCollections.PointRoundsForAthleteRound([],{athlete_id: this.getModel("athlete_core").getId(),
        round_id: this.round_id}),{depth:1});

      this.addCollection("lifetime_stats_row", new fantasy.packs.CompBeans.AthleteLifetimeStatRowAllCats(null,{athlete_id: this.getId(), sport_id: this.sport_id}),{depth:1});
      this.addCollection("this_round_stats_row", new fantasy.packs.CompBeans.AthleteRoundStatRowAllCats(null,{athlete_id: this.getId(), sport_id:  this.sport_id, round_id: this.round_id}),{depth:1});


      
    },
        levelPrep: function() { 
      var athleteCore = this.getModel("athlete_core");
      if(athleteCore.loaded)
      {
          this.getModel("position_core").set("position_id",athleteCore.get("primary_position_id"));
          this.getModel("real_team_core").set("real_team_id",athleteCore.get("real_team_id"));
    this.getCollection("athlete_rounds").set("athlete_id", this.getModel("athlete_core").getId()).
             set("round_id",this.round_id);
         this.getCollection("this_round_stats_row").round_id = this.round_id;
         this.getCollection("this_round_stats_row").sport_id = this.sport_id;
         this.getCollection("this_round_stats_row").athlete_id = this.get("athlete_id");
         this.getCollection("lifetime_stats_row").sport_id = this.sport_id;
         this.getCollection("lifetime_stats_row").athlete_id = this.get("athlete_id");

      }

    }

  });


// fantasy.packs.CoreCollections.AthletesForSport = fantasy.Collections.Classes.Base.extend({
//   model: fantasy.packs.CompBeans.Athlete,
//         mdl_name: "AthletesForSport",
//     use_cache: true,      
//   url: function() { return "/api/sport/" + this.sport_id + "/athletes/";},
//   idAttribute: "sport_id"
// });

// fantasy.packs.CoreCollections.AthletesInLeague = fantasy.Collections.Classes.Base.extend({
//   model: fantasy.packs.CompBeans.Athlete,
//         mdl_name: "AthletesInLeague",
//     use_cache: true,      
//   url: function() { return "/api/league/" + this.league_id + "/athletes_in_league/";},
//   idAttribute: "league_id"
// });



fantasy.packs.CoreCollections.AthletesForSportNotInLeague = fantasy.Collections.Classes.Base.extend({
  model: fantasy.packs.CompBeans.Athlete,
        mdl_name: "AthletesForSport",
    use_cache: true,      
  idAttribute: "league_id",
     initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      if(options)
      {
        this.sport_id = options.sport_id;
        this.league_id = options.league_id;
      }
      this.athletesForSport = new fantasy.packs.CoreCollections.AthletesForSport(null,{sport_id: this.sport_id});
      this.athletesInLeague = new fantasy.packs.CoreCollections.AthletesInLeague(null,{league_id: this.league_id});

    },
    fetch: function(options)
    {
      var self = this;
      var atheletesForSportPromise = this.athletesForSport.fetch();
      var atheletesInLeaguePromise = this.athletesInLeague.fetch();
      return $.when(atheletesForSportPromise,atheletesInLeaguePromise).done(function() {
        console.log("AthletesForSportNotInLeague, in sport: " + self.athletesForSport.length + " and in league: " + self.athletesInLeague.length);
        var inLeagueMapByAthleteId = _.indexBy(self.athletesInLeague.toJSON(),"athlete_id");
        var goodModels = [];
        _.each(self.athletesForSport.models,function(athlete) { 
          if(inLeagueMapByAthleteId[athlete.getId()])
            return;
          goodModels.push(athlete);
        });

        var justUsefulModels = goodModels;     
       // console.log("AthletesForSportNotInLeague, in sport: " + self.athletesForSport.length + " and in league: " + self.athletesInLeague.length + " and in our disunion: " + justUsefulModels.length);

        self.reset(justUsefulModels);
        self.trigger("sync");

      }).fail(function(err) { 
        console.error("trouble loading athletes for sport not in league");
      });

    }
});


fantasy.packs.CoreCollections.AthleteCompositesForPrimaryPosition  = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.AthleteSummaryComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.AthletesForPrimaryPosition,
  idAttribute: "position_id",
  mdl_name: "AthleteCompositesForPrimaryPosition",
    use_cache: true,    
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
    //  console.log("AthleteCompositesForPrimaryPosition post super init",this);
    }
});


fantasy.packs.CoreCollections.AthleteCompositesOnRealTeam  = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.AthleteSummaryComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.AthletesOnRealTeam,
  idAttribute: "real_team_id",
  mdl_name: "AthleteCompositesOnRealTeam",
    use_cache: true,    
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
    //  console.log("AthleteCompositesOnRealTeam post super init",this);
    },
      comparator: function(athleteComposite){
      return athleteComposite.getModel("athlete_core").get("last_name");
  }
});


fantasy.packs.CoreCollections.AthletesOnFTeamWithRoundStats  = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.AthleteWithRoundDualTotalsComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.AthletesOnAFTeam,
  id_fields: ["f_team_id","round_id"],
  mdl_name: "AthleteCompositesOnRealTeam",
    use_cache: true,    
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
    },
      comparator: function(athleteComposite){
      return athleteComposite.getModel("athlete_core").get("last_name");
  }
});

