fantasy.beans = fantasy.beans || {};
fantasy.beans.Classes = fantasy.beans.Classes || {};
fantasy.packs = fantasy.packs || {};
fantasy.packs.Models = fantasy.packs.Models || {};
fantasy.Supers = fantasy.Supers || {};
fantasy.present = fantasy.present  || {};
fantasy.present.Classes = fantasy.present.Classes || {};

fantasy.Collections = fantasy.Collections || {};
fantasy.Collections.Classes = fantasy.Collections.Classes || {};


fantasy.packs.Auth = fantasy.packs.Auth || {};

fantasy.packs.Auth.LoggedInUserInfoModel = fantasy.beans.Classes.Base.extend({
	  idAttribute: "user_id",
	  url: "/api/auth/loggedin",
	 initialize: function(attributes, options) {
	 	console.log("LoggedInUserInfoModel INIT START");
  		fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
  	},
  	isLoggedIn: function() { 
  		return this.get("logged_in");
  	},
  	normalize: function() { 
  		var self = this;
  		if(this.get("groups"))
  		{
  			_.each(this.get("groups"),function(group) { 
  				self.set("is_"+ group, true);
  			});
  		}

  	}
  });


fantasy.packs.Auth.LogoutModel = fantasy.beans.Classes.Base.extend({
	  idAttribute: "user_id",
	  url: "/api/auth/logout"
});



fantasy.packs.Auth.handleFacebookAuth = function(facebook_access_token){
	var deferred = new $.Deferred();
	var url = '/api/auth/facebook_login/' + facebook_access_token;
	$.ajax({
	  type: "POST",
	  url: url,
	  data: {},
	  dataType: "json",
	  contentType: "application/json"
	}).done(function(data, textStatus, jqXHR) {
		console.log("handleFacebookAuth post to our server response. jquery ajax post style.");
		  console.log('handleFacebookAuth INFO: ' + jqXHR.responseText, jqXHR, data);
		
			deferred.resolve(data);

	}).fail(function(jqXHR, textStatus, errorThrown ) { 
		  console.error("** handleFacebookAuth An error occurred during the transaction", jqXHR,errorThrown,textStatus);
		  deferred.reject();
	});
	return deferred.promise();

};

 
fantasy.packs.Auth.handleGoogleAuth = function(googleUser){
	var deferred = new $.Deferred();
	 $(".fd-loading-outer").removeClass("active");    
	 fantasy.router._showLoadingPage();
	 $(".fd-loading-msg").html("Logging in...");	

	console.log("handleGoogleAuth onSignIn START");

  var profile = googleUser.getBasicProfile();
  console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
  console.log('Name: ' + profile.getName());
  console.log('Image URL: ' + profile.getImageUrl());
  console.log('Email: ' + profile.getEmail());

  var id_token = googleUser.getAuthResponse().id_token;
  var url = '/api/googleauth/tokensign/' + id_token;

$.ajax({
  type: "POST",
  url: url,
  data: {},
  dataType: "json",
  contentType: "application/json"
}).done(function(data, textStatus, jqXHR) {
	console.log("post to our server response. jquery ajax post style.");
	  console.log('Signed in as: ' + jqXHR.responseText, jqXHR);
	
		deferred.resolve(jqXHR.responseText);

}).fail(function(jqXHR, textStatus, errorThrown ) { 
	  console.error("** An error occurred during the transaction", jqXHR,errorThrown,textStatus);
	  deferred.reject();
});

	// var xhr = new XMLHttpRequest();
	// xhr.open('POST', url);
	// xhr.setRequestHeader('Content-Type', 'application/json');
	// xhr.onload = function() {
	// 	console.log("post to our server response.");
	// 	deferred.resolve(xhr.responseText);
	//   console.log('Signed in as: ' + xhr.responseText, xhr);
	// };

	// xhr.onerror = function () {
	//   console.log("** An error occurred during the transaction", xhr);
	//   deferred.reject();

	// };

	// xhr.send('{}');

	return deferred.promise();
};


fantasy.packs.Auth.googleLogout = function() { 
 var auth2 = gapi.auth2.getAuthInstance();
  
  return  auth2.signOut().then(function () {
      console.log('User signed out.');
    });
};

fantasy.packs.Auth.GenericLoginView =  fantasy.present.Classes.Base.extend({ 
	className: "generic-login-chooser",
	template: fantasy.templates.login_type_chooser,
		initialize: function(options)
	{
		console.log("GenericLoginView init");
		fantasy.present.Classes.Base.prototype.initialize.apply(this, arguments);
		this.render();
	}
});



fantasy.packs.Auth.GoogleLoginButtonView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.google_login_button_view,
		initialize: function(options)
	{
		console.log("GoogleLoginButtonView INIT Start");
		fantasy.present.Classes.Base.prototype.initialize.apply(this, arguments);
		if(options && options.invited)
		{
			var local_storage_item = 		 JSON.parse( localStorage.getItem( 'invite_to_accept' ) ) ;
			this.setContent({invited:true, invite_info: local_storage_item});
		}

		this.render();
	},
	postRender: function() { 
		console.log("GoogleLoginButtonView postRender Start");


		var self = this;
		if(!window.googleUser)
			window.googleUser = {};
		if(!window.gapi)
		{
			console.log("GoogleLoginButtonView about to... insertGooglePlatformScript");
			self.insertGooglePlatformScript();
		}
		else
		{
			console.log("GoogleLoginButtonView gapi already loaded. Going to call initGoogleButton");

			self.initGoogleButton();
		}
	},
	insertGooglePlatformScript: function() { 
		var self = this;
		console.log("insertGooglePlatformScript initGoogleButton Start");

		window.callback_delta = function() { 
			_.bind(self.initGoogleButton,self)();
			delete window.callback_delta;
		};

	  	var po = document.createElement('script');
	  	po.type = 'text/javascript';
	  	po.async = true;
	   	po.defer = true;
	 	
	    po.src = 'https://apis.google.com/js/platform.js?onload=callback_delta';
	    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);

	},
	/**
		never called directly, it's wrapped in a window function whose name is passed to the platform.js load.
		@see insertGooglePlatformScript
	**/
	initGoogleButton: function() { 
		console.log("GoogleLoginButtonView initGoogleButton Start");

		var self = this;
	 gapi.load('auth2', function(){
	 	if(window && typeof window.auth2 !== "undefined")
	 	{
	 		console.log("we already have auth2 object global scope.");
	 	}
	      // Retrieve the singleton for the GoogleAuth library and set up the client.
	      var auth2 = gapi.auth2.init({
	        client_id: '386065653622-kq6ga83mbruk1ls6il4082otlikal4r2.apps.googleusercontent.com',
	        cookiepolicy: 'single_host_origin',
	        // Request scopes in addition to 'profile' and 'email'
	        //scope: 'additional_scope'
	      });

	      auth2.then(function() { 
	      	var googleAuth = gapi.auth2.getAuthInstance();
	      	if(googleAuth.isSignedIn.get())
	      	{
	      		console.log("signed into google");
	      		var googleUser = googleAuth.currentUser.get();
	      		if(googleUser){
	      			console.log("signed into google, email: ", googleUser.getBasicProfile().getEmail());
	      		}
	      		 $(".fd-loading-outer").removeClass("active");    
	      		 fantasy.router._showLoadingPage();
	      		 $(".fd-loading-msg").html("Logging in...");
	      	}
 	       self.renderGoogleButton();

	      });
	      // attachSignin(document.getElementById('customBtn'));
	    });
	},
	handleSuccess: function(googleUser) {
		console.log("GoogleLoginButtonView handleSuccess called.");
		var self = this;
		fantasy.packs.Auth.handleGoogleAuth(googleUser).done(function(text) { 
			console.log("GoogleLoginButtonView handleSuccess callback successful google auth login, triggering logged_in on self.",self);
			self.trigger("logged_in");
		}).fail(function(err) { 
     		$(".fd-loading-outer").removeClass("active");    
      		$(".fd-loading-msg").html("Loading...");

			//TODO LET SERVER KNOW ABOUT ERROR
			self.showError("There has been an error with our system, we apologize.  Please contact us to let us know about the problem and or try again soon.");
			console.error("GoogleLoginButtonView handleSuccess callback failure There has been an error!!!",err);
		});
	},
	showError: function(error_message) { 
		this.$el.find(".error-message-area").html("<h3>Error</h3><p class=\"fd-error-msg\">" + error_message + "</p>");
	},
	handleError: function(err) { 
		console.error("GoogleLoginButtonView There has been a problem with the login.");
	},
	/**
		should happen AFTER it has been initialized.  Called by initGoogleButton's success callback.
	**/
	renderGoogleButton: function() { 
				console.log("GoogleLoginButtonView renderGoogleButton called.");

		var self = this;
	      gapi.signin2.render('my-signin2', {
	        'scope': 'profile email',
	        'width': 240,
	        'height': 50,
	        'longtitle': true,
	        'theme': 'dark',
	        'onsuccess': _.bind(self.handleSuccess,self),
	        'onfailure': _.bind(self.handleError,self),
	      });

	},

});




fantasy.packs.Auth.LogoutView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.logout_view,
		initialize: function(options)
	{
		var self = this;
		console.log("LogoutView INIT Start");
		fantasy.present.Classes.Base.prototype.initialize.apply(this, arguments);
  		 $(".fd-loading-outer").removeClass("active");    
  		 fantasy.router._showLoadingPage();
  		 $(".fd-loading-msg").html("Logging out...");

  		 this.logoutGoogleDeferred = new $.Deferred();
 		 this.logoutFacebookDeferred = new $.Deferred();
 		 this.logoutFantasyDictatorDeferred = new $.Deferred();

 		 this.masterPromise = $.when(this.logoutGoogleDeferred, this.logoutFacebookDeferred,this.logoutFacebookDeferred);
 		 this.masterPromise.done(function() { 
 		 	self.trigger("logged_out");
 		 }).fail(function(err) { 
 		 	console.error("Trouble logging out", err);
 		 });

 		this.render();
		 
	},
	postRender: function() { 
		console.log("LogoutView postRender Start");
		var self = this;
		if(!window.googleUser)
			window.googleUser = {};
		if(!window.gapi)
		{
			console.log("about to... insert google platform");
			self.insertGooglePlatformScript();
		}
		else
		{
			console.log("gapi already loaded.");			
			self.googlePlatformLoaded();
		}

		if(window.FB)
		{
	        self.logoutFacebook(function() { 
	        	console.log("facebook signed out confirmed via already loaded FB obj");
	        });
		}
		else
		{
			self.insertFacebookScripts();
		}
	},
	insertGooglePlatformScript: function() { 
		var self = this;
		console.log("LogoutView insertGooglePlatformScript initGoogleButton Start");

		window.callback_delta = function() { 
			_.bind(self.googlePlatformLoaded,self)();
		};

	  	var po = document.createElement('script');
	  	po.type = 'text/javascript';
	  	po.async = true;
	   	po.defer = true;
	 	
	    po.src = 'https://apis.google.com/js/platform.js?onload=callback_delta';
	    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);

	},
	/**
		never called directly, it's wrapped in a window function whose name is passed to the platform.js load.
		@see insertGooglePlatformScript
	**/
	googlePlatformLoaded: function() { 
		console.log("LogoutView googlePlatformLoaded Start");

		var fdLogoutModel = new fantasy.packs.Auth.LogoutModel();
		fdLogoutModel.setId("0");
		var fdLogoutPromise = fdLogoutModel.destroy();
		if(fdLogoutPromise)
		{
			fdLogoutPromise.done(function() { 
				console.log("logged out of FD");
			});
		}
		else
		{
			fdLogoutPromise = new $.Deferred().resolve().promise();
		}
		fdLogoutPromise.always(function() { 
      		self.logoutFantasyDictatorDeferred.resolve();
		});

		var self = this;
	 	gapi.load('auth2', function(){

	      // Retrieve the singleton for the GoogleAuth library and set up the client.
	   var  localauth2 = gapi.auth2.init({
	        client_id: '386065653622-kq6ga83mbruk1ls6il4082otlikal4r2.apps.googleusercontent.com',
	        cookiepolicy: 'single_host_origin',
	        // Request scopes in addition to 'profile' and 'email'
	        //scope: 'additional_scope'
	      });

	      localauth2.then(function() { 
	      	var googleAuth = gapi.auth2.getAuthInstance();
	      	if(googleAuth.isSignedIn.get())
	      	{

	      		console.log("LogoutView, pre-work, yes, user is logged into google");
	      		var googleUser = googleAuth.currentUser.get();
	      		if(googleUser){
	      			console.log("LogoutView about to log out the user signed into google, email: ", googleUser.getBasicProfile().getEmail());
	      		}
	      		googleAuth.signOut().then(function() { 
	      			console.log("LogoutView logged out of google");

	      			fdLogoutPromise.done(function() { 
	      				console.log("Logged out of Both FD and Google");
	      				self.logoutGoogleDeferred.resolve();

	      			}).fail(function(err) { 
	      				console.error("trouble logging out of FD", arguments);
	      				self.logoutGoogleDeferred.reject(err);

	      			});
	      		});

	      	} else {
	      		console.log("LogoutView wasn't even logged into google.");
	      		self.logoutGoogleDeferred.resolve();

	      	}
	      });
	    });
	},
	insertFacebookScripts: function() { 
	      console.log("insertFacebookScripts Start");

	    var self = this;

	    window.fbAsyncInit = function() {
	      console.log("insertFacebookScripts callback fbAsyncInit Start");

	        FB.init({
	          appId      : '1598948693454320',
	          cookie     : true,  // enable cookies to allow the server to access 
	                              // the session
	          xfbml      : true,  // parse social plugins on this page
	          version    : 'v2.8' // use graph api version 2.8
	        });

	        // Now that we've initialized the JavaScript SDK, we call 
	        // FB.getLoginStatus().  This function gets the state of the
	        // person visiting this page and can return one of three states to
	        // the callback you provide.  They can be:
	        //
	        // 1. Logged into your app ('connected')
	        // 2. Logged into Facebook, but not your app ('not_authorized')
	        // 3. Not logged into Facebook and can't tell if they are logged into
	        //    your app or not.
	        //
	        // These three cases are handled in the callback function.

	        self.logoutFacebook(function() { 
	        	console.log("facebook signed out confirmed via script load callback.");
	        });         


	      };

	    // Load the SDK asynchronously
	    (function(d, s, id) {
	      var js, fjs = d.getElementsByTagName(s)[0];
	      if (d.getElementById(id)) return;
	      js = d.createElement(s); js.id = id;
	      js.src = "//connect.facebook.net/en_US/sdk.js";
	      fjs.parentNode.insertBefore(js, fjs);
	    }(document, 'script', 'facebook-jssdk'));

	},
	logoutFacebook: function(callback) {
		console.log("logoutFacebook called");
		var self = this;
		//checks login status
	        FB.getLoginStatus(function(response) {
	         console.log("fb getLoginStatus for logout", response);
	         if(response!== "connected")
	         {
	         	console.log("user isn't even logged into FB. Either not logged into facebook or not currently authing this app.");
	         	if(callback)
	         		callback();
	         	console.log("about to resolve facebook deferred, not even logged in self: ", self);
      	      	self.logoutFacebookDeferred.resolve();

	         }
	         else 
	         {
			  FB.logout(function(response) {
				      console.log("FacebookLoginView logoutOfFacebook called back, signed out of facebook.", response);
				       // Person is now logged out
				       if(callback)
					       callback();
			         	console.log("about to resolve facebook deferred, was logged into FB,  self: ", self);
	      	      		self.logoutFacebookDeferred.resolve();
				    });
	         }
	        });
	},
	handleSuccess: function(googleUser) {
	
	},
	showError: function(error_message) { 
		this.$el.find(".error-message-area").html("<h3>Error</h3><p class=\"fd-error-msg\">" + error_message + "</p>");
	},
	handleError: function(err) { 
		console.error("LogoutView There has been a problem with the login.");
	},


});


