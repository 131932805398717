fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PagePointJournal = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-point-journal",
	bodyClasses: "fdbc-point-journal",
	template: fantasy.templates.page_point_journal,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
	    this.point_journal_id = options.point_journal_id;
	    this.model = new fantasy.packs.CompBeans.PointJournalFullComposite({point_journal_id: this.point_journal_id});
	    this.setContent(this.model);
	     var promise = fantasy.cstore.cstore.getOrFetchItem(this.model,600);
      promise.done(function(arg) { 

      }).fail(function(err) { 
      	console.error("trouble loading point journal full composite", arguments);
      });


		// this.addView(new fantasy.packs.UXWidgets.ImpactStatement({model: {statement: "Bring your Fantasy to life with Fantasy Dictator"}}),{fineWithAppend:true});
		// this.addView(threeUpAlpha.render(),{fineWithAppend:true});

		this.render();
	}
});


fantasy.packs.Comps.PagePointJournals = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-point-journals",
	bodyClasses: "fdbc-point-journals",
	template: fantasy.templates.page_point_journals,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
	
		var self = this;
		var pointJournalModels = new fantasy.packs.CompBeans.PointJournalCollectionRecentFull([],{sport_id:this.sport_id || 1});
		pointJournalModels.fetch().done(function() { 

		});

		this.setContent({});

	  	var promise = fantasy.cstore.cstore.getOrFetchItem(pointJournalModels,600);

      promise.done(function(arg) { 
      	self.setContent(arg);
			var pointListView = new fantasy.packs.Comps.PointJournalFullListView({collection: arg });
			self.addView(pointListView,{selector: ".fd-dz-recent-points"});

      	self.render();

      }).fail(function(err) { 
      	console.error("trouble loading point journal full composite", arguments);
      });


	// this.addView(threeUpAlpha.render(),{fineWithAppend:true});

		this.render();
	}
});



fantasy.packs.Comps.PagePointCategories = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-point-categories",
	bodyClasses: "fdbc-point-categories",
	template: fantasy.templates.page_point_categories,
	initialize: function(options) {
		console.log("PagePointCategories init");
		var self = this;
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
	    this.collection = new fantasy.packs.CoreCollections.PointCategoryAll();
	    this.setContent(this.collection);
	     var promise = fantasy.cstore.cstore.getOrFetchItem(this.collection,600);
      promise.done(function(arg) { 
	    self.setContent(arg);
	    self.render();
	    console.log("rendered PagePointCategories in callback ");

      }).fail(function(err) { 
      	console.error("trouble loading point categories", arguments);
      });

		this.render();
	},
	postRender: function() { 
	    console.log("rendered PagePointCategories in callback ");
	}
});


