fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageStandings = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-standings",
	template: fantasy.templates.page_standings,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.setContent({});
		this.render();
	}
});