fantasy.packs = fantasy.packs || {};
fantasy.packs.State = fantasy.packs.State || {};

/**

**/
fantasy.packs.State.FriendsOnlineManager = fantasy.beans.Classes.CompositeModel.extend({
    use_cache: false,     
   initialize: function(attributes, options) {
      	fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      	this.PING_INTERVAL = 20000;

      	this.REFRESH_INTERVAL = 10000;
      	this.OLDEST_ALLOWABLE_QTY = 30;
      	this.OLDEST_ALLOWABLE_UNIT = "seconds";

   		this.messageHistory = [];
   		
   		//key: user_id, value: message
   		this.lastMessageFromUserMap = {};

   		//key: league_id, value = interval handle
   		this.leaguesPingingMap = {};

         if(!isBot){
            //PERIODICALLY CHEK OUR MESSAGE HISTORY AND CALCULATE WHO IS ONLINE
            this.ourPeriodicCheckerIntervalHandle = setInterval(_.bind(this.calculateWhoisOnline,this),this.REFRESH_INTERVAL);

            //LISTEN FOR MESSAGES FROM SERVER
            this.listenTo(fantasy.socket.socketManager,"user_online", _.bind(this.receiveOnlineMessage,this));

         }

   },
   stopAllAndClear: function() { 
   		_.each(_.values(this.leaguesPingingMap), function(interval_handle) { 
   			clearInterval(interval_handle);
   		});

   	this.leaguesPingingMap = {};
		this.messageHistory = [];
		this.lastMessageFromUserMap = {};
		this.stopListening(fantasy.socket.socketManager,"user_online");
   	this.listenTo(fantasy.socket.socketManager,"user_online", _.bind(this.receiveOnlineMessage,this));
   	console.log("stopAllAndClear complete");
   },
   setupPinger: function(league_id) { 
      if(isBot)
         return;
      
   		var self = this;
   		if(typeof this.leaguesPingingMap[league_id] !== "undefined")
   		{
   			console.log("already setup to ping " + league_id + " so not setting up new pinger");
   			return;
   		}

         self.ping(league_id);

   		var intervalHandle = setInterval(_.bind(function() { 
   			self.ping(league_id);
   		}, self), this.PING_INTERVAL);

   		this.leaguesPingingMap[league_id] = intervalHandle;
         console.log("SEtup pinger to ", league_id);
   },
   ping: function(league_id) { 
   	   	console.log("ping", league_id);
   		if(fantasy.userState.passiveIsLoggedIn() && !isBot)
			fantasy.socket.socketManager.throttledTellTeamImOnline(league_id,fantasy.userState.getLoggedInUserId(),fantasy.userState.getUserComposite().getModel("user_core").get("handle"));
		else if(!isBot)
		{
			console.error("trying to ping but not online anymore");
		}
	},
   receiveOnlineMessage: function(data){ 
   	console.log("receiveOnlineMessage", data);
      if(!data.payload)
      {
         console.error("bad online received message", data);
         return;
      }

   	 var message = data;
   	 message.receipt_mom = moment.utc();
   	 this.messageHistory.push(message);
       if(!this.lastMessageFromUserMap[data.payload.user_id])
       {
         console.log("novel message, going to ping out sooner to let them know we're online");
         if(message.league_id)
            this.ping(message.league_id);
       }
   	 this.lastMessageFromUserMap[data.payload.user_id] = message;

      if(data.payload.user_id === fantasy.userState.getLoggedInUserId())
      {
         console.log("skipping re-calc info about ourself", data.payload.user_id);
         return;
      }

   	 this.calculateWhoisOnline();

   },
   calculateWhoisOnline: function() {
   	console.log("calculateWhoisOnline");
      var oldActiveMessages = this.get("activeMessages");
      var oldOnlineList = this.get("online_list");
   	 var activeMessages = {};
       var self = this;
   	 var nowMom = moment.utc();
   	 
   	 var oldestAllowableMoment = nowMom.clone().subtract(this.OLDEST_ALLOWABLE_QTY,this.OLDEST_ALLOWABLE_UNIT);

   	 _.each(_.values(this.lastMessageFromUserMap), function(lastMessage) { 
         if(!lastMessage || !lastMessage.payload || !lastMessage.payload.user_id)
         {
            console.error("message without payload or user id in calculating who is online, skipping.", lastMessage);
            return;
         }

   	 	if(lastMessage.receipt_mom.isAfter(oldestAllowableMoment))
   	 	{
   	 		activeMessages[lastMessage.payload.user_id] = lastMessage;
   	 	} else
   	 	{
   	 		//delete it from last message so we don't have to process it later
   	 		console.log("Departing from online status", lastMessage);
            if(self.lastMessageFromUserMap && self.lastMessageFromUserMap[lastMessage.payload.user_id])
     	 		   delete self.lastMessageFromUserMap[lastMessage.payload.user_id];
   	 	}
   	 });
       var arrayOfPayloads = _.pluck(_.values(activeMessages),"payload") || [];
   	 var arrayOfUserIds = _.pluck(arrayOfPayloads,"user_id") || [];
       if(typeof oldActiveMessages !== "undefined" && typeof oldOnlineList !== "undefined")
       {
         if(_.isEqual(oldActiveMessages,activeMessages ) && _.difference(arrayOfUserIds,oldOnlineList).length === 0 && _.difference(oldOnlineList, arrayOfUserIds).length === 0)
            return;
       }
       console.log("end of calculateWhoisOnline, online: ", arrayOfUserIds);      
   	 this.set("activeMessages", activeMessages);
       this.set("online_list", arrayOfUserIds);
   	 this.trigger("sync");
   }

});
