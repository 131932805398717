

fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageEditConfirmUserHandle = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-user-handle",
	template: fantasy.templates.page_edit_confirm_user_handle,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		var view = new fantasy.packs.Comps.EditConfirmUserHandleForm({model:this.model});

		this.listenTo(view,"successful_submission", function() { 

			fantasy.router._showLoadingPage();
			
			fantasy.userState.reloadCurrentUserInfo().done(function() { 
				fantasy.router.navigateWrapper("/home/");
			}).fail(function() { 
				console.error("trouble reloading current user info", arguments);

			});
		});

		this.addView(view,{selector: ".fd-dz-form"});
		this.render();
	}
});
