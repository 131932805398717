
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageHomeView = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_home,
	bodyClasses: "fdbc-home fdbc-full-body-width",
	events: {"click .action-start-league" : "startLeagueModalLaunch"},
	initialize: function(options) {
		var self = this;
		if(options)
		{
			this.sport_id = options.sport_id;
			this.season_id = options.season_id;			
		}
		
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.userComposite = options.userComposite;
		this.setContent(this.userComposite);

		this.addView(new fantasy.packs.UXWidgets.ImpactStatement({model: {statement: "Fantasy Dictator<br/>Where America's Fantasy Comes to Life"}}),{selector: ".fd-dz-home-mast"});

		this.addView(new fantasy.packs.Comps.LeagueInvitesListView({collection: this.userComposite.getCollection("inbound_invites")}), {selector: ".fd-dz-invites"});

		//fantasy.packs.CompBeans.LeagueTeasersForUser
		//var userLeaguesCurrentSeason = new fantasy.packs.CompBeans.LeagueTeasersForUserSeason([],{user_id: fantasy.userState.getLoggedInUserId(), season_id: this.season_id });

		var userLeaguesCurrentSeason = new fantasy.packs.CompBeans.LeagueTeasersForUser([],{user_id: fantasy.userState.getLoggedInUserId() });
		//this.addView(new fantasy.packs.Comps.LeaguesYoursView({collection: this.userComposite.getCollection("leagues") }),{selector: ".fd-dz-leagues"});
		this.addView(new fantasy.packs.Comps.LeagueTeasersYoursView({collection: userLeaguesCurrentSeason }),{selector: ".fd-dz-leagues"});
		userLeaguesCurrentSeason.fetch();


		var pointJournalModels = new fantasy.packs.CompBeans.PointJournalCollectionRecentFull([],{sport_id:this.sport_id || 1});
		pointJournalModels.fetch().done(function() { 
			var pointListView = new fantasy.packs.Comps.PointJournalFullListView({collection: pointJournalModels });
			self.addView(pointListView,{selector: ".fd-dz-recent-points"});
		});

		//window.pointJournalModels = pointJournalModels;
		this.render();

	},
	startLeagueModalLaunch: function(e) {
		console.log("startLeagueModalLaunch start");
		var self = this;
		e.preventDefault();
        e.stopPropagation();
        this.$el.find(".action-start-league").attr("disabled", true).html("Starting...");

		var league = new fantasy.packs.CompBeans.League({sport_id: this.sport_id, season_id: this.season_id});
		this.createLeagueFormView = new fantasy.packs.Comps.EditCreateLeagueNameView({league:league });
		this.listenToOnce(this.createLeagueFormView,"form-view-done", function() { 
			this.createLeagueFormView.remove();	
	        this.$el.find(".action-start-league").attr("disabled", false).html("Start a League");

		});
		this.createLeagueFormView.render();
		fantasy.router.modalManager.wrapAndLaunchModal(this.createLeagueFormView,{triggerBackOnSubviewRemoval: false});	
				console.log("startLeagueModalLaunch just launched modal with league, ", league, this.createLeagueFormView);

	}
});