fantasy.packs.Comps = fantasy.packs.Comps || {};


/**

expects a model option that is a leagueComposite, already loaded

**/
fantasy.packs.Comps.LeagueWeekTabs = fantasy.present.Classes.AssignableContainerView.extend({
	className: "league-week-tabs",
	template: fantasy.templates.sub_structure_league_week_tabs,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		var self = this;

		this.round_id = options.round_id || null;
		this.sport_id = this.model.getModel("league_core").get("sport_id");
		
		this.model.set("current_round_id", this.round_id);
		self.addView(new fantasy.packs.Comps.SeasonMastView({model: self.model.getModel("season_core")}),{selector: ".fd-dz-season"});
	
		if(this.round_id)
			this.addView(new fantasy.packs.Comps.LeagueWeekTab({model: this.model,round_id: this.round_id, user_id: fantasy.userState.getLoggedInUserId() }),{selector: ".fd-dz-meat"});
		else{
			//				self.trigger("started_draft");
			var leagueOverview = new fantasy.packs.Comps.PageLeagueYouAreOnView({leagueComposite: this.model});
			self.listenTo(leagueOverview,"started_draft", function() { 
				self.render();
			});
			this.addView(leagueOverview,{selector: ".fd-dz-meat"});
		}
		this.addView(new fantasy.packs.Comps.LeagueMastView({model: this.model}),{selector:".fd-dz-league"});


		this.render();
	}
});


fantasy.packs.Comps.LeagueWeekTab = fantasy.present.Classes.AssignableContainerView.extend({
className: "league-week-tab",
	template: fantasy.templates.sub_structure_league_week_tab,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		if(options && options.round_id)
			this.round_id = options.round_id;
		if(options && options.user_id)
			this.user_id = options.user_id;
		var self = this;

		this.richRoundComposite = new fantasy.packs.CompBeans.RichRoundComposite({round_id: this.round_id},{leagueComposite: this.model, user_id: this.user_id});
		this.setContent(self.richRoundComposite);
		this.render();
		window.richRoundComposite = this.richRoundComposite;

		this.richRoundComposite.fetch().done(function() { 
			self.your_game_composite = self.richRoundComposite.getModel("your_game_composite");

			fantasy.router.updatePageTitleSecondary(self.richRoundComposite.getModel("league_composite").getModel("league_core").get("name") + " - " + self.richRoundComposite.getModel("round_core").get("name"));
			//self.addView(new fantasy.packs.Comps.RoundMastView({model: self.richRoundComposite.getModel("round_core")}),{selector: ".fd-dz-round"});
			
			self.addView(new fantasy.packs.Comps.GamesInRoundListView({collection: self.richRoundComposite.getCollection("game_composites") }),{selector: ".fd-dz-games"});


			self.yourLineupView = new fantasy.packs.Comps.AthletesInMyStartingLineupForTabView({collection: self.richRoundComposite.getModel("your_game_composite").getCollection("your_team_lineup")});
			self.theirLineupView = new fantasy.packs.Comps.AthletesInTheirStartingLineupListView({collection: self.richRoundComposite.getModel("your_game_composite").getCollection("their_team_lineup")});
			self.yourBenchView = new fantasy.packs.Comps.AthletesInYourFTeamViewPickable({collection: self.richRoundComposite.getModel("your_game_composite").getYourLeftOverRoster()});

			self.listenTo(self.yourBenchView,"add_to_lineup",self.handleAddToLineup);
			self.listenTo(self.yourLineupView,"remove_from_lineup",self.handleRemoveFromLineup);

			self.addView(new fantasy.packs.Comps.FGameAthleteStats({model: self.richRoundComposite.getModel("your_game_composite") }), {selector: ".fd-dz-athlete-stats"});

			self.roundView = new fantasy.packs.Comps.RoundMastView({model: self.richRoundComposite.getModel("your_game_composite").getModel("round_core")});
			self.addView(self.roundView, {selector: ".fd-dz-round-info"});

			//self.leagueView = new fantasy.packs.Comps.LeagueCoreMastView({model: self.richRoundComposite.getModel("your_game_composite").getModel("league_core")});
			//self.addView(self.leagueView, {selector: ".fd-dz-league-info"});

			self.addView(self.yourLineupView, {selector: ".fd-dz-my-starters"});
			self.addView(self.theirLineupView, {selector: ".fd-dz-their-starters"});
			self.addView(self.yourBenchView, {selector: ".fd-dz-my-bench"});
			self.sportCore = self.richRoundComposite.getModel("your_game_composite").getModel("sport_core");

			//fd-dz-games

			self.render();

		}).fail(function(err) {
			console.error("trouble fetching RichRoundComposite");
		});


},//end initialize
postRender: function() { 
	if(this.yourLineupView)
	{
		this.yourLineupView.setFixedWidthByCalculatingChildrenWidth(0);
	}
	if(this.yourBenchView)
	{
		this.yourBenchView.setFixedWidthByCalculatingChildrenWidth(0);
	}	
	if(this.theirLineupView)
	{
		this.theirLineupView.setFixedWidthByCalculatingChildrenWidth(0);
	}	
	this.checkForFullness();
	this.checkForRoundLocked();
},
/**
now takes a fantasy.packs.CompBeans.AthleteWithRoundDualTotalsComposite  as argument
**/
handleAddToLineup: function(athleteWithRoundDualTotalsComposite) { 

	console.log("handleAddToLineup tab view handler");
		var self = this;
		self.$el.addClass("processing");
		self.richRoundComposite.set("processing", true);
		self.max_lineup_size = self.your_game_composite.getModel("sport_core").get("f_team_starters");  //self.sportCore.get("f_team_starters");

		if(self.your_game_composite.getCollection("your_team_lineup").length >= self.max_lineup_size-1)
		{
			self.$el.addClass("lineup-full");
			self.$el.removeClass("lineup-not-full");

		}
		else
		{
			self.$el.removeClass("lineup-full");
			self.$el.addClass("lineup-not-full");
		}

		if(self.your_game_composite.getCollection("your_team_lineup").length >= self.max_lineup_size)
		{
			self.$el.removeClass("processing");
			self.richRoundComposite.set("processing", false);

			console.error("Attempt to add more players to roster than allowed!");
			return;
		}

		console.log("Still have room to add more folks: self.max_lineup_size, lineup length ",self.max_lineup_size,self.your_game_composite.getCollection("your_team_lineup").length );
		var teamStarter = new fantasy.packs.CompBeans.TeamStarterRoundRel({
			name: "Starter",
			f_team_id: self.your_game_composite.your_f_team_id,
			athlete_id: athleteWithRoundDualTotalsComposite.getId(),
			position_id: athleteWithRoundDualTotalsComposite.getModel("athlete_core").get("primary_position_id"),
			round_id: self.your_game_composite.getModel("f_game_core").get("round_id"),
			f_game_id: self.your_game_composite.getId(),
		});
		teamStarter.save().done(function() { 
			self.refreshLists();
			self.checkForFullness();
			self.$el.removeClass("processing");
			self.richRoundComposite.set("processing", false);

		}).fail(function(err) { 
			console.error("trouble adding team starter", arguments);
			self.refreshLists();
			self.checkForFullness();
			self.$el.removeClass("processing");
			self.richRoundComposite.set("processing", false);

		});

	},
	checkForRoundLocked: function() { 
		var self = this;
		if(!this.richRoundComposite)
			return;

		var startInFuture = this.richRoundComposite.getModel("round_core").isStartInFuture();
		if(startInFuture)
		{
			self.$el.removeClass("round_started");
			self.$el.addClass("round_starts_in_future");
		}
		else 
		{
			self.$el.removeClass("round_starts_in_future");
			self.$el.addClass("round_started");
		}
	
	},
	checkForFullness: function() { 
		var self = this;
		if(!self.your_game_composite)
			return;
		if(!self.your_game_composite.getModel("sport_core"))
			return;
		if(!self.your_game_composite.getCollection("your_team_lineup"))
			return;

		self.max_lineup_size = self.your_game_composite.getModel("sport_core").get("f_team_starters");
		console.log("lineup size, max_lineup_size",self.your_game_composite.getCollection("your_team_lineup").length, self.max_lineup_size);
		if(self.your_game_composite.getCollection("your_team_lineup").length >= self.max_lineup_size)
		{
			self.$el.addClass("lineup-full");
			self.$el.removeClass("lineup-not-full");

		}
		else
		{
			self.$el.removeClass("lineup-full");
			self.$el.addClass("lineup-not-full");
		}
	},
	refreshLists: function() { 
		var self = this;
		return self.your_game_composite.getCollection("your_team_lineup").fetch().done(function() { 
			self.your_game_composite.calculateAvailableRoster();
			self.checkForFullness();
			self.checkForRoundLocked();
		});
	},
	handleRemoveFromLineup: function(teamStarterRoundRelComposite) {
		var self = this;

		this.$el.addClass("processing");
			self.richRoundComposite.set("processing", true);

		teamStarterRoundRelComposite.destroy().done(function() { 
			self.refreshLists();
			self.$el.removeClass("processing");
			self.richRoundComposite.set("processing", false);

		}).fail(function(err) { 
			console.error("trouble removing someone from the starting lineup", arguments);
			self.refreshLists();
			self.$el.removeClass("processing");
			self.richRoundComposite.set("processing", false);
		});

	},
	remove: function() { 
		fantasy.present.Classes.AssignableContainerView.prototype.remove.apply(this,arguments);
	}

});


   // this.addModel("league_composite", this.leagueComposite);
   //      this.set("league_id", this.leagueComposite.getId());
   //      this.user_id = options.user_id;

   //      this.addModel("round_core",new fantasy.packs.CompBeans.Round( {round_id: this.getId()}));
   //      console.log("RoundForLeagueComposite", this);
   //      var games = new fantasy.packs.CoreCollections.GamesForLeagueAndRound(null,{league_id: this.get("league_id"), round_id: this.getId()});
   //      this.addCollection("games_simple", games, {depth:0});
    
   //      var gameComposites = new fantasy.packs.CoreCollections.GameCompositesForLeagueAndRound (null,{leagueComposite: this.leagueComposite, round_id: this.getId(), league_id: this.get("league_id")});
   //      this.addCollection("game_composites", gameComposites, {depth:0});

   //      var yourFantasyTeamCompositeCollection = new fantasy.packs.CompBeans.SingleUserFantasyTeamCompositeCollection([],{user_id: this.user_id, league_id: this.leagueComposite.get("league_id")});
   //      this.addModel("your_fantasy_team_composite_col", yourFantasyTeamCompositeCollection,{depth:0});

   //      var yourGameComposite = new fantasy.packs.CompBeans.YourGameComposite({f_game_id: f_game_id},{f_game_id: f_game_id,your_f_team_id:your_f_team_id });
   //      this.addModel("your_game_composite", yourFantasyTeamComposite, {depth: 1});

