fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageAbout = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-about",
	bodyClasses: "fdbc-about fdbc-full-body-width",
	template: fantasy.templates.page_about,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.setContent({});
		// var threeUpAlpha = new fantasy.packs.UXWidgets.ThreeUp({model:{items:[
		// 	{
		// 		image_url: "",
		// 		sub_head: "Make a league",
		// 		blurb: "Anyone can make a league, or ask a friend to invite you to theirs."
		// 	},
		// 	{
		// 		image_url: "",
		// 		sub_head: "Invite friends",
		// 		blurb: "Your friends will receive emails with a special link to join your league."
		// 	},
		// 	{
		// 		image_url: "",
		// 		sub_head: "Start the draft",
		// 		blurb: "Everyone in your league participates in real-time to fill out their rosters."
		// 	},						
		// 	]}});

		this.addView(new fantasy.packs.UXWidgets.ImpactStatement({model: {statement: "About Fantasy Dictator"}}),{fineWithAppend:true});
		//this.addView(threeUpAlpha.render(),{fineWithAppend:true});
		this.addView(new fantasy.packs.UXWidgets.BandBeefyParagraph({model: {title: "Fantasy Dictator",paragraph: fantasy.templates.about_us_blurb() }}),{fineWithAppend: true});

		var instructionList = new fantasy.packs.UXWidgets.InstructionList({model: {items:[
			{
				sub_head: "Signup",
				blurb: "Signup in seconds with your Google or Facebook account."
			},
			{
				sub_head: "Create league",
				blurb: "Anyone can make a league, or ask a friend to invite you to theirs.<br/><br/>You can make or join up to three leagues per season."
			},
			{
				sub_head: "Invite",
				blurb: "On your league page, use the Invite Friends form to invite more people to your league.  People you invite will receive emails with a special link to join your league.  Tell them to look out for the invite!"
			},
			{
				sub_head: "Schedule draft",
				blurb: "Pick a time for draft day with your league members."
			},					
			{
				sub_head: "Draft",
				blurb: "Everyone in your league participates in real-time to fill out their rosters.  We use a snake-draft that gives the last fantasy team two picks in a row, and the first fantasy team two picks in a row after the first round.  Make an appointment with your " + 
				" team and login at the same time.  You only get two minutes to make each draft pick, so be prepared to act fast."
			},		
			{
				sub_head: "Pick lineup",
				blurb: "Choose your weekly line-ups for up-coming weeks."
			},	
			// {
			// 	sub_head: "Protect Freedom",
			// 	blurb: "Get involved in local, State, and Federal politics, tell stories of American liberty, support N.G.O.s that fight for liberty and justice, and disobey unconstitutional decrees."
			// },										
			// {
			// 	sub_head: "Weep",
			// 	blurb: "You are allowed to cry into your pillow once a week."
			// },										
			{
				sub_head: "Compete",
				blurb: "See how your lineup fares against other teams in your league."
			},										

			]}});

		this.addView(instructionList.render(),{fineWithAppend:true});

		this.addView(new fantasy.packs.UXWidgets.BandBeefyParagraph({model: {title: "Dic(tator) Moves",paragraph: "<p>When one of these real life dictator players does something dictatorial the editors at Fantasy Dictator will award points to that player.</p>"}}),{fineWithAppend: true});
		
		this.addView(new fantasy.packs.Comps.PagePointCategories(),{fineWithAppend:true});




		this.render();
	}
});