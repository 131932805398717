fantasy.packs.Comps = fantasy.packs.Comps || {};


fantasy.packs.Comps.FriendsInLeagueOnlineStatus =  fantasy.present.Classes.Base.extend({ 
	className: "fd-friends-in-league-online-status",
	template: fantasy.templates.sub_league_online_status,
	skip_render_via_diff: true
});


fantasy.packs.Comps.LeagueItemYoursView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-league",

	template: fantasy.templates.sub_league_item_yours
});



fantasy.packs.Comps.LeaguesYoursView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-leagues",
	subViewClass: fantasy.packs.Comps.LeagueItemYoursView
});

fantasy.packs.Comps.LeagueTeaserItemYoursView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-league fd-league-teaser",
	template: fantasy.templates.sub_league_teaser_item_yours
});

fantasy.packs.Comps.LeagueTeasersYoursView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-leagues fd-league-teasers",
	subViewClass: fantasy.packs.Comps.LeagueTeaserItemYoursView
});



fantasy.packs.Comps.LeagueMastView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.sub_league_mast_view
});
fantasy.packs.Comps.LeagueCoreMastView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.sub_league_core_mast_view
});
fantasy.packs.Comps.UserItemInLeagueView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item",
	template: fantasy.templates.sub_user_item_in_league_b
});

fantasy.packs.Comps.UsersInLeagueView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list",	
	subViewClass: fantasy.packs.Comps.UserItemInLeagueView
});


fantasy.packs.Comps.FantasyTeamInAllRosterListView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-fantasy-team-in-all-roster-list",
	template: fantasy.templates.sub_team_all_teams_roster_list
});

fantasy.packs.Comps.TeamItemInLeagueView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item",
	template: fantasy.templates.sub_team_item_in_league
});



fantasy.packs.Comps.TeamsInLeagueView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-teams-in-league",
	subViewClass: fantasy.packs.Comps.TeamItemInLeagueView
});


fantasy.packs.Comps.FantasyTeamMastView =  fantasy.present.Classes.Base.extend({ 
	template: fantasy.templates.sub_fantasy_team_mast_view
});


fantasy.packs.Comps.AthleteItemInFTeamView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-athlete-item athlete",
	
	template: fantasy.templates.sub_athlete_item_in_f_team
});

fantasy.packs.Comps.AthletesInFTeamView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list  fd-athletes-in-fantasy-team fd-athlete-list",

	subViewClass: fantasy.packs.Comps.AthleteItemInFTeamView
});



fantasy.packs.Comps.AthleteAvailableItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-athlete-item athlete available",
	template: fantasy.templates.sub_athlete_available_draft,
	events: { 
		"click .fd-action-draft-pick": "pickDraft"
	},
	pickDraft: function(e) { 
		console.log("pickDraft AthleteAvailableItemView",this.model);
        e.preventDefault();
        e.stopPropagation();
        this.trigger("picked_for_draft", {model: this.model, view: this});
	}
});

fantasy.packs.Comps.AthletesAvailableListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-athlete-list fd-athletes-available",
	subViewClass: fantasy.packs.Comps.AthleteAvailableItemView,
	massageCreatedSubView: function(subview) { 
		var self = this;

		this.listenTo(subview,"picked_for_draft", function(obj) { 

			self.trigger("picked_for_draft", obj);
		});
		return subview;
	}
});



//all athletes in all fteams view
fantasy.packs.Comps.AllTeamRostersViewDrafting  = fantasy.present.Classes.ContainerView.extend({
	className: "fd-all-team-rosters-drafting",
	initialize: function(options)
	{
		fantasy.present.Classes.ContainerView.prototype.initialize.apply(this,arguments);
		var self = this;
		this.allFantasyTeamCompositesForLeagueCollection = options.allFantasyTeamCompositesForLeagueCollection;
		console.log("AllTeamRostersViewDrafting init", self, this.allFantasyTeamCompositesForLeagueCollection);
		_.each(this.allFantasyTeamCompositesForLeagueCollection.models, function(fTeamComposite) { 
			var subview = new fantasy.packs.Comps.AthletesInFTeamView({collection:fTeamComposite.getCollection("athletes")});
			var fTeamView = new fantasy.packs.Comps.FantasyTeamInAllRosterListView({model:fTeamComposite });
			fTeamView.render();
			self.addView(fTeamView);
			subview.render();
			self.addView(subview);
		});
		
		self.render();

	}

 });


fantasy.packs.Comps.EditCreateLeagueNameView = fantasy.present.Classes.AssignableFormView.extend({ 
	className: "edit-create-league-form-view",
	template: fantasy.templates.sub_form_edit_create_league_name_view,
	thank_you_template: fantasy.templates.sub_form_edit_create_league_name_thankyou_view,
	events: { 
		"click .reinit": "reinit",
		"click .submit": "interceptSubmit",
		"click .cancel": "interceptCancel"
	},
	initialize: function(options)
	{
		console.log("EditCreateLeagueNameView start INIT");

		fantasy.present.Classes.AssignableFormView.prototype.initialize.apply(this,arguments);
		if(options)
		{
			if(options.league) {
				this.league = options.league;
				if(this.league.getId())
					this.league_id = this.league.getId();
				console.log("EditCreateLeagueNameView league passed in!", this.league);
			}
		}

		if(options && options.league_id && !this.league)
		{
			this.league_id = options.league_id;
		}
		if(!this.league)
			this.league = new new fantasy.packs.CompBeans.League({sport_id: this.sport_id, season_id: this.season_id});
		this.setContent(this.league);
		console.log("EditCreateLeagueNameView end INIT", this.league, this);


		this.sportSelector = new fantasy.packs.UXWidgets.SelectInput( {
			option_model_name_fieldname: "name",
			option_model_value_fieldname: "season_id",
			data_model_fieldname: "season_id",
			data_model: this.model,
			options_collection: fantasy.userState.getCollection("seasons_allowing_new_leagues")
		 });

		this.addView(this.sportSelector,{selector:".fd-dz-season-selector"});


	},
	reinit: function() { 
		this.template = fantasy.templates.sub_invite_to_league_by_email_view;
		this.league = new new fantasy.packs.CompBeans.League({sport_id: this.sport_id, season_id: this.season_id});
		this.setContent(this.league);
		this.did_reinit = true;
		this.render();
	},
	postRender: function() { 
		console.log("EditCreateLeagueNameView post RENDER");

		if(this.did_reinit)
			return;
	},
	notifyDoneWithForm: function() { 
		fantasy.userState.userComposite.fetch();
		this.trigger("form-view-done");
		this.remove();
	}
});

