fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};

fantasy.packs.CompBeans.LeagueWhoIsOnlineModel = fantasy.beans.Classes.CompositeModel.extend({
      use_cache: false,     
     initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      this.leagueComposite = options.leagueComposite;

      var self = this;

      this.debouncedSyncTrigger = _.debounce(function() { 
        console.log("debounced sync");
        self.trigger("sync");
      }, 3000);
      var hasDoneFirstTime = false;

      
      this.stopListening(fantasy.userState.friendsOnlineManager);

        this.listenTo(fantasy.userState.friendsOnlineManager,"sync", function() { 
          console.log("LeagueWhoIsOnlineModel friends online sync hear");
           // var listOfWhoisOnline = fantasy.userState.friendsOnlineManager.get("online_list");
            var mapOfWhoisOnline = fantasy.userState.friendsOnlineManager.get("activeMessages");
            if(!mapOfWhoisOnline)
              return;
            var users =  self.leagueComposite.getCollection("users").clone();
            var massaged_users = [];


            var numOnline = 0;
            _.each(users.models, function(user) { 
                  var massaged_user = user.clone();

                 if(mapOfWhoisOnline[user.getId()]) {

                  massaged_user.set("online", true);
                  numOnline++;
                }
                else{
                  massaged_user.set("online", false);
                }
               massaged_users.push(massaged_user);

            });
            var massagedUserCollection = new fantasy.Collections.Classes.Base(massaged_users);
            self.addCollection("users", massagedUserCollection);
            self.set("num_online",numOnline);
            self.set("any_online", numOnline > 0);
            self.set("any_offline", numOnline !== users.length);
            if(!hasDoneFirstTime)
            {
              self.trigger("sync");
              hasDoneFirstTime = true;
            }
            else
            {
              self.debouncedSyncTrigger();
            }
            console.log("LeagueWhoIsOnlineModel friends online sync process done, numOnline", numOnline);
        });


    },
});


fantasy.packs.CompBeans.LeagueComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "league_id",
        mdl_name: "LeagueComposite",
    use_cache: true,     
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      var self = this;
      this.addModel("league_core",new fantasy.packs.CompBeans.League({league_id: this.getId()}));
      this.addModel("season_core",new fantasy.packs.CompBeans.Season({season_id: this.getModel("league_core").get("season_id")}),{depth:1});
      this.addCollection("users",new fantasy.packs.CoreCollections.UsersInALeague(null,{league_id: this.getId()}));
      this.addCollection("teams",new fantasy.packs.CoreCollections.FantasyTeamsInALeague(null,{league_id: this.getId()}));
      this.addCollection("outstanding_invites", new fantasy.packs.CoreCollections.OutboundInvitesFromLeague(null,{league_id: this.getId()}));
      
      self.set("even_num_teams", false);

      this.listenTo(this.getCollection("teams"),"sync",function() { 
        self.set("even_num_teams", (self.getCollection("teams").models.length % 2 === 0));
      });
      this.addModel("commissioner_user", new fantasy.packs.CompBeans.User({user_id: this.getModel("league_core").get("created_by_user_id")}),{depth:1});
      this.addModel("season_composite", new fantasy.packs.CompBeans.SeasonComposite({season_id: this.getModel("league_core").get("season_id")}), {depth:1});
      this.listenTo(this,"sync", function() { 
        self.calculateLoggedInUserFTeamId();
        console.log("LEAGUE COMPOSITE SYNC HEARD");
      });
      // this.listenTo(this.getCollection("outstanding_invites"),"sync",function() { 
      //   self.set("num_truly_outstanding_invites")
      // });
    },
  
    levelPrep: function() { 
      var leagueCore = this.getModel("league_core");
      if(leagueCore.loaded)
      {
        if(leagueCore.get("league_state") === 3)
        {
          if(leagueCore.get("current_draft_user_id") && !this.getModel("current_draft_user"))
            this.addModel("current_draft_user",new fantasy.packs.CompBeans.User({user_id: leagueCore.get("current_draft_user_id")}),{depth:1, clone_it: true});

          if(leagueCore.get("next_draft_user_id") && !this.getModel("next_draft_user"))
            this.addModel("next_draft_user",new fantasy.packs.CompBeans.User({user_id: leagueCore.get("next_draft_user_id")}),{depth:1, clone_it: true});
          if(leagueCore.get("previous_draft_user_id") && !this.getModel("previous_draft_user"))
            this.addModel("previous_draft_user",new fantasy.packs.CompBeans.User({user_id: leagueCore.get("previous_draft_user_id")}),{depth:1, clone_it: true});

        }
      }
      this.getModel("commissioner_user").setId(this.getModel("league_core").get("created_by_user_id"));
      this.getModel("season_core").setId(this.getModel("league_core").get("season_id"));
      this.getModel("season_composite").setId(this.getModel("league_core").get("season_id"));
      console.log("LeagueComposite level prep ",this.getModel("league_core").get("season_id"),this,this.getModel("season_core"),this.getModel("league_core"));

    },
    reFetchDraftingVolatileData: function() { 
      console.log("reFetchDraftingVolatileData start", this);
      var self = this;
      var deferred = new $.Deferred();
      var leagueCore = this.getModel("league_core");
      leagueCore.fetch().done(function() { 

        self.addModel("current_draft_user",new fantasy.packs.CompBeans.User({user_id: leagueCore.get("current_draft_user_id")}),{depth:1, clone_it: true});
        self.addModel("next_draft_user",new fantasy.packs.CompBeans.User({user_id: leagueCore.get("next_draft_user_id")}),{depth:1, clone_it: true});
        self.addModel("previous_draft_user",new fantasy.packs.CompBeans.User({user_id: leagueCore.get("previous_draft_user_id")}),{depth:1, clone_it: true});

        // self.getModel("current_draft_user").setId(leagueCore.get("current_draft_user_id"));
        // self.getModel("current_draft_user").set("user_id",leagueCore.get("current_draft_user_id"));

        // self.getModel("next_draft_user").setId(leagueCore.get("next_draft_user_id"));
        // self.getModel("next_draft_user").set("user_id",leagueCore.get("next_draft_user_id"));

        // self.getModel("previous_draft_user").setId(leagueCore.get("previous_draft_user_id"));
        // self.getModel("previous_draft_user").set("user_id",leagueCore.get("previous_draft_user_id"));

        console.log("reFetchDraftingVolatileData middle", self);

        // var a = self.getModel("current_draft_user").fetchOrPopulateFromCache();
        // var b = self.getModel("next_draft_user").fetchOrPopulateFromCache();
        // var c = self.getModel("previous_draft_user").fetchOrPopulateFromCache();

          var a = fantasy.cstore.cstore.getOrFetchItem(self.getModel("previous_draft_user"),self.getModel("previous_draft_user").cache_ttl || 1800);
          a.done(function(arg) { 
            self.addModel("previous_draft_user",arg.clone());
          });
          var b = fantasy.cstore.cstore.getOrFetchItem(self.getModel("next_draft_user"),self.getModel("next_draft_user").cache_ttl || 1800);
          b.done(function(arg) { 
            self.addModel("next_draft_user",arg.clone());
          });
          var c = fantasy.cstore.cstore.getOrFetchItem(self.getModel("current_draft_user"),self.getModel("current_draft_user").cache_ttl || 1800);
          c.done(function(arg) { 
            self.addModel("current_draft_user",arg.clone());
          });


        $.when(a,b,c).done(function() {  
           console.log("reFetchDraftingVolatileData DONE", self);
           
           //brendan test
           self.trigger("sync");

          deferred.resolve(self); 

        });
      });

      return deferred.promise();

    },
    calculateLoggedInUserFTeamId: function() { 
      var teams = this.getCollection("teams");
      if(!teams || teams.length === 0)
        return null;

      var your_f_team_id = null;
      _.each(teams.models,function(fTeam) { 
        if(fTeam.get("user_id") === fantasy.userState.getLoggedInUserId())
          your_f_team_id = fTeam.getId();
      });
      this.your_f_team_id = your_f_team_id;
      this.set("your_f_team_id", this.your_f_team_id);
      return this.your_f_team_id;
    }

  });



fantasy.packs.CompBeans.LeagueTeaser = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "league_id",
        mdl_name: "LeagueTeaser",
    use_cache: true,     
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      var self = this;
      this.addModel("league_core",new fantasy.packs.CompBeans.League({league_id: this.getId()}));
      this.addModel("season_core",new fantasy.packs.CompBeans.Season({season_id: this.getModel("league_core").get("season_id")}),{depth:1});
      this.addCollection("teams",new fantasy.packs.CoreCollections.FantasyTeamsInALeague(null,{league_id: this.getId()}));
      
    },
    levelPrep: function() { 
      var leagueCore = this.getModel("league_core");
      this.getModel("season_core").setId(leagueCore.get("season_id"));
    },
    fetch: function(options){
      var self = this;
      return fantasy.beans.Classes.CompositeModel.prototype.fetch.apply(this,arguments).done(function() { 
        var teams = self.getCollection("teams");
            self.set("odd_number_players", false);
            self.set("encourage_invite", false);

        if(!teams)
          {
            self.set("encourage_invite", true);
          }
          else {
            if(teams.length % 2 === 1)
            {
              self.set("encourage_invite", true);
              self.set("odd_number_players", true);

            }
            if(teams.length < 4)
            {
              self.set("encourage_invite", true);
            }
          }
          self.trigger("sync");

      });

    }

  });



fantasy.packs.CompBeans.LeagueTeasersForUser = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.LeagueTeaser,
  coreCollectionClass: fantasy.packs.CoreCollections.LeaguesForUser,
  mdl_name: "LeagueTeasersForUser",
  use_cache: true,
  id_fields: ["user_id"],
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("LeagueTeasersForUser post super init",this);
    },
});

fantasy.packs.CompBeans.LeagueTeasersForUserSeason = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  model: fantasy.packs.CompBeans.LeagueTeaser,
  coreCollectionClass: fantasy.packs.CoreCollections.LeaguesForUserAndSeason,
  mdl_name: "LeagueTeasersForUserSeason",
  use_cache: true,
  idAttribute: "season_id",
  id_fields: ["season_id","user_id"],
   initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("LeagueTeasersForUserSeason post super init",this);
    },
});


