

fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageJoiningLeague = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_joining_league,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);

		//console.log("Just stored: ", JSON.parse( localStorage.getItem( 'invite_to_accept' ) ) );
		var rawStr =  localStorage.getItem( 'invite_to_accept' );
		if(!rawStr)
		{
			this.setContent({error:true,message:"No invite found."});
			this.render();
			return;
		}
		var inviteInfo = JSON.parse( rawStr);
		
		this.setContent(inviteInfo);

	    try {
	    	var view = new fantasy.packs.Comps.JoiningLeagueFromInviteView({league_id: inviteInfo.league_core.league_id, inviteInfo: inviteInfo});
	    	this.listenToOnce(view,"joined", function() { 

	    		localStorage.removeItem("invite_to_accept");

	    		console.log("PageViewReceivedInvite received joined event. Bubbling up");
	    		self.trigger("joined");
	    	});
	    	//accepted_invite
			this.addView(view,{selector:".fd-dz-joining"});
		}
		catch(err)
		{
			console.error("PageJoiningLeague Error ",err);
			this.setContent({error:true});
		}
		this.render();
	}
});