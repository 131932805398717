fantasy.cstore = fantasy.cstore || {};

(function beta() { 
	fantasy.cstore.cstore = function() { 

		var cstore = {};
		var contracts = {};

		/**
		for a given mdl_name, lists which keys we have in action that
		are for collections that contain objects of that type.
		example:

		@key: string mdl_names
		@value: map (object) of keys for collections to "true"
		**/
		var mdl_names_to_col_map = {};
		var self = this;

		/**
		resets the cache, clearing things out
		**/
		self.reset = function() { 
			cstore = {};
			contracts = {};
			mdl_names_to_col_map = {};
		};

		self.getInternals = function() { 
			return { 
				cstore: cstore,
				contracts : contracts,
				mdl_names_to_col_map: mdl_names_to_col_map
			};
		};

		self.getItem = function(mdl_name, id){
			var wrapper= cstore[mdl_name + "_" + id];
			if(!wrapper)
				return null;
			return wrapper.obj;
		};

		self.addItem = function(model)
		{
			if(!model || !model.mdl_name)
				throw "does not have an mdl_name!";
			var wrapper = {};
			wrapper.obj = model;
			wrapper.fetched_moment = moment.utc();
			var key = model.mdl_name + "_" + model.getId(); 
			cstore[key] = wrapper;

			//okay if we have a collection,
			//then let's register its children object(s) dependencies
			if(model.model && model instanceof Backbone.Collection)
			{
				var tempModel = new model.model([],{});

				//handles collections of plain objects.
				//we want to say: hey, this collection is dependent
				//upon this mdl_name.
				if(tempModel.mdl_name)
				{
					self._registerDependency(tempModel.mdl_name,key);
				}

				//handles composite models with explicit declarations
				//of what mdl_names are inside them
				if(tempModel.dependent_mdl_names)
				{
					_.each(tempModel.dependent_mdl_names,function(mdl_name) { 
						self._registerDependency(mdl_name,key);

					});
				}

				//handles composite models, looking inside their sub models
				if(tempModel.subModelsMap)
				{
					var subModelInstances = _.values(tempModel.subModelsMap);
					if(subModelInstances && subModelInstances.length>0)
					{
						//console.log("cstore subModelInstances ",subModelInstances);
						//for each sub-model in the composite:
						_.each(subModelInstances,function(submodel) { 
							if(submodel) {
								//console.log("cstore submodel in each. submodel,subModelInstances,tempModel,model: ", submodel,subModelInstances,tempModel,model);
								if(submodel.mdl_name)
									self._registerDependency(submodel.mdl_name,key);
							}
						});
					}
				}
			}
			return this;
		};

		/**
			used internally.
			@param mdl_name (dependee) type of object that, when updated, tells us that
			the key's value is probably out of date.
			@param key (depender) often the key to a collection that comprises,
			directly or indirectly on items of mdl_name.  Making this keyed entry
			very interested and dependent on changes to things of mdl_name

		**/
		self._registerDependency = function(mdl_name,key) {
			//console.log("cstore: registering a dependency, ", mdl_name,key);
			var collectionDependencies = mdl_names_to_col_map[mdl_name];
			collectionDependencies = collectionDependencies || {};
			collectionDependencies[key] = true;
			mdl_names_to_col_map[mdl_name] = collectionDependencies;
		};

		self.notifyOfUpdate = function(model) {
			//TODO potential optimization to just replace the model
			//in the wrapper with the item.
			self.removeItem(model);
		};

		self.removeItem = function(model) {
			console.log("cstore: removing item from cache, model: " + model.mdl_name,model.getId(),model);

			var key = model.mdl_name + "_" + model.getId();
			return self.removeItemByKey(key, model.mdl_name);
		};

		self.removeItemByKey = function(key,mdl_name_optional) {
			console.log("cstore: removing item from cache, key: " + key);

			if(cstore[key])
				delete cstore[key];
			if( contracts[key])
				delete contracts[key];

			if(!mdl_name_optional)
				return;

			//also remove dependencies
			var dependencies = mdl_names_to_col_map[mdl_name_optional];
			if(dependencies)
			{
				//console.log("cstore: processing dependencies during removal: ", key, mdl_name_optional, dependencies);
				var keys = _.keys(dependencies);
				if(keys && keys.length > 0)
				{
					_.each(keys, function(key) { 
						self._refetchDependentCollection(key);		
						self.removeItemByKey(key,null);
					});
				}
				else
				{
					//console.log("cstore: ZERO dependencies found during removal: ", key, mdl_name_optional, dependencies);

				}
				//remove dependency mapping now that the stuff is gone.
				delete mdl_names_to_col_map[mdl_name_optional];
			}

		};

		self._refetchDependentCollection = function(key) {
			var wrapper = cstore[key];
			if(!wrapper)
			{
				console.warn("_refetchDependentCollection requested that we refetch a collection, but can't find the object. Someone must have beat us to it. key: ", key);
				return;
			}
			var dependentObj = wrapper.obj;
			console.log("refetching a dependent", dependentObj,key);
			dependentObj.fetch();

		};
		/**

		if we have it, we'll resolve it.
		if we need to fetch it, we'll fetch it, add to our cache, and resolve it

		@param model
		@param ttl_seconds integer SECONDS (OPTIONAL -- defaults to model.ttl_seconds or NONE if not found)
		@returns jquery promise
		@resolves the model to use
		@rejects error object

		**/
		self.getOrFetchItem = function(model, ttl_seconds, triggerSyncIfFromCache){
			if(!model || !model.mdl_name)
				throw "This model class does not have an mdl_name! add this.mdl_name = in init or mdl_name: in extend";
			var key = "";

			try {
				key = model.mdl_name + "_" + model.getId();
			}catch(err) {
				console.error("cstore getOrFetchItem error thrown making key, for model: ",model);
				throw err;
			}

			var wrapper= cstore[key];
			ttl_seconds = ttl_seconds || model.ttl_seconds || false;
			var have_to_fetch = true;
		  	var deferred = new $.Deferred();
		  	var ourPromise = deferred.promise();
		  	var keyInOurMap = false;

			if(wrapper)
			{	
				keyInOurMap = true;

				if(ttl_seconds){
					var now_minus_ttl = moment.utc().subtract(ttl_seconds,"seconds");
					have_to_fetch = wrapper.fetched_moment.isBefore(now_minus_ttl);

				}
				else
				{
					have_to_fetch = false;
				}
				if(!have_to_fetch)
				{
					//console.log("cstore cache fulfill "  + model.mdl_name + " :", model.getId(), wrapper);
					deferred.resolve(wrapper.obj);
					if(triggerSyncIfFromCache)
						wrapper.obj.trigger("sync");
					return ourPromise;
				}
			}

			if(have_to_fetch)
			{
				if(contracts[key])
				{
					//console.log("cstore prom. queue: ", key);
					contracts[key].done(function(arg) { 
						//console.log("cstore prom. fulfill ", key, model.getId());
						deferred.resolve(arg);
					});
					return ourPromise;
				}
				else if(!keyInOurMap)
				{
					console.log("cstore novel: ", key);
				}

				var pure_fetch_promise = model.fetch();

				contracts[key] = ourPromise;

				pure_fetch_promise.done(function(){
					//console.log("cstore server populate",key, model.getId(), model);

					//ADD IT TO OUR CACHE
					self.addItem(model);
					deferred.resolve(model);
					delete contracts[key];
				}).fail(function(err) { 
					deferred.reject(err);
				});
			}
			return ourPromise;

		};

		return self;

	};

	fantasy.cstore.cstore = new fantasy.cstore.cstore();

})();
