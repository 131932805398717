
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageLeagueYouAreOnView = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_league_you_are_on,
	className: "fd-league-you-are-on",
	events: { 
		"click .action-start-draft": "startDraft"
	},
	getPageTitle: function() { 
		return this.leagueComposite.getModel("league_core").get("name"); 
	},
	initialize: function(options) {
		console.log("PageLeagueYouAreOnView INIT START",options);
		var self = this;
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.leagueComposite = options.leagueComposite;
		this.setContent(this.leagueComposite);

		fantasy.socket.socketManager.listenToLeagueRoom(this.leagueComposite.getId());

		this.listenTo(fantasy.socket.socketManager,"draft_start", _.bind(this.handleExternalDraftStart,this));
	
		this.listenTo(fantasy.socket.socketManager,"draft_end", _.bind(this.handleExternalDraftEnd,this));

		fantasy.userState.friendsOnlineManager.setupPinger(this.leagueComposite.getId());

		window.curLeague = this.leagueComposite;

		//this.addView(new fantasy.packs.Comps.LeagueMastView({model: this.leagueComposite}),{selector:".fd-dz-league"});
		
		//this.addView(new fantasy.packs.Comps.UsersInLeagueView({collection: this.leagueComposite.getCollection("users") }),{selector: ".fd-dz-users"});
		
		this.addView(new fantasy.packs.Comps.TeamsInLeagueView({collection: this.leagueComposite.getCollection("teams") }),{selector: ".fd-dz-teams"});
		
		var seasonId = this.leagueComposite.getModel("league_core").get("season_id");

		if(!seasonId){
			console.error("PageLeagueYouAreOnView init doesn't have a season id on the league core of the league composite.",seasonId,this.leagueComposite.getModel("league_core"),this.leagueComposite.getModel("league_core").attributes, this.leagueComposite,this);
			throw "PageLeagueYouAreOnView init doesn't have a season id on the league core of the league composite.";
		}
		//TODO
		this.seasonComposite = new fantasy.packs.CompBeans.SeasonComposite({season_id: this.leagueComposite.getModel("league_core").get("season_id") });


		this.yourFantasyTeamsOnLeague = new fantasy.packs.CompBeans.SingleUserFantasyTeamCompositeCollection([],{user_id: fantasy.userState.getModel("user_composite").getId(), league_id: this.leagueComposite.getId()});
		
		this.athletesOnYourTeamView = new fantasy.packs.Comps.AthletesInFTeamView();
		this.yourFantasyTeamCompositeView = new fantasy.packs.Comps.FantasyTeamInAllRosterListView();
		self.addView(this.athletesOnYourTeamView, {selector: ".fd-dz-your-roster"});
		self.addView(this.yourFantasyTeamCompositeView, {selector: ".fd-dz-your-team"});



//		self.addView(this.invitesSentView, {selector: ".fd-dz-invites-sent"});



	   var yourFantasyTeamsOnLeaguePromise = fantasy.cstore.cstore.getOrFetchItem(self.yourFantasyTeamsOnLeague,120,false);

		yourFantasyTeamsOnLeaguePromise.done(function(arg) { 
			if(arg)
				self.yourFantasyTeamsOnLeague = arg;

			if(self.yourFantasyTeamsOnLeague && self.yourFantasyTeamsOnLeague.length > 0)
			{
				var yourTeamComposite = self.yourFantasyTeamsOnLeague.models[0];
				console.log("found fantasy team",yourTeamComposite);
				self.athletesOnYourTeamView.setContent(yourTeamComposite.getCollection("athletes"));
				self.athletesOnYourTeamView.render();
				self.yourFantasyTeamCompositeView.setContent(yourTeamComposite);
				self.yourFantasyTeamCompositeView.render();
				window.yourTeamComposite = yourTeamComposite;
			}
			else
			{
				console.log("didn't find fantasy team");
			}

		});
	

		this.seasonComposite.getCollection("rounds").modelInject = function(model) { 
			model.setMeta("league_id", self.leagueComposite.getId());
			console.log("just injected meta info!", model);
		};

		console.log("just set modelInject func on rounds collection in seasonComposite", this.seasonComposite);
		window.seasonComposite = this.seasonComposite;

		this.seasonComposite.fetch().done(function() { console.log("just loaded seasonComposite", self.seasonComposite);
			self.seasonComposite.getCollection("rounds").setMetaOnAll("league_id",self.leagueComposite.getId());
			self.seasonComposite.getCollection("rounds").trigger("sync");
		});

		this.addView(new fantasy.packs.Comps.RoundsListView({collection: this.seasonComposite.getCollection("rounds") }),{selector: ".fd-dz-rounds"});

		if(this.leagueComposite.getModel("league_core").get("accepting_members"))
			this.addView(new fantasy.packs.Comps.InviteToLeagueByEmailView({league_id: this.leagueComposite.getId()}), { selector: ".fd-dz-invite-form"});

		this.render();
	},
	handleExternalDraftEnd: function(data) { 

		console.log("handleExternalDraftEnd", data);
		var self = this;

		self.leagueComposite.refetch().done(function(){ 
			fantasy.router.navigateWrapper("league/weeks/?league_id=" + self.leagueComposite.getId());
		});			

	},
	handleExternalDraftStart: function(data){ 
		console.log("handleExternalDraftStart", data);
		var self = this;

		self.leagueComposite.refetch().done(function(){ 
			fantasy.router.navigateWrapper("league/drafting/?league_id=" + self.leagueComposite.getId());
		});	

	},
	startDraft: function(e) { 
		//TODO put up confirmation screen...

		var leagueId = this.leagueComposite.getId();
		var self = this;

		var myprom = $.ajax({url:"/api/league/start_draft/" + leagueId,method:"POST"});

		return myprom.done(function(){ 
			self.leagueComposite.refetch().done(function(){ 
				self.render();
				self.trigger("started_draft");
			});
		});
	}
});