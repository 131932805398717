


/**
construct accepts an options object:

{
	submit_text: "Submit", //optional, also captured from dom
	submit_processing_text: "Submitting...",
	cancel_processing_text: "Cancelling...",
	reenable_submit_button_on_success: true,
	customValidator: function(candidate_bb_model) { }
}
**/
fantasy.present.Classes.FormView =  fantasy.present.Classes.Base.extend({
	className: "form-view",
	tagName: "div",
	events: { 
		"click .submit": "interceptSubmit",
		"click .cancel": "interceptCancel",
		"click .close": "interceptClose",
		"submit form": "genericIntercept"

	},
	initialize: function(options)
	{
		 fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
		 fantasy.present.Classes.FormViewMixin(this);
		 this.config(options);

	}
});


// config: function(options) { 
// 	  		 this.submit_text = options.submit_text || "Submit";		 
// 			 this.submit_processing_text = options.submit_processing_text || "Submitting...";
// 			 this.cancel_processing_text = options.cancel_processing_text || "Cancelling...";
// 			 this.reenable_submit_button_on_success = options.reenable_submit_button_on_success;
// 			 if(options.customValidator) 
// 			 	this.customValidator = options.customValidator;		
// 			 if(options.massageModel)
// 			 	this.massageModel = options.massageModel;

// 		},
// 		/**	
// 			overrideable
// 		**/
// 		handleValidSubmission: function() { 
// 			var self = this;
// 			console.log("Default handleValidSubmission function start");
// 			this.populateModelFromForm();
// 			var savePromise = this.model.save();
// 			savePromise.done(function(){ 
// 				self.trigger("save_success");
// 			}).fail(function(err) { 
// 				self.trigger("save_error",arguments);
// 			})
// 			return savePromise;

// 		},		
// 		displayGeneralError: function(msg)
// 		{
// 			this.$el.addClass("has-error");
// 			this.$el.find(".general-error-msg").html(msg);
// 		},
// 		/**
		
// 		@returns boolean true if valid, false if invalid
// 		**/
// 		validateForm: function() { 
// 		    this.candidateModel = this.model.clone();
// 			this.candidateModel.set(this.getFormDataAsObject());
// 			console.log("candidate model post form data set", this.candidateModel, this.getFormDataAsObject());
// 			var error_obj = null;
// 			if(this.customValidator)
// 				error_obj = this.customValidator(this.candidateModel);
// 			else
// 				error_obj = this.candidateModel.validate();
// 			console.log("validateForm error_obj:", error_obj);
// 			if(!error_obj)
// 				return true;
// 			this.displayValidationErrorObject(error_obj);
// 			return false;
// 		},
// 		displayValidationErrorObject: function(error_obj) {
// 			var self = this;		
// 			this.$el.addClass("has-error");		
// 			this.displayGeneralError(error_obj.general_error);
// 			if(error_obj.field_notes)
// 			{
// 				_.each(error_obj.field_notes, function(field_note) { 
// 				  	self.$el.find("." + field_note.field_name + "-err").addClass("has-error");
// 				  	if(field_note.error_msg && field_note.error_msg.length  > 0)
// 					  	self.$el.find("." + field_note.field_name + "-err").html(field_note.error_msg);
// 				  	self.$el.find("." + field_note.field_name).addClass("has-error");
// 				  	self.$el.find("." + field_note.field_name).closest(".form-group").addClass("has-error");
// 				});
// 			}		
// 		},
// 		clearErrors: function() { 
// 			var self = this;
// 			this.$el.removeClass("has-error");
// 			$.each(this.$el.find("form").serializeArray(), function(_, kv) {
// 			  	self.$el.find("." + kv.name + "-err").html("").removeClass("has-error");
// 			  	self.$el.find("." + kv.name).removeClass("has-error");
// 			  	self.$el.find("." + kv.name).closest(".form-group").removeClass("has-error");
// 			});
// 		},
		
// 		interceptCancel: function(evt) { 
// 			evt.stopPropagation();
// 			evt.preventDefault();
// 			this.notifyDoneWithForm();
// 		},
// 		interceptClose: function(evt) { 
// 			evt.stopPropagation();
// 			evt.preventDefault();
// 			this.notifyDoneWithForm();
// 		},
// 		snapValues: function() { 
// 			var data = this.getFormDataAsObject();
// 			this.model.set(data);
// 		},
// 		getFormDataAsObject: function() { 
// 			var paramObj = {};
// 			$.each(this.$el.find("form").serializeArray(), function(_, kv) {
// 			  if (paramObj.hasOwnProperty(kv.name)) {
// 			    paramObj[kv.name] = $.makeArray(paramObj[kv.name]);
// 			    paramObj[kv.name].push(kv.value);
// 			  }
// 			  else {
// 			    paramObj[kv.name] = kv.value;
// 			  }
// 			});
// 			return paramObj;
// 		},
// 		genericIntercept: function(evt) { 
// 			var self = this;
// 			evt.stopPropagation();
// 			evt.preventDefault();
// 			console.error("GOT A SUBMIT BUT WE DON'T WANT THOSE");
			
// 		},		
// 		interceptSubmit: function(evt) { 
// 			var self = this;
// 			evt.stopPropagation();
// 			evt.preventDefault();
// 			this.clearErrors();
// 			this.$el.removeClass("successful_submission");
// 			//capture original submit button text
// 			this.submit_text = this.$el.find(".submit").html();
// 			this.$el.find(".submit").prop('disabled', true);
// 			this.$el.find(".submit").html(this.submit_processing_text);
// 			if(this.validateForm) {
// 				var valid = this.validateForm();
// 				if(!valid)
// 				{
// 					this.$el.find(".submit").prop('disabled', false);
// 					this.$el.find(".submit").html(this.submit_text);
// 					return;
// 				}
// 			}
// 			if(this.reenable_submit_button_on_success) {
// 				this.$el.find(".submit").prop('disabled', false);
// 				this.$el.find(".submit").html(this.submit_text);
// 			}
// 			this.$el.addClass("successful_submission");
// 			this.handleValidSubmission().done(function() { 
// 				self.$el.addClass("successful_submission");

// 				if(self.reenable_submit_button_on_success) {
// 					self.$el.find(".submit").prop('disabled', false);
// 					self.$el.find(".submit").html(self.submit_text);
// 				}
// 				else
// 				{
// 					self.$el.find(".submit").prop('disabled', true);
// 					self.$el.find(".cancel").prop('disabled', true);
// 					self.$el.find(".submit").html("Done");
// 				}
// 				self.trigger("successful_submission");
// 				if(self.thank_you_template)
// 				{
// 					self.original_template = self.template;
// 					self.template = self.thank_you_template;
// 					self.render();
// 				}
// 			}).fail(function(err) { 
// 				console.log("form view handle slash do submit, error: ",err);
// 				var errorMessage = JSON.stringify(err);

// 				if(err &&  err.responseJSON && err.responseJSON.err)
// 				{
// 					if(err.responseJSON.err.msg)
// 						errorMessage =  err.responseJSON.err.msg;
// 					else
// 						errorMessage =  err.responseJSON.err;
// 				}

// 				if(err && err.err && err.err.message)
// 				{
// 					errorMessage = err.err.message;
// 				} 

// 				self.displayGeneralError("Trouble saving.<br/><b>" + errorMessage + "</b>");
// 				self.$el.find(".submit").prop('disabled', false);
// 				self.$el.find(".submit").html(self.submit_text);
// 			});
			
// 		}, 
// 		populateModelFromForm: function() {
// 			var formData = this.$el.find("form").serializeArray();
// 			var self = this;
// 			if(!this.model)
// 			{
// 				console.log("ERROR: can't find model to populate in populateModelFromForm.");
// 			}
// 			_.each(formData, function(objpair) { 
// 				self.model.set(objpair.name,objpair.value);
// 			});
// 			if(this.massageModel)
// 				this.massageModel();
// 		},
// 		notifyDoneWithForm: function() { 
// 			this.trigger("form-view-done");
// 		}

// });

fantasy.present.Classes.AssignableFormView =  fantasy.present.Classes.AssignableContainerView.extend({
	className: "form-view",
	tagName: "div",
	events: { 
		"click .submit": "interceptSubmit",
		"click .cancel": "interceptCancel",
		"submit form": "genericIntercept"
	},
	initialize: function(options)
	{
		 fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		 fantasy.present.Classes.FormViewMixin(this);
		 this.config(options);
	},
});


fantasy.present.Classes.FormViewMixin = function(arg) { 

	arg = _.extend(arg, 
		{
			config: function(options) { 
	  		 this.submit_text = options.submit_text || "Submit";		 
			 this.submit_processing_text = options.submit_processing_text || "Submitting...";
			 this.cancel_processing_text = options.cancel_processing_text || "Cancelling...";
			 this.reenable_submit_button_on_success = options.reenable_submit_button_on_success;
			 if(options.customValidator) 
			 	this.customValidator = options.customValidator;		
			 if(options.massageModel)
			 	this.massageModel = options.massageModel;		

			 this.undelegateEvents();
			 this.delegateEvents();	 
		},
		/**	
			overrideable
		**/
		handleValidSubmission: function() { 
			var self = this;
			console.log("Default handleValidSubmission function start");
			this.populateModelFromForm();
			var savePromise = this.model.save();
			savePromise.done(function(){ 
				self.trigger("save_success");
			}).fail(function(err) { 
				self.trigger("save_error",arguments);
			});
			return savePromise;


		},		
		displayGeneralError: function(msg)
		{
			this.$el.addClass("has-error");
			this.$el.find(".general-error-msg").html(msg);
		},
		/**
		
		@returns boolean true if valid, false if invalid
		**/
		validateForm: function() { 
		    this.candidateModel = this.model.clone();
			this.candidateModel.set(this.getFormDataAsObject());
			console.log("candidate model post form data set", this.candidateModel, this.getFormDataAsObject());
			var error_obj = null;
			if(this.customValidator)
				error_obj = this.customValidator(this.candidateModel);
			else
				error_obj = this.candidateModel.validate();
			console.log("validateForm error_obj:", error_obj);
			if(!error_obj)
				return true;
			this.displayValidationErrorObject(error_obj);
			return false;
		},
		displayValidationErrorObject: function(error_obj) {
			var self = this;		
			this.$el.addClass("has-error");		
			this.displayGeneralError(error_obj.general_error);
			if(error_obj.field_notes)
			{
				_.each(error_obj.field_notes, function(field_note) { 
				  	self.$el.find("." + field_note.field_name + "-err").addClass("has-error");
				  	if(field_note.error_msg && field_note.error_msg.length  > 0)
					  	self.$el.find("." + field_note.field_name + "-err").html(field_note.error_msg);
				  	self.$el.find("." + field_note.field_name).addClass("has-error");
				  	self.$el.find("." + field_note.field_name).closest(".form-group").addClass("has-error");
				});
			}		
		},
		clearErrors: function() { 
			var self = this;
			this.$el.removeClass("has-error");
			$.each(this.$el.find("form").serializeArray(), function(_, kv) {
			  	self.$el.find("." + kv.name + "-err").html("").removeClass("has-error");
			  	self.$el.find("." + kv.name).removeClass("has-error");
			  	self.$el.find("." + kv.name).closest(".form-group").removeClass("has-error");
			});
		},
		
		interceptCancel: function(evt) { 
			evt.stopPropagation();
			evt.preventDefault();
			this.notifyDoneWithForm();
		},
		snapValues: function() { 
			var data = this.getFormDataAsObject();
			this.model.set(data);
		},
		getFormDataAsObject: function() { 
			var paramObj = {};
			$.each(this.$el.find("form").serializeArray(), function(_, kv) {
			  if (paramObj.hasOwnProperty(kv.name)) {
			    paramObj[kv.name] = $.makeArray(paramObj[kv.name]);
			    paramObj[kv.name].push(kv.value);
			  }
			  else {
			    paramObj[kv.name] = kv.value;
			  }
			});
			return paramObj;
		},
		genericIntercept: function(evt) { 
			var self = this;
			evt.stopPropagation();
			evt.preventDefault();
			console.error("GOT A SUBMIT BUT WE DON'T WANT THOSE");
			console.log("GOT A SUBMIT BUT WE DON'T WANT THOSE");

		},
		interceptSubmit: function(evt) { 
			var self = this;
			evt.stopPropagation();
			evt.preventDefault();
			this.clearErrors();
			this.$el.removeClass("successful_submission");
			//capture original submit button text
			this.submit_text = this.$el.find(".submit").html();
			this.$el.find(".submit").prop('disabled', true);
			this.$el.find(".submit").html(this.submit_processing_text);
			if(this.validateForm) {
				var valid = this.validateForm();
				if(!valid)
				{
					this.$el.find(".submit").prop('disabled', false);
					this.$el.find(".submit").html(this.submit_text);
					return;
				}
			}
			if(this.reenable_submit_button_on_success) {
				this.$el.find(".submit").prop('disabled', false);
				this.$el.find(".submit").html(this.submit_text);
			}
			this.$el.addClass("successful_submission");
			this.handleValidSubmission().done(function() { 
				self.$el.addClass("successful_submission");

				if(self.reenable_submit_button_on_success) {
					self.$el.find(".submit").prop('disabled', false);
					self.$el.find(".submit").html(self.submit_text);
				}
				else
				{
					self.$el.find(".submit").prop('disabled', true);
					self.$el.find(".cancel").prop('disabled', true);
					self.$el.find(".submit").html("Done");
				}

				self.trigger("successful_submission");
				
				if(self.thank_you_template)
				{
					self.original_template = self.template;
					self.template = self.thank_you_template;
					self.render();
				}
			}).fail(function(err) { 
				console.log("form view handle slash do submit, error: ",err);
				var errorMessage = JSON.stringify(err);

				if(err &&  err.responseJSON && err.responseJSON.err)
				{
					if(err.responseJSON.err.msg)
						errorMessage =  err.responseJSON.err.msg;
					else
						errorMessage =  err.responseJSON.err;
				}

				if(err && err.err && err.err.message)
				{
					errorMessage = err.err.message;
				} 
				self.trigger("failed_submission");
				self.displayGeneralError("Trouble saving.<br/><b>" + errorMessage + "</b>");
				self.$el.find(".submit").prop('disabled', false);
				self.$el.find(".submit").html(self.submit_text);
			});
			
		}, 
		populateModelFromForm: function() {
			var formData = this.$el.find("form").serializeArray();
			var self = this;
			if(!this.model)
			{
				console.log("ERROR: can't find model to populate in populateModelFromForm.");
			}
			_.each(formData, function(objpair) { 
				self.model.set(objpair.name,objpair.value);
			});
			if(this.massageModel)
				this.massageModel();

		},
		notifyDoneWithForm: function() { 
			this.trigger("form-view-done");
		}
	});

	return arg;
};


