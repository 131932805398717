fantasy.packs.Comps.SeasonItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item",

	template: fantasy.templates.sub_season_item_view
});

fantasy.packs.Comps.SeasonsListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list",
	subViewClass: fantasy.packs.Comps.SeasonItemView
});


fantasy.packs.Comps.SeasonMastView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-season-mast",

	template: fantasy.templates.sub_mast_season
});
