//http://paulmolluzzo.github.io/simple_share_popups/

fantasy.packs.Tracking = fantasy.packs.Tracking || {};

fantasy.packs.Tracking.trackEvent = function(cat,action,label,value,fieldsObject){

	if(isBot)
		return;

	fieldsObject = fieldsObject || {};

	try{
		ga('send', 'event', cat, action, label, value, fieldsObject);
	}
	catch(err)
	{
		console.error("Trouble sending Google Analytics Event Track",err);
	}

};

fantasy.packs.Tracking.trackTimingSinceBodScript = function(cat,timing_var,label){

	if(isBot)
		return;
	
	if(!_bodScriptRunDateMS)
	{
		console.error("CAN'T FIND _bodScriptRunDateMS in timing tracking!");
		return;
	}

	var d = new Date();
	var n = d.getTime();

	var dur = _bodScriptRunDateMS - n;

	try{
		ga('send', 'timing', cat, timing_var, dur,label);

	}
	catch(err)
	{
		console.error("Trouble sending Google Analytics Timing Track",err);
	}

};




fantasy.packs.Tracking.trackSocialShareIntent = function(platform, url) { 
	if(!isBot)
		fantasy.packs.Tracking.trackEvent("social","share",platform,1);
};


fantasy.packs.Tracking.SocialTrackingManager = function() { 

	var self = this;

	  _.extend(self, Backbone.Events);
	if(isBot)
		return self;	  
	   // $("body").off("click.facebookshare");
    // $("body").on("click.facebookshare",".share_on_facebook", function(e) { 
    //   console.log("facebook share click");
    //     // e.preventDefault();
    //     // e.stopPropagation();

    //   //fantasy.packs.Auth.FacebookSocial.launchShareModal(window.location.href);
    //   fantasy.packs.Tracking.trackSocialShareIntent("facebook");
    // });

    function setupListner(platform){
	   $("body").off("click." + platform);
	    $("body").on("click." + platform,".share_on_" + platform, function(e) { 
	      console.log("share click " + platform);
	      fantasy.packs.Tracking.trackSocialShareIntent(platform);
	      self.trigger("social_share_intent",platform);
	    });
    }

	var platforms = ["facebook","twitter","linkedin","whatsapp","googleplus","stumbleupon","tumblr","reddit"];

	_.each(platforms,setupListner);

	this.listenTo(this,"social_share_intent", function(platform) { 
		console.log("heard social share intent for platform: ", platform);
	});


/**

@see https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
@see https://developers.google.com/web/fundamentals/engage-and-retain/app-install-banners/

**/
	self.listenForSinglePageAppEvents = function() { 

		window.addEventListener('beforeinstallprompt', function(e) {
		  // beforeinstallprompt Event fired
		  // e.userChoice will return a Promise. 
		  var the_platform = e.platform || e.platforms;
		  
		  e.userChoice.then(function(choiceResult) {
		    console.log(choiceResult.outcome);

		    if(choiceResult.outcome == 'dismissed') {
		      console.log('User cancelled home screen install');
			fantasy.packs.Tracking.trackEvent("home_install","dismissed",the_platform,1);

		    }
		    else {
		      console.log('User added to home screen');
				fantasy.packs.Tracking.trackEvent("home_install","accepted",the_platform,1);

		    }
		  });
		});

	};

	self.listenForSinglePageAppEvents();



	return self;
};

fantasy.socialTrackingManager = new fantasy.packs.Tracking.SocialTrackingManager();

