fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};

fantasy.packs.CoreCollections.PointJournalsForAthlete = fantasy.Collections.Classes.Base.extend({
	model: fantasy.packs.CompBeans.PointJournal,
	url: function() { return "/api/athlete/" + this.athlete_id + "/point_journals/";},
	idAttribute: "athlete_id"
});


fantasy.packs.CompBeans.PointJournalComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "point_journal_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      if(options.model)
        this.addModel("point_journal_core",options.model);
      else 
        this.addModel("point_journal_core",new fantasy.packs.CompBeans.PointJournal({point_journal_id: this.getId()}));

      this.addModel("point_category_core",new fantasy.packs.CompBeans.PointCategory({point_category_id: this.getModel("point_journal_core").get("point_category_id")}),{depth:1});
    }

  });


fantasy.packs.CompBeans.PointJournalFullComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "point_journal_id",
     mdl_name: "PointJournalFullComposite",
    use_cache: true,
    cache_ttl: 60,        
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      options = options || {};
      
      if(options.model)
        this.addModel("point_journal_core",options.model);
      else 
        this.addModel("point_journal_core",new fantasy.packs.CompBeans.PointJournal({point_journal_id: this.getId()}));

      this.addModel("point_category_core",new fantasy.packs.CompBeans.PointCategory({point_category_id: this.getModel("point_journal_core").get("point_category_id")}),{depth:1});
      this.addModel("athlete_core",new fantasy.packs.CompBeans.Athlete({athlete_id: this.getModel("point_journal_core").get("athlete_id")}),{depth:1});

    },
     levelPrep: function() { 
      var pointJournalCore = this.getModel("point_journal_core");
      this.getModel("point_category_core").setId(pointJournalCore.get("point_category_id"));
      this.getModel("athlete_core").setId(pointJournalCore.get("athlete_id"));
      console.log("tail levelPrep PointJournalFullComposite", this);
   }

  });


fantasy.packs.CompBeans.PointJournalCompositesForAthlete = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  idAttribute: "athlete_id",
  model: fantasy.packs.CompBeans.PointJournalFullComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.PointJournalsForAthlete,
 initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("PointJournalCompositessForAthlete end init", this);
    }
});


fantasy.packs.CompBeans.PointJournalCollectionRecentFull = fantasy.Collections.Classes.CoreToCompositeConverterCollection.extend({
  idAttribute: "sport_id",
  model: fantasy.packs.CompBeans.PointJournalFullComposite,
  coreCollectionClass: fantasy.packs.CoreCollections.RecentPointJournals,
   mdl_name: "PointJournalCollectionRecentFull",
    use_cache: true,
    cache_ttl: 60,      
 initialize: function(models, options) {
      fantasy.Collections.Classes.CoreToCompositeConverterCollection.prototype.initialize.apply(this,arguments);
      console.log("PointJournalCompositessForAthlete end init", this);
    }
});


fantasy.packs.CompBeans.AthleteAbstractStatRowAllCats = fantasy.Collections.Classes.Base.extend({
  idAttribute: "athlete_id",
  id_fields: ["athlete_id","sport_id"],
  model: fantasy.beans.Classes.Base,
 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      console.log("AthleteAbstractStatRowAllCats init", this);

    },
    comparator: function(model) { 
      if(model)
        return model.get("point_category_obj").abbr;
      return 0;
    },
    instantiateCatGroupedStatsCollection: function() { 
      return new fantasy.packs.CoreCollections.LifetimePointsGroupedSumsForAthleteSport([],{athlete_id: this.athlete_id, sport_id: this.sport_id});
    },
    fetch:function() { 
          var self = this;
          var deferred = new $.Deferred();
          this.lifetimeStatGroups = this.instantiateCatGroupedStatsCollection();
          this.pointCategoriesAll = new fantasy.packs.CoreCollections.PointCategoryAll();
          var promises = [];

          promises.push(this.lifetimeStatGroups.fetch());
          promises.push(this.pointCategoriesAll.fetch());
          $.when(promises[0],promises[1]).done(function() { 

            var map = {};

            _.each(self.lifetimeStatGroups.models, function(stat) { 
              var cellModel = new self.model({athlete_id: self.athlete_id, cell_value: stat.get("the_sum"), point_category_id: stat.get("point_category_id")});
              cellModel.setId(stat.get("point_category_id"));
              map[stat.get("point_category_id")] = cellModel;
            });

            _.each(self.pointCategoriesAll.models,function(point_category) { 
              var cellModel = map[point_category.get("point_category_id")];
              if(!cellModel)
              {
                cellModel = new self.model({athlete_id: self.athlete_id, cell_value: 0, point_category_id: point_category.get("point_category_id"),
                  point_category_obj: point_category.attributes });
                cellModel.setId(point_category.get("point_category_id"));
                map[point_category.get("point_category_id")] = cellModel;
              }
              else
              {
                cellModel.set("point_category_obj",point_category.attributes);
              }
            });

            self.reset(_.values(map));

            self.trigger("sync");
            deferred.resolve();
          }).fail(function(err) {
            console.error("trouble loading AthleteAbstractStatRowAllCats",err); 
            deferred.reject(err);
          });

          return deferred.promise();
    }

});



fantasy.packs.CompBeans.AthleteLifetimeStatRowAllCats = fantasy.packs.CompBeans.AthleteAbstractStatRowAllCats.extend({
  idAttribute: "athlete_id",
  id_fields: ["athlete_id","sport_id"],
  model: fantasy.beans.Classes.Base,
 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      console.log("AthleteLifetimeStatRowAllCats init", this);

    },
    instantiateCatGroupedStatsCollection: function() { 
      return new fantasy.packs.CoreCollections.LifetimePointsGroupedSumsForAthleteSport([],{athlete_id: this.athlete_id, sport_id: this.sport_id});
    }
});

fantasy.packs.CompBeans.AthleteRoundStatRowAllCats = fantasy.packs.CompBeans.AthleteAbstractStatRowAllCats.extend({
  idAttribute: "athlete_id",
  id_fields: ["athlete_id","sport_id","round_id"],
  model: fantasy.beans.Classes.Base,
 initialize: function(models, options) {
      fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
      console.log("AthleteRoundStatRowAllCats init", this);

    },
    instantiateCatGroupedStatsCollection: function() { 
      return new fantasy.packs.CoreCollections.RoundPointsGroupedSumsForAthleteSport([],{athlete_id: this.athlete_id, sport_id: this.sport_id, round_id: this.round_id});
    }
});


// fantasy.packs.CoreCollections.LifetimePointsGroupedSumsForAthleteSport = fantasy.Collections.Classes.Base.extend({
//   model: fantasy.beans.Classes.Base,
//   url: function() { return "/api/stats/point_journal/?athlete_id=" + this.athlete_id + "&sport_id=" + this.sport_id;},
//   id_fields: ["athlete_id","sport_id"],
//   idAttribute: "sport_id",
//         mdl_name: "LifetimePointSumForAthleteSport",
//     use_cache: true,    
//  initialize: function(models, options) {
//       fantasy.Collections.Classes.Base.prototype.initialize.apply(this,arguments);
//       if(!this.sport_id)
//         this.sport_id = 1;
//     }
// });

