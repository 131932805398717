fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};

fantasy.packs.CompBeans.Athlete = fantasy.beans.Classes.Base.extend({
	  idAttribute: "athlete_id",
	  urlRoot: "/api/athlete/",
    mdl_name: "athlete",
    use_cache: true,
    cache_ttl: 180,      
	 initialize: function(attributes, options) {
  		fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
  	},
    normalize: function() { 
      if(this.get("first_name") && this.get("last_name"))
      {
        this.set("full_name", this.get("first_name") + " " + this.get("last_name"));
      }
      if(this.get("picture_url"))
      {
        var picture_url_small = this.get("picture_url").replace("/athletes/", "/athletes_100/");
        this.set("picture_url_small",picture_url_small);

      }
    }
  });
fantasy.packs.CompBeans.FantasyGame = fantasy.beans.Classes.Base.extend({
    idAttribute: "f_game_id",
    urlRoot: "/api/f_game/",
    mdl_name: "FantasyGame",
    use_cache: true,
    cache_ttl: 100,        
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });

fantasy.packs.CompBeans.FantasyTeam = fantasy.beans.Classes.Base.extend({
    idAttribute: "f_team_id",
    urlRoot: "/api/f_team/",
    mdl_name: "FantasyTeam",
    use_cache: true,  
    cache_ttl: 100,        
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });

fantasy.packs.CompBeans.FTeamAthleteMem = fantasy.beans.Classes.Base.extend({
    idAttribute: "f_team_athlete_mem_id",
    urlRoot: "/api/f_team_athlete_mem/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });
fantasy.packs.CompBeans.DraftPickerModel = fantasy.beans.Classes.Base.extend({
    idAttribute: "f_team_athlete_mem_id",
    urlRoot: "/api/f_team_athlete_mem/draft_pick/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });

fantasy.packs.CompBeans.TimeCheck = fantasy.beans.Classes.Base.extend({
    url: "/api/timecheck/",
    normalize: function() { 
      var serverMom = moment.utc(this.get("server_utc"));
      var clientMom = moment.utc();
      var diff_ms = serverMom.diff(clientMom);
      this.set("diff_ms", diff_ms); 
      console.log("TimeCheck Normalize: ", { 
        serverMom: serverMom.format(),
        clientMom: clientMom.format(),
        diff_ms: diff_ms

      });
    },
    getNowServer: function() { 
      var clientMom = moment.utc();
      var serverMom = clientMom.clone().add(this.get("diff_ms"),"ms");
      return serverMom;
    }
  });



    // self.app.post(self.path + "auto_draft/:league_id", function (req, res) {
fantasy.packs.CompBeans.AutoDraftWorkerModel = fantasy.beans.Classes.Base.extend({
    idAttribute: "league_id",
    urlRoot: "/api/f_team_athlete_mem/auto_draft/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.HandleCheck = fantasy.beans.Classes.Base.extend({
    idAttribute: "handle",
    urlRoot: "/api/user/lookup_handle",
    mdl_name: "HandleCheck",
    use_cache: true,  
    cache_ttl: 600,       
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.HandleUpdateModel = fantasy.beans.Classes.Base.extend({
    idAttribute: "user_id",
    urlRoot: "/api/user/update_handle",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
      this.loaded = true;
      
    }
  });


// /api/user/lookup_handle/BrEnDaN
/**
fantasy_ss.entities.league_system.league_states = {
  "LEAGUE_OPEN": 0,
  "LEAGUE_FULL": 1,
  "PRE_DRAFT": 2,
  "IN_DRAFT" : 3,
  "POST_DRAFT": 4,
  "REGULAR_SEASON": 5,
  "PLAYOFFS": 6,
  "SEASON_OVER": 7
};
**/
fantasy.packs.CompBeans.League = fantasy.beans.Classes.Base.extend({
    idAttribute: "league_id",
    urlRoot: "/api/league/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    },
    normalize: function() { 
      this.set("accepting_members", false);
      this.set("league_full", true);
      this.set("can_trade", false);
        this.set("season_over", false);
        this.set("in_playoffs", false);
        this.set("regular_season", false);
        this.set("draft_happening", false);
        this.set("pre_draft", true);

      if(this.get("league_state") === 0)
      {
        this.set("accepting_members", true);
        this.set("league_full", false);
      }
      if(this.get("league_state") === 3)
      {
        this.set("draft_happening", true);
        this.set("pre_draft", false);

      }      

      if(this.get("league_state") === 4)
      {
        this.set("can_trade", true);
        this.set("pre_draft", false);

      }
      if(this.get("league_state") === 7)
      {
        this.set("season_over", true);
        this.set("pre_draft", false);

      }
      if(this.get("league_state") === 6)
      {
        this.set("in_playoffs", true);
        this.set("pre_draft", false);

      }
      if(this.get("league_state") === 5)
      {
        this.set("regular_season", true);
        this.set("pre_draft", false);

      }

      if(this.get("created_by_user_id")=== fantasy.userState.getLoggedInUserId()){
        this.set("belongs_to_you",true);
      }

      //fantasy.timeCheck.getNowServer()
        
        if(this.get("draft_happening"))
        {
          this.addInFuturePastFlags("last_pick_start_ts");
          var serverNow = fantasy.timeCheck.getNowServer();
          var lastPickStartMom = moment.utc(this.get("last_pick_start_ts"));
          var currentPickExpMom = lastPickStartMom.clone().add(120,"seconds");
          this.set("current_draft_pick_expires",currentPickExpMom.format());
          this.addInFuturePastFlags("current_draft_pick_expires");
          this.addInBetweenFlags("last_pick_start_ts","current_draft_pick_expires", "current_pick_window_valid");
          if(!this.get("current_pick_window_valid"))
          {
            console.log("Invalid current pick window!", this.get("last_pick_start_ts"),this.get("current_draft_pick_expires"), serverNow.format() );
          }
          this.refreshSecondsLeftForDraftPick();
         if(this.get("current_draft_user_id") === fantasy.userState.getModel("user_composite").getId())
          {
            this.set("your_draft_pick", true);
          }
          else
            this.set("your_draft_pick", false);

        }

 

    },
    refreshSecondsLeftForDraftPick: function() { 
          var serverNow = fantasy.timeCheck.getNowServer();
          var currentPickExpStr = this.get("current_draft_pick_expires");
          var currentPickExpMom = moment.utc(currentPickExpStr);
          var diff_seconds = currentPickExpMom.diff(serverNow,"seconds");
          if(diff_seconds<0)
            diff_seconds = -1;
          if(diff_seconds > 120)
            diff_seconds = 120;

          var minLeft = Math.floor(diff_seconds / 60);
          var secLeft = diff_seconds % 60;

          var secLeftString = "" + secLeft;

          this.set("draft_pick_min_left",minLeft );
          this.set("draft_pick_sec_left", secLeft);
          if(secLeft < 10)
            secLeftString = "0" + "" + secLeft;

          var stringRep = "";
          if(diff_seconds > 0 && diff_seconds < 121)
            stringRep = this.get("draft_pick_min_left") + ":" + secLeftString;

          if(diff_seconds < 0)
            stringRep = "0:00";
          if(diff_seconds > 120)
            stringRep = "1:20";

          this.set("draft_pick_time_left_str", stringRep);
          return stringRep;

    }

  });

fantasy.packs.CompBeans.LeagueInvite = fantasy.beans.Classes.Base.extend({
    idAttribute: "league_invite_id",
    urlRoot: "/api/league_invite/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    },
    normalize: function() { 
     if(this.get("created_ts"))
       this.dateFormatFieldLocal("created_ts","pretty_created_ts","MMM Do h:mm a");
    }
  });

/**

https://localhost.fantasydictator.com/api/league_invite/lookup/4/?invite_auth_code=fda7b1d0-c5bd-4a6c-933a-d7b2640b0916&sh=1a77a0e13092ef7509ebff4afc6cf3c40d1b0e2e0c12c270eea718d9aee6be9a

expects
league_invite_id
invite_auth_code
sh

This is a core model but it has DEPTH TO IT -- IT RETURNS AN OBJECT WITH OBJECTS AS PROPERTIES

**/
fantasy.packs.CompBeans.LeagueInviteLookup = fantasy.beans.Classes.Base.extend({
    idAttribute: "league_invite_id",
    url: function() { 
      return "/api/league_invite/lookup/" + this.getId() + "/?invite_auth_code=" + this.invite_auth_code + "&sh=" + this.sh;
    },
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
      if(options && options.invite_auth_code)
        this.invite_auth_code = options.invite_auth_code;
      if(options && options.sh)
        this.sh = options.sh;

    },
    makeRegularModel: function() { 
      var model = new fantasy.packs.CompBeans.LeagueInvite(_.extend({},this.attributes));
      model.loaded = true;
      return model;
    }
  });


fantasy.packs.CompBeans.PointCategory = fantasy.beans.Classes.Base.extend({
    idAttribute: "point_category_id",
    urlRoot: "/api/point_category/",
    mdl_name: "PointCategory",
    use_cache: true,
    cache_ttl: 6000,    
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
   }
  });

fantasy.packs.CompBeans.PointJournal = fantasy.beans.Classes.Base.extend({
    idAttribute: "point_journal_id",
    urlRoot: "/api/point_journal/",
    mdl_name: "PointJournal",
    use_cache: true,
    cache_ttl: 120,    
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
      console.log("fantasy.packs.CompBeans.PointJournal init",arguments);
      this.normalize();
    },
    computeURLName: function() { 
      var name = this.get("name");
      name = name.replace(/\s+/g, '-').toLowerCase();
      return encodeURIComponent(name);
    },
    normalize: function() { 
      if(this.get("name") && !this.get("url_name"))
      {
        this.set("url_name", this.computeURLName() );
      }
      if(!this.get("awarded_dt"))
        this.set("awarded_dt",moment.utc().format());
      if(!this.get("points") && this.get("points")!== 0)
        this.set("points",10);
    if(this.get("awarded_dt")) {
        this.dateFormatField("awarded_dt","awared_dt_pretty","MMM D, YYYY");
        //.fromNow();
        this.dateAgoFormatField("awarded_dt","awared_dt_ago");
        this.conditionalDateFormatOrAgo("awarded_dt","awared_dt_con_pretty","MMM D, YYYY",1,"days");
        this.makeLocalDateTimeField("awarded_dt");
      }
    if(this.get("json_content"))
    {
      try {
        var obj = JSON.parse(this.get("json_content"));
        this.set("json_obj", obj);
      }
      catch(err)
      {
        console.error("trouble parsing point journal json_content",err);
      }
    }

    }
  });


fantasy.packs.CompBeans.AthleteRound = fantasy.beans.Classes.Base.extend({
    idAttribute: "athlete_round_id",
    urlRoot: "/api/athlete_round/",
    mdl_name: "AthleteRound",
    use_cache: true,
    cache_ttl: 120,    
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
   }
  });
/**
@deprecated
**/
fantasy.packs.CompBeans.LifetimePointSumForAthleteSport = fantasy.beans.Classes.Base.extend({
  idAttribute: "combo_id",
  id_fields: ["athlete_id","sport_id"],
  url: function() { return "/api/stats/point_journal/?no_group=true&athlete_id=" + this.get("athlete_id") + "&sport_id=" + this.get("sport_id");},
  mdl_name: "LifetimePointSumForAthleteSport",
  use_cache: true,
  cache_ttl: 120,    

  initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
      this.setId(this.get("sport_id") + "_" + this.get("athlete_id"));
    },
  parse: function(response,options) { 
    if(response && response.length > 0)
    {
      return response[0];
    }
    console.error("LifetimePointSumForAthleteSport bad response", response);
    return {};
  }
});


fantasy.packs.CompBeans.TeamStarterRoundRel = fantasy.beans.Classes.Base.extend({
          mdl_name: "TeamStarterRoundRel",
    use_cache: true,      
    idAttribute: "team_starter_round_rel_id",
    urlRoot: "/api/team_starter_round_rel/",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.Position = fantasy.beans.Classes.Base.extend({
          mdl_name: "position",
    use_cache: true,      
    idAttribute: "position_id",
    urlRoot: "/api/position/",
  cache_ttl: 6000,    
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });

fantasy.packs.CompBeans.RealTeam = fantasy.beans.Classes.Base.extend({
        mdl_name: "real_team",
    use_cache: true,      
    idAttribute: "real_team_id",
    urlRoot: "/api/real_team/",
  cache_ttl: 6000,    

   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.Round = fantasy.beans.Classes.Base.extend({
    idAttribute: "round_id",
    urlRoot: "/api/round/",
        mdl_name: "round",
    use_cache: true,    
  cache_ttl: 600,    
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    },
    normalize: function() { 
      if(this.get("start_date"))
        this.dateFormatField("start_date","pretty_start_date","ddd. M/D");
     if(this.get("end_date"))
       this.dateFormatField("end_date","pretty_end_date","ddd. M/D");
     this.set("unknown_start", false);
     if(this.get("start_date") && this.isStartInFuture())
     {
      this.set("round_not_started",true);
      this.set("round_started", false);

     }
     else if(this.get("start_date"))
     {
      this.set("round_not_started", false);
      this.set("round_started", true);

     } else { 
      this.set("unknown_start",true);
     }
    if(this.get("start_date") && this.get("end_date"))  {
      this.addInBetweenFlags("start_date","end_date", "in_round");
        this.addInFuturePastFlags("start_date");
        this.addInFuturePastFlags("end_date");
      }

    },
    isStartInFuture: function() { 
      var nowMom = fantasy.timeCheck.getNowServer();
      var roundStartMom = moment.utc(this.get("start_date"));
      return nowMom.isBefore(roundStartMom);    
    }
  });

fantasy.packs.CompBeans.RoundDef = fantasy.beans.Classes.Base.extend({
    idAttribute: "round_def_id",
    urlRoot: "/api/round_def/",
        mdl_name: "RoundDef",
    use_cache: true,    
  cache_ttl: 6000,     
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.Season = fantasy.beans.Classes.Base.extend({
    idAttribute: "season_id",
    urlRoot: "/api/season/",
        mdl_name: "season",
    use_cache: true,
  cache_ttl: 500,     
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
      console.log("Season init, attributes, options, self", attributes, options, this);
    },
    normalize: function() { 
      if(this.get("start_date"))
        this.dateFormatField("start_date","pretty_start_date","ddd. M/D");
      if(this.get("draft_date")) {
        this.dateFormatField("draft_date","pretty_draft_date","ddd. M/D");      
        this.dateAgoFormatField("draft_date","draft_date_ago");
        this.conditionalDateFormatOrAgo("draft_date","draft_date_cond","MMM D, YYYY",1,"days");
       
        var draftMom = moment.utc(this.get("draft_date"));
        var draftOverMom = draftMom.clone().add(1,"days");
        this.set("draft_date_over_ts", draftOverMom.format());

        this.addInFuturePastFlags("draft_date_over_ts");

        this.addInBetweenFlags("draft_date","draft_date_over_ts", "draft_is_today");

        this.addInBetweenNowAndDate("draft_date_over_ts","draft_in_future");


      }
     if(this.get("end_date")) {
       this.dateFormatField("end_date","pretty_end_date","ddd. M/D");
       this.addInFuturePastFlags("end_date");
     }

     if(this.get("start_date") && this.get("end_date"))
     {
      //SETS in_season if we are between its start date and end date
      this.addInBetweenFlags("start_date","end_date", "in_season");
     }

    }



  });

fantasy.packs.CompBeans.Sport = fantasy.beans.Classes.Base.extend({
    idAttribute: "sport_id",
    urlRoot: "/api/sport/",
        mdl_name: "sport",
    use_cache: true,  
  cache_ttl: 6000,     

   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });

fantasy.packs.CompBeans.RealTeam = fantasy.beans.Classes.Base.extend({
    idAttribute: "real_team_id",
    urlRoot: "/api/real_team/",
        mdl_name: "real_team",
    use_cache: true,  
  cache_ttl: 6000,     
          
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.User = fantasy.beans.Classes.Base.extend({
    idAttribute: "user_id",
    urlRoot: "/api/user/",
        mdl_name: "User",
    use_cache: true,  
  cache_ttl: 6000,         
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });


fantasy.packs.CompBeans.UserLeagueMem = fantasy.beans.Classes.Base.extend({
    idAttribute: "user_league_mem_id",
    urlRoot: "/api/user_league_mem/",
        mdl_name: "UserLeagueMem",
    use_cache: true,  
  cache_ttl: 360,       
   initialize: function(attributes, options) {
      fantasy.beans.Classes.Base.prototype.initialize.apply(this,arguments);
    }
  });

