fantasy.packs.Comps = fantasy.packs.Comps || {};




fantasy.packs.Comps.LeagueInviteItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-league-invite-item",
	template: fantasy.templates.sub_league_invite_list_item_view,
	events: { "click .fd-join-league-action" : "handleAccept"},
	initialize: function(options){
		fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
	},
	handleAccept: function(e) { 
		e.preventDefault();
        e.stopPropagation();
        this.$el.find("button").attr("disabled",true).html("Accepting...");
        var self = this;
		var userLeagueMemModel = new fantasy.packs.CompBeans.UserLeagueMem({league_id: this.model.get("league_id")});
		console.log("About to attempt to join league", this.model.get("league_id"));
		userLeagueMemModel.save().done(function() { 
			console.log("Joined a league!!!");
			self.template = 	fantasy.templates.sub_league_invite_list_item_accepting;
			self.setContent(userLeagueMemModel);
			self.render();

		}).fail(function(err) { 
			console.error("error joining league",err);
			self.model.set("error_joining", true);
	        this.$el.find("button").attr("disabled",true).html("Error...");
			self.render();
		});
	}
});
fantasy.packs.Comps.LeagueInvitesListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-league-invite-items",	
	subViewClass: fantasy.packs.Comps.LeagueInviteItemView
});




fantasy.packs.Comps.InviteToLeagueByEmailView = fantasy.present.Classes.FormView.extend({ 
	template: fantasy.templates.sub_invite_to_league_by_email_view,
	thank_you_template: fantasy.templates.sub_invite_to_league_by_email_thank_you_view,
	events: { 
		"click .panel-heading span.clickable": "handleToggle",
		"click .panel-title.clickable": "handleToggle",
		"click .reinit": "reinit"
	},
	initialize: function(options)
	{
		fantasy.present.Classes.FormView.prototype.initialize.apply(this,arguments);
		if(options && options.league_id)
		{
			this.league_id = options.league_id;
		}
		var inviteModel = new fantasy.packs.CompBeans.LeagueInvite({ 
			league_id: this.league_id

		});
		this.setContent(inviteModel);
		
	},
	reinit: function() { 
		this.template = fantasy.templates.sub_invite_to_league_by_email_view;
		var inviteModel = new fantasy.packs.CompBeans.LeagueInvite({ 
			league_id: this.league_id
		});
		this.setContent(inviteModel);
		this.did_reinit = true;
		this.render();
	},
	postRender: function() { 
		if(this.did_reinit)
			return;
		
		this.close();
		this.$el.find(".panel .panel-body").hide();
	},
	open: function() { 
		var clickableController = this.$el.find(".panel span.clickable");
		clickableController.parents('.panel').find('.panel-body').slideDown();
		clickableController.removeClass('panel-collapsed');
		clickableController.find('i').removeClass('glyphicon-chevron-down').addClass('glyphicon-chevron-up');
	},
	close: function() { 
		var clickableController = this.$el.find(".panel span.clickable");	
		clickableController.parents('.panel').find('.panel-body').slideUp();
		clickableController.addClass('panel-collapsed');
		clickableController.find('i').removeClass('glyphicon-chevron-up').addClass('glyphicon-chevron-down');
	},
	handleToggle: function(e) { 
	var clickableController = this.$el.find(".panel span.clickable");
		if(!clickableController.hasClass('panel-collapsed')) {
			this.close();
		} else {
			this.open();
		}		
	}



});


/**

Form you see when you click on an invite email that lets you accept an invite.

TODO add DECLINE


**/
fantasy.packs.Comps.InviteToYouView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-invite-to-you",
	template: fantasy.templates.sub_league_invite_to_you,
	events:{ "click .accept-invite": "handleAccept"},
	initialize: function(options){
		fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
	    var self = this;
	    if(options)
	    {
	    	this.league_invite_id = options.league_invite_id;
	    	this.invite_auth_code = options.invite_auth_code;
	    	this.sh = options.sh;
	    }
	    else
	    {
	    	throw "Required params missing.";
	    }
	    //fantasy.packs.CompBeans.LeagueInviteLookup

	    var lookupModel = new fantasy.packs.CompBeans.LeagueInviteLookup ({league_invite_id: this.league_invite_id},{ invite_auth_code: this.invite_auth_code, sh:this.sh });
	    this.setContent(lookupModel);
	    window.lookupModel = lookupModel;
	    lookupModel.fetch().done(function() { 
		    	self.render();
	    }).fail(function(err) { 
	    	console.error("error",err);
	    	self.setContent({error:true});
	    	self.render();
	    });

	},
	handleAccept: function(e){ 
		var self = this;
		this.$el.find(".accept-invite").attr("disabled",true);
		localStorage.setItem( 'invite_to_accept', JSON.stringify(self.model.attributes) );
		console.log("Just stored: ", JSON.parse( localStorage.getItem( 'invite_to_accept' ) ) );
		self.trigger("accepted_invite");
	},
	postRender: function() { 
		console.log("InviteToYouView post render");
	}
});


/**
Does the actual joining.
**/
fantasy.packs.Comps.JoiningLeagueFromInviteView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-joining-league-from-invite",
	template: fantasy.templates.sub_joining_league_from_invite,
	initialize: function(options){
		fantasy.present.Classes.Base.prototype.initialize.apply(this,arguments);
		this.league_id = options.league_id;
		if(!this.league_id)
		{
			throw "League ID is required for joining.";
		}

		var self = this;
		var userLeagueMemModel = new fantasy.packs.CompBeans.UserLeagueMem({league_id: this.league_id});
		
		self.setContent(userLeagueMemModel);
		self.render();
		userLeagueMemModel.save().done(function() { 
			
			self.render();

		}).fail(function(err) { 
			console.error("error joining league",err);
			self.setContent({error:true, err: err});
			self.render();
		});

	}
});


