fantasy.packs = fantasy.packs || {};
fantasy.packs.Nav = fantasy.packs.Nav || {};

fantasy.packs.Nav.NavView = fantasy.present.Classes.ContainerView.extend( {
  className: "fd-top-nav",
  events: {
    "click .global-navbar-toggle": "toggleGlobalNav",
  },
      initialize: function(options)
      {
        $("body").off("click.collapse_nav");
        $("body").on("click.collapse_nav","#body-part",function() { 
          $("#fd-top-nav #navbar").collapse('hide');
        });
        //lastScrollTop = 0;
        //$(window).scroll(this.watchScroll);
        fantasy.present.Classes.ContainerView.prototype.initialize.apply(this,arguments);
        this.navState = new fantasy.packs.Nav.NavCompositeModel();
        this.navPartView = new fantasy.packs.Nav.NavPartView({model:this.navState});
        this.addView(this.navPartView);
      },
     
});


/**
Holds volatile state about which page someone is on, if something is in an on-state or off state, etc.,
AND holds CONFIGURATION data about pages and nav structure.


**/
fantasy.packs.Nav.NavCompositeModel =  fantasy.beans.Classes.CompositeModel.extend({
 initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
    //  this.addModel("userstate", fantasy.userstate);
      var self = this;

      if(options && options.pageNavData)
      {
        this.set("page_nav_data", options.pageNavData);
        var pages_by_route = {};
        fantasy.packs.Nav.indexLevelsPagesByAttribute(pages_by_route, options.pageNavData, "route");
        this.set("pages_by_route", pages_by_route);
        var pages_by_id = {};
        fantasy.packs.Nav.indexLevelsPagesByAttribute(pages_by_id, options.pageNavData, "id");
        this.set("pages_by_id", pages_by_id);
      }

      self.listenTo(self,"change:route_fragment", function() {
        var pagesByRoute = self.get("pages_by_route");
        self.set("current_page_data", false);
        if(pagesByRoute)
        {
          var page = pagesByRoute[self.get("route_fragment")];
          if(page)
          {
            self.set("current_page_data", page);
          }
          else
          {
            console.log("page not found by route", self);
          }
        }
      });
    },
    getPageIdByRoute: function(route)
    {

    }
});


fantasy.packs.Nav.indexLevelsPagesByAttribute = function(map_dest, source_array, attribute_name) {

  _.each(source_array, function(page) {
    var attributeValue = page[attribute_name];
    map_dest[attributeValue] = page;
    if(page.subnav)
    {
        fantasy.packs.Nav.indexLevelsPagesByAttribute(map_dest,page.subnav,attribute_name);
    }
  });

};


//    <div id="df-bottom-bar"></div>

fantasy.packs.Nav.BottomBar =  fantasy.present.Classes.Base.extend({
    className: "bottom-bar",
    events: {
      "click .fd-action-logout": "handleLogout"
    },
    template: fantasy.templates.bottom_bar
});



//    'keydown .enterclick' : 'baseKeydownHandler'


fantasy.packs.Nav.NavPartView =  fantasy.present.Classes.Base.extend(
 {
    className: "fd-nav-part",
    events: {
      "click .fd-action-logout": "handleLogout"
    },
    template: fantasy.templates.nav,
    handleLogout: function(e) {
        e.preventDefault();
        e.stopPropagation();
  //        fantasy.userstate.logout();
      console.error("handleLogout Not Implemented");
    },

    postRender: function() {

    }

 });
