
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageDrafting = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-drafting draft-mode",
	template: fantasy.templates.page_drafting,
	events: { 
		"click .action-cancel-draft": "cancelDraft",
		"click .action-auto-draft": "autoDraft"
	},
	initialize: function(options) {
		console.log("PageDrafting INIT START",options);
		var self = this;
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.leagueComposite = options.leagueComposite;

		fantasy.socket.socketManager.listenToLeagueRoom(this.leagueComposite.getId());
		window.pageDrafting = this;
		
		this.friendsOnlineModel = new fantasy.packs.CompBeans.LeagueWhoIsOnlineModel({},{leagueComposite: this.leagueComposite});
		this.friendsOnlineView = new fantasy.packs.Comps.FriendsInLeagueOnlineStatus({model:this.friendsOnlineModel});
		window.friendsOnlineModel = this.friendsOnlineModel;
		self.addView(self.friendsOnlineView, { selector: ".fd-dz-friends-online"});

		this.listenTo(fantasy.socket.socketManager,"draft_pick_made", _.bind(this.handleDraftPickMadeBySomeone,this));
		this.listenTo(fantasy.socket.socketManager,"draft_cancel", _.bind(this.handleDraftCancelServerPing,this));
		this.listenTo(fantasy.socket.socketManager,"draft_end", _.bind(this.handleDraftEndServerPing,this));

		//fantasy.socket.socketManager.throttledTellTeamImOnline(this.leagueComposite.getId(),fantasy.userState.getLoggedInUserId(),fantasy.userState.getUserComposite().getModel("user_core").get("handle"));
		fantasy.userState.friendsOnlineManager.setupPinger(this.leagueComposite.getId());
		this.setContent(this.leagueComposite);

		self.athletesLeft = new fantasy.packs.CoreCollections.AthletesLeftForLeague([],{league_id: self.leagueComposite.getId(),sport_id: self.leagueComposite.getModel("league_core").get("sport_id")});
		this.handleDraftPickBound = _.bind(this.handleDraftPick,this);
		self.availableAthletesView = new fantasy.packs.Comps.AthletesAvailableListView({collection: self.athletesLeft});
		
		this.listenTo(self.availableAthletesView,"picked_for_draft", this.handleDraftPickBound);

		self.addView(self.availableAthletesView, {selector: ".fd-dz-available-athletes"});
		self.athletesLeft.fetch();

		self.allRostersCollection = new fantasy.packs.CompBeans.AllFantasyTeamCompositesForLeagueCollection([],{league_id: self.leagueComposite.getId()});
		self.allRostersCollection.fetch().done(function() { 
			
			console.log("allRostersCollection fetch done", self.allRostersCollection);

			self.allRostersView = new fantasy.packs.Comps.AllTeamRostersViewDrafting({allFantasyTeamCompositesForLeagueCollection: self.allRostersCollection});

			self.addView(self.allRostersView, {selector: ".fd-dz-all-rosters"});
			self.ownFantasyTeamComposite = self.allRostersCollection.getCompositeByUserId(fantasy.userState.getModel("user_composite").getId());
			if(!self.ownFantasyTeamComposite )
				console.error("trouble finding own fantasy team composite");
			else
			{
				var subview = new fantasy.packs.Comps.AthletesInFTeamView({collection:self.ownFantasyTeamComposite.getCollection("athletes")});
				var fTeamView = new fantasy.packs.Comps.FantasyTeamInAllRosterListView({model:self.ownFantasyTeamComposite  });
				self.addView(subview, {selector: ".fd-dz-your-roster"});
				self.addView(fTeamView, {selector: ".fd-dz-your-team"});

			}

			self.render();
		}).fail(function(err) { 
			console.error("PageDrafting ERROR!", err);
		});

		function updateTimeLeft(){ 
			self.$el.find(".draft-pick-time-left").html(self.leagueComposite.getModel("league_core").refreshSecondsLeftForDraftPick());
		}
		this.intervalHandle = setInterval(updateTimeLeft,1000);

	},handleDraftEndServerPing: function(data) { 
		var self = this;

		console.log("Draft is Over!.");

		self.leagueComposite.getModel("league_core").fetch().done(function(){ 
			fantasy.router.navigateWrapper("league/weeks/?league_id=" + self.leagueComposite.getId() + "&round_id=null");
		});	

	},
	handleDraftCancelServerPing: function(data) { 
		var self = this;
		if(self.just_canceled_draft)
		{
			console.log("skipping draft cancel reload because we were the one to cancel it.");
			return;
		}
		self.leagueComposite.getModel("league_core").fetch().done(function(){ 
			fantasy.router.navigateWrapper("league/?league_id=" + self.leagueComposite.getId());
		});	
	},
	handleDraftPickMadeBySomeone: function(data) { 

		console.log("SOCKET Got Server Ping!", data);
		var self = this;
		if(self.just_picked)
		{
			console.log("skipping refresh because the draft pick was us!");
			return;
		}
		//self.ownFantasyTeamComposite.refetch();
		self.allRostersCollection.reFetchEach();
		self.athletesLeft.fetch();
		self.leagueComposite.reFetchDraftingVolatileData().done(function() { 
			console.log("SOCKET handleDraftPickMadeBySomeone Got Server Ping, re-rendering.");
		});

		// self.leagueComposite.getModel("league_core").fetch().done(function() { 
		// 	console.log("SOCKET handleDraftPickMadeBySomeone Got Server Ping, re-rendering.");
		// });
	},
	postRender: function() { 
		if(this.leagueComposite.getModel("league_core").get("your_draft_pick"))
		{
			this.$el.addClass("your_pick");
		}
		else
		{
			this.$el.removeClass("your_pick");
		}
	},
	handleConfirmedDraftPick: function(obj) { 
		var self = this;
		self.just_picked = true;
		obj.view.$el.find("button").attr("disabled", true).html("Picking...");
		var athlete = obj.model;
		var draftPick = new fantasy.packs.CompBeans.DraftPickerModel({
			athlete_id: athlete.getId(),league_id: this.leagueComposite.getId(),
			created_by_user_id: fantasy.userState.getModel("user_composite").getId(),
			f_team_id: this.ownFantasyTeamComposite.getModel("f_team_core").getId() });
		draftPick.save().done(function() { 
			obj.view.remove();
			console.log("DRAFT PICKED!");
			//TODO ADD LOADING INDICATOR on team refresh
			//self.ownFantasyTeamComposite.refetch();
			self.allRostersCollection.reFetchEach();
			self.athletesLeft.fetch();

			self.leagueComposite.reFetchDraftingVolatileData().done(function() { 
				console.log("SOCKET handleDraftPickMadeBySomeone Got Server Ping, re-rendering.");
				self.just_picked = false;

			});

			// self.leagueComposite.getModel("league_core").fetch().done(function() {   //getModel("league_core").fetch()
			// 	//self.leagueComposite.trigger("sync");
			// 	//self.render();
			// 	console.log("DRAFT PICKED JUST RE-RENDERED PageDrafting Page");
			// 	self.just_picked = false;

			// });
		}).fail(function(err) { 
			console.error("Error with draft pick!", err);
			obj.view.$el.find("button").attr("disabled", true).html("Error...");

		});
	},
	handleDraftPick: function(obj) { 
		var self = this;
		this.$el.find(".fd-action-draft-pick").hide();
		fantasy.router.modalManager.areYouSure("are you sure you want to pick " + obj.model.get("first_name"), _.bind(this.handleConfirmedDraftPick,this,obj),
			function() { 
				self.$el.find(".fd-action-draft-pick").show();

			});

	},
	autoDraft: function(e){ 
		var self = this;
		var autoDraftWorker = new fantasy.packs.CompBeans.AutoDraftWorkerModel({league_id: this.leagueComposite.getId() });
		autoDraftWorker.save().done(function() { 
			console.log("autodraft successful", autoDraftWorker);
		}).fail(function(err) { 
			console.log("autodraft err", autoDraftWorker, arguments);
		});

	},
	cancelDraft: function(e) { 
		//TODO put up confirmation screen...

		var leagueId = this.leagueComposite.getId();
		var self = this;
		self.just_canceled_draft = true;


		var myprom = $.ajax({url:"/api/league/cancel_league_season/" + leagueId,method:"POST"});

		return myprom.done(function(){ 

			// self.ownFantasyTeamComposite.refetch();
			// self.allRostersCollection.fetch();
			// self.athletesLeft.fetch();

			self.leagueComposite.getModel("league_core").fetch().done(function(){ 
				fantasy.router.navigateWrapper("league/?league_id=" + self.leagueComposite.getId());
			});
		});
	},
	remove: function() { 
		
		clearInterval(this.intervalHandle);

		fantasy.present.Classes.AssignableContainerView.prototype.remove.apply(this,arguments);

		}
});