var fantasy = fantasy || {};

fantasy.pure_routes = {
     "/"                                                   		: "landingPage",
     "/index.html"                                               : "landingPage",
     "index.html"                                               : "landingPage",
     ""                                                       	: "landingPage",
     "home/"                                                   	: "homePage",
     "home"                                                   	: "homePage",
     "/home/"                                                   	: "homePage",
     "login/?invited=true&lid=:league_invite_id"           		: "loginPage",
     "login/"                                              		: "loginPage",
     "facebook_login/?invited=true&lid=:league_invite_id"        : "facebookLoginPage",
     "facebook_login/"                                           : "facebookLoginPage",
     "google_login/?invited=true&lid=:league_invite_id"          : "googleLoginPage",
     "google_login/"                                             : "googleLoginPage",
     "league/?league_id=:league_id"                     		: "myLeague",
     "league/fantasy_team/?league_id=:league_id&f_team_id=:f_team_id": "fantasyTeam",
	   "league/round/?league_id=:league_id&round_id=:round_id"      : "leagueRound",
     "league/"                                            : "homePage",      
     "league/your/game/?f_game_id=:f_game_id&your_f_team_id=:your_f_team_id"             : "yourGame",  
     "league/drafting/?league_id=:league_id"              	: "drafting",  
     "dicmove/"                                             : "dicmoves",
     "dicmove/types/"                                       : "dicmoveTypes",
     "dicmove/:point_journal_id/:url_name/"                         : "dicMovePage",

     "league/weeks/?league_id=:league_id&round_id=:round_id"  : "leagueTabs",

     "sport/?sport_id=:sport_id"                           	: "sportHome",
     "athletes/?athlete_id=:athlete_id"                     : "athlete",
     "athletes/"                                           	: "sportHome",
     "players/"                                            : "sportHome",

     "about/"                                               : "about",
     "invite/info/?lid=:league_invite_id&ac=:invite_auth_code&sh=:sh" : "inviteLookup",
     "/invite/info/?lid=:league_invite_id&ac=:invite_auth_code&sh=:sh" : "inviteLookup",
     "accepted_invite/"                                     	: "acceptedInvite",
     "/accepted_invite/"                                     	: "acceptedInvite",
     "season/"                                            		: "seasons",          
     "standing/"                                            	: "standings",
     "admin/point_journal/"       							: "handlePointJournalAddEdit",
     "admin/season/"       								: "handleAdminSeason",
     "terms_and_conditions/" 								: "genericPage",
     "error/" 											: "genericPage",
     "privacy_policy/" 									: "genericPage",
     "contact/"  										: "contactPage",
     "logout/?tk=:tk"                    					: "handleLogout",
     "mysettings/handle/"     							: "editConfirmUserHandle"
	 };

var module = module || false;
if(module)
     module.exports = fantasy.pure_routes;

