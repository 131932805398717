fantasy.socket = fantasy.socket || {};


fantasy.socket.SocketManager = function() { 
	var self = this;
  var socket = null;

  if(!isBot)
    socket = io();
  var handlers = {};
  var roomsJoined = {};

  _.extend(self, Backbone.Events);

  self.addHandler = function(handler_name,name,func) 
  {
  	handlers[handler_name] = {name: name, func: func};
    if(!isBot)
    	socket.on(name,func);
  };

  self.removeHandler = function(handler_name,name,func) {
  	delete handlers[handler_name];
    if(!isBot)
    	socket.removeListener(name,func);
  };
  
  self.emit = function(name,data) { 
    if(!isBot)
       socket.emit(name,data);
  };

  self.listenToRoom = function(room_name){
    if(!isBot)
    	socket.emit("room",room_name);
    roomsJoined[room_name] = true;
  };
  self.listenToLeagueRoom = function(league_id){
    if(roomsJoined[league_id])
    {
      console.log("already listening to league room ", league_id);
      return;
    }
   if(!isBot)
      socket.emit("league_room_join","" + league_id);
    roomsJoined[league_id] = true;
    console.log("SocketManager Listenting to League ", league_id);
  };

if(!isBot) {

  self.addHandler("league_room_join_confirm","league_room_join_confirm",function(data) { 
    console.log("SOCKET CONFIRMED LEAGUE ROOM JOIN", data);
  });

 self.addHandler("someone_joined","someone_joined",function(data) { 
    console.log("SOCKET SOMEONE JOINED, MAYBE YOU, MAYBE SOMEONE ELSE", data);
  });


  self.addHandler("default_news_thing","news", function (data) {
    console.log("SOCKET FROM SOCKET",data);
    self.emit('my other event', { my: 'data' });
  });

  self.addHandler("draft_cancel","draft_cancel",function(data) { 
    console.log("SOCKET draft_cancel",data);

    self.trigger("draft_cancel",data);
  });

  self.addHandler("draft_pick_made","draft_pick_made",function(data) { 
    console.log("SOCKET draft_pick_made",data);

    self.trigger("draft_pick_made",data);
  });


  self.addHandler("draft_start","draft_start",function(data) { 
    console.log("SOCKET draft_start",data);

    self.trigger("draft_start",data);
  });  
// draft_end",{user_id: user_id,athlete_id: athlete_id});
  self.addHandler("draft_end","draft_end",function(data) { 
    console.log("SOCKET draft_end",data);

    self.trigger("draft_end",data);
  });  

  self.addHandler("user_online","user_online", function(data) { 
    console.log("SOCKET RECEIVED", "user_online",data);
    self.trigger("user_online",data);
  });

}

  self.tellTeamImOnline = function(league_id,user_id, handle) { 
    var payload = { league_id: league_id, user_id: user_id, handle:handle};
    if(!isBot)
      socket.emit("user_online_c_to_s", payload);
    console.log("SOCKET SENT just sent user_online_c_to_s",payload);
  };
   if(!isBot)
   self.throttledTellTeamImOnline = _.throttle(self.tellTeamImOnline, 5000);
  else 
   self.throttledTellTeamImOnline = self.tellTeamImOnline;

       // socket.on("user_online_c_to_s", function(data) { 
       //    self.notifyLeagueUserOnline(data.league_id,data.user_id,data.handle);
       // });

  // socket.on('news', function (data) {
  //   console.log("FROM SOCKET",data);
  //   socket.emit('my other event', { my: 'data' });
  // });

//socket.removeListener('test', test);

  return self;
};

