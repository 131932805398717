
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageLoading = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_loading,
	className: "fd-page-loading",
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.render();
	}
});