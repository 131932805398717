
Handlebars.registerHelper('if_eq', function(a, b, opts) {
    if(a == b) // Or === depending on your needs
        return opts.fn(this);
    else
        return opts.inverse(this);
});

Handlebars.registerHelper('times', function(a, b, opts) {
  return (a * b);
});


Handlebars.registerHelper('if_exists', function(a, opts) {
    if(typeof a !== "undefined" && a !== null)
        return opts.fn(this);
    else
        return opts.inverse(this);
});

Handlebars.registerHelper('json', function(context) {
    return JSON.stringify(context);
});
