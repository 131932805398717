fantasy.packs.CompBeans = fantasy.packs.CompBeans || {};


fantasy.packs.CompBeans.LeagueInviteComposite = fantasy.beans.Classes.CompositeModel.extend({
    idAttribute: "league_invite_id",
   initialize: function(attributes, options) {
      fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
      this.leagueInviteCoreModel = options.model || new fantasy.packs.CompBeans.LeagueInvite({league_invite_id: this.get("league_invite_id")});

      this.addModel("league_invite_core",this.leagueInviteCoreModel,{depth:0});

      this.addModel("league_core",new fantasy.packs.CompBeans.League({league_id: this.leagueInviteCoreModel.get("league_id")}),{depth:1});
      this.addModel("created_by_user_core",new fantasy.packs.CompBeans.User({user_id: this.leagueInviteCoreModel.get("created_by_user_id")}),{depth:1});
 
    },
    levelPrep: function() { 
      var inviteCore = this.getModel("league_invite_core");
      this.getModel("created_by_user_core").setId(inviteCore.get("created_by_user_id"));
      this.getModel("league_core").setId(inviteCore.get("league_id"));

    }

  });


// fantasy.packs.CompBeans.LeagueInviteLookupComposite = fantasy.beans.Classes.CompositeModel.extend({
//     idAttribute: "league_invite_id",
//    initialize: function(attributes, options) {
//       fantasy.beans.Classes.CompositeModel.prototype.initialize.apply(this,arguments);
//       this.leagueInviteLookupCoreModel = options.model || new fantasy.packs.CompBeans.LeagueInviteLookup(_.extend({}, attributes),  _.extend({},options));

//       this.addModel("league_invite_lookup_core",this.leagueInviteLookupCoreModel,{depth:0});
//        this.addModel("league_core",new fantasy.packs.CompBeans.League({league_id: this.leagueInviteLookupCoreModel.get("league_id")}),{depth:1});
//       this.addModel("created_by_user_core",new fantasy.packs.CompBeans.User({user_id: this.leagueInviteLookupCoreModel.get("created_by_user_id")}),{depth:1});
 
//     },
//     levelPrep: function() { 
//         this.getModel("league_core").setId(this.leagueInviteLookupCoreModel.get("league_id"));
//         this.getModel("created_by_user_core").setId(this.leagueInviteLookupCoreModel.get("created_by_user_id"));
//     }

//   });
