fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageAthlete = fantasy.present.Classes.AssignableContainerView.extend({
	className: "page-athlete",
	template: fantasy.templates.page_athlete,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.athleteComposite = options.athleteComposite;
		this.setContent(this.athleteComposite);
		window.page_athelete_composite = this.athleteComposite;
		
		this.addView(new fantasy.packs.Comps.PointJournalListView ({collection: this.athleteComposite.getCollection("all_points") }),{selector: ".fd-dz-point_journals"});
		this.render();
	},
	getPageTitle: function() { 
		return this.athleteComposite.getModel("athlete_core").get("first_name") + " " + this.athleteComposite.getModel("athlete_core").get("last_name"); 
	}
});