fantasy.packs.Comps.RoundItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item fd-round-item",

	template: fantasy.templates.sub_round_item_view
});

fantasy.packs.Comps.RoundsListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-rounds-list",
	subViewClass: fantasy.packs.Comps.RoundItemView
});


fantasy.packs.Comps.RoundMastView =  fantasy.present.Classes.Base.extend({ 
	className: "round-mast",

	template: fantasy.templates.sub_round_mast_view
});


fantasy.packs.Comps.GameInRoundItemView =  fantasy.present.Classes.Base.extend({ 
	className: "fd-list-item game-in-round",

	template: fantasy.templates.sub_game_in_round_item_view
});

fantasy.packs.Comps.GamesInRoundListView =  fantasy.present.Classes.CollectionView.extend({ 
	className: "fd-list fd-games-in-round-list",

	subViewClass: fantasy.packs.Comps.GameInRoundItemView
});

fantasy.packs.Comps.FGameAthleteStats =  fantasy.present.Classes.Base.extend({ 
	className: "fd-fgame-athlete-stats",
	template: fantasy.templates.sub_structure_athlete_stats_for_fteam_round
});
