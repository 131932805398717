
fantasy.packs.Comps = fantasy.packs.Comps || {};

fantasy.packs.Comps.PageFantasyTeamView = fantasy.present.Classes.AssignableContainerView.extend({
	template: fantasy.templates.page_fantasy_team_view,
	initialize: function(options) {
		fantasy.present.Classes.AssignableContainerView.prototype.initialize.apply(this,arguments);
		this.fantasyTeamComposite = options.fantasyTeamComposite;
		this.setContent(this.fantasyTeamComposite);
		
		this.addView(new fantasy.packs.Comps.FantasyTeamMastView({model: this.fantasyTeamComposite}),{selector:".fd-dz-fteam"});
		this.addView(new fantasy.packs.Comps.AthletesInFTeamView({collection: this.fantasyTeamComposite.getCollection("athletes") }),{selector: ".fd-dz-athletes"});

		this.render();
	}
});